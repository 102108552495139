import React from "react";

export const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
  >
    <line
      x1="0.7"
      y1="1.3"
      x2="11.3"
      y2="1.3"
      stroke="#757786"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
    <line
      x1="0.7"
      y1="5.3"
      x2="7.3"
      y2="5.3"
      stroke="#757786"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
    <line
      x1="0.7"
      y1="9.3"
      x2="11.3"
      y2="9.3"
      stroke="#757786"
      strokeWidth="1.4"
      strokeLinecap="round"
    />
  </svg>
);

export const Searchicon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.69991 12.3993C9.84789 12.3993 12.3998 9.84746 12.3998 6.69964C12.3998 3.55181 9.84789 1 6.69991 1C3.55194 1 1 3.55181 1 6.69964C1 9.84746 3.55194 12.3993 6.69991 12.3993Z"
      stroke="#757786"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.999 13L11.7988 11.7999"
      stroke="#757786"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PromoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <path
      d="M9.42427 1V0.3H8.95208L8.77522 0.737823L9.42427 1ZM4.57405 1L5.2231 0.737823L5.04625 0.3H4.57405V1ZM3 12.3C2.28203 12.3 1.7 11.718 1.7 11H0.3C0.3 12.4912 1.50883 13.7 3 13.7V12.3ZM11 12.3H3V13.7H11V12.3ZM12.3 11C12.3 11.718 11.718 12.3 11 12.3V13.7C12.4912 13.7 13.7 12.4912 13.7 11H12.3ZM12.3 3V11H13.7V3H12.3ZM11 1.7C11.718 1.7 12.3 2.28203 12.3 3H13.7C13.7 1.50883 12.4912 0.3 11 0.3V1.7ZM9.42427 1.7H11V0.3H9.42427V1.7ZM8.77522 0.737823C8.54213 1.31487 7.86986 1.8 6.99916 1.8V3.2C8.3677 3.2 9.60041 2.43291 10.0733 1.26218L8.77522 0.737823ZM6.99916 1.8C6.12846 1.8 5.4562 1.31487 5.2231 0.737823L3.92501 1.26218C4.39792 2.43291 5.63063 3.2 6.99916 3.2V1.8ZM3 1.7H4.57405V0.3H3V1.7ZM1.7 3C1.7 2.28203 2.28203 1.7 3 1.7V0.3C1.50883 0.3 0.3 1.50883 0.3 3H1.7ZM1.7 11L1.7 3H0.3L0.3 11H1.7Z"
      fill="#757786"
    />
    <line
      x1="3.7"
      y1="6.3"
      x2="10.3"
      y2="6.3"
      stroke="#757786"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeDasharray="4 4"
    />
  </svg>
);

export const ChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M12 5.62577C12 8.73281 9.3135 11.2515 6 11.2515C5.40574 11.2524 4.81394 11.1697 4.23975 11.0056C3.80175 11.2435 2.796 11.7 1.104 11.9974C0.954 12.0231 0.84 11.8559 0.89925 11.7064C1.16475 11.0346 1.40475 10.1392 1.47675 9.32271C0.558 8.33418 0 7.04025 0 5.62577C0 2.51874 2.6865 0 6 0C9.3135 0 12 2.51874 12 5.62577ZM3.75 5.62577C3.75 5.41262 3.67098 5.2082 3.53033 5.05748C3.38968 4.90676 3.19891 4.82209 3 4.82209C2.80109 4.82209 2.61032 4.90676 2.46967 5.05748C2.32902 5.2082 2.25 5.41262 2.25 5.62577C2.25 5.83892 2.32902 6.04334 2.46967 6.19406C2.61032 6.34478 2.80109 6.42945 3 6.42945C3.19891 6.42945 3.38968 6.34478 3.53033 6.19406C3.67098 6.04334 3.75 5.83892 3.75 5.62577ZM6.75 5.62577C6.75 5.41262 6.67098 5.2082 6.53033 5.05748C6.38968 4.90676 6.19891 4.82209 6 4.82209C5.80109 4.82209 5.61032 4.90676 5.46967 5.05748C5.32902 5.2082 5.25 5.41262 5.25 5.62577C5.25 5.83892 5.32902 6.04334 5.46967 6.19406C5.61032 6.34478 5.80109 6.42945 6 6.42945C6.19891 6.42945 6.38968 6.34478 6.53033 6.19406C6.67098 6.04334 6.75 5.83892 6.75 5.62577ZM9 6.42945C9.19891 6.42945 9.38968 6.34478 9.53033 6.19406C9.67098 6.04334 9.75 5.83892 9.75 5.62577C9.75 5.41262 9.67098 5.2082 9.53033 5.05748C9.38968 4.90676 9.19891 4.82209 9 4.82209C8.80109 4.82209 8.61032 4.90676 8.46967 5.05748C8.32902 5.2082 8.25 5.41262 8.25 5.62577C8.25 5.83892 8.32902 6.04334 8.46967 6.19406C8.61032 6.34478 8.80109 6.42945 9 6.42945Z"
      fill="#757786"
    />
  </svg>
);
