import React from "react";

import { useSelector } from "react-redux";
import { Grid, Button, Skeleton } from "@mui/material";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
import Carousel from "react-multi-carousel";

import useStyles from "./SemiBanner.styles";

const SemiBanner = () => {
  const classes = useStyles();
  const { bonusImages, loading } = useSelector((state) => state.casinoLobby);

  const cryptoResponsive = {
    desktop: {
      breakpoint: { max: 5000, min: 1751 },
      items: 3,
    },
    miniDesktop1: {
      breakpoint: { max: 1750, min: 1537 },
      items: 3,
    },
    miniDesktop: {
      breakpoint: { max: 1536, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 992 },
      items: 2,
    },
    miniTablet: {
      breakpoint: { max: 991, min: 768 },
      items: 2,
    },

    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  function CustomButtonGroupAsArrows({ next, previous }) {
    return (
      <div className={classes.actionCaraousel}>
        <Button className={classes.leftArrow} onClick={previous}>
          <KeyboardArrowLeftTwoToneIcon />
        </Button>
        <Button className={classes.rightArrow} onClick={next}>
          <NavigateNextTwoToneIcon />
        </Button>
      </div>
    );
  }

  const handleRedirect = (banner) => {
    window.location.href =
      banner?.redirect_url || process.env.REACT_APP_BASE_URL;
  };

  return (
    <Grid className={`${classes.bonusSection} bonusSectionContent`}>
      <Grid className={classes.bonusHeading}>
        <Grid className={classes.bonusHeadingImage}>
          <img src="/images/bonus-icon.svg" alt="bonus icon" />
          <span>Bonuses</span>
        </Grid>
        <Button className={classes.seeAllBtn} disabled>
          See all
        </Button>
      </Grid>

      <Grid>
        <Carousel
          responsive={cryptoResponsive}
          autoPlay={false}
          infinite={true}
          autoPlaySpeed={7000}
          arrows={false}
          containerClass="container-padding-bottom"
          customButtonGroup={<CustomButtonGroupAsArrows />}
          renderButtonGroupOutside
          centerMode={false}
        >
          {!loading
            ? bonusImages?.map((banner) => (
                <Grid key={banner.index} onClick={() => handleRedirect(banner)}>
                  <Grid className={classes.position2}>
                    <img
                      src={
                        banner?.image_url ||
                        "https://highroller-dev-active-storage.s3.amazonaws.com/images/bonus-img1.svg"
                      }
                      alt={"bonus-img1"}
                    />
                  </Grid>
                </Grid>
              ))
            : [1, 2, 3, 4, 5].map((index) => (
                <Skeleton
                  key={index}
                  style={{ marginRight: "10px" }}
                  height={200}
                  width={352}
                />
              ))}
        </Carousel>
      </Grid>
    </Grid>
  );
};

export default SemiBanner;
