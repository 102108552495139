import { createSlice } from "@reduxjs/toolkit";

export const transactionInitialState = {
  loading: false,
  success: false,
  error: null,
  myBets: [],
  transactions: [],
  totalPages: 0,
  betsTotalPages: 0,
  currentPage: 1,
  betHistoryCount: 0,
  userBets: [],
  userBonusHistory: [],
  userTransactionHistory: [],
  transactionHistoryCount: 0,
  bonusCount: 0,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState: transactionInitialState,
  reducers: {
    getTransactionsStart: (state, action) => {
      state.loading = true;
    },
    getTransactionsSuccess: (state, action) => {
      console.log("Action transaction>>>>>", action);
      state.loading = false;
      state.transactions = action.payload.transactionList;
      state.totalPages = action.payload.totalPage;
    },
    getTransactionsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getMyBetsStart: (state, action) => {
      state.loading = true;
    },
    getMyBetsSuccess: (state, action) => {
      state.loading = false;
      state.myBets = action.payload.transactionList;
      state.betsTotalPages = action.payload.totalPage;
      state.currentPage = action.payload.currentPage;
    },
    getMyBetsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getUsersAllBetsStart: (state, action) => {
      state.loading = true;
    },
    getUsersAllBetsSuccess: (state, action) => {
      state.loading = false;
      state.userBets = action.payload.transactionList;
      state.betsTotalPages = action.payload.totalPage;
      state.currentPage = action.payload.currentPage;
      state.betHistoryCount = action.payload.count;
    },
    getUsersAllBetsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getUsersAllBonusHistStart: (state, action) => {
      state.loading = true;
    },
    getUsersAllBonusHistSuccess: (state, action) => {
      state.loading = false;
      state.userBonusHistory = action.payload.transactionList;
      state.bonusCount = action.payload.count;
    },
    getUsersAllBonusHistFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getUsersAllTransactionHistStart: (state, action) => {
      state.loading = true;
    },
    getUsersAllTransactionHistSuccess: (state, action) => {
      state.loading = false;
      state.userTransactionHistory = action.payload.transactionList;
      state.transactionHistoryCount = action.payload.count;
    },
    getUsersAllTransactionHistFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    cancelUserTransactionStart: (state, action) => {
      state.loading = false;
    },
    cancelUserTransactionSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
    },
    cancelUserTransactionFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetBets: (state) => {
      state.myBets = transactionInitialState.myBets;
    },
    resetTransaction: (state) => {
      state.transactions = transactionInitialState.transactions;
    },
  },
});

export const {
  resetTransaction,
  resetBets,
  getTransactionsStart,
  getTransactionsSuccess,
  getTransactionsFailure,
  getUsersAllBetsStart,
  getUsersAllBetsFailure,
  getUsersAllBetsSuccess,
  getUsersAllBonusHistStart,
  getUsersAllBonusHistSuccess,
  getUsersAllBonusHistFailure,
  getUsersAllTransactionHistStart,
  getUsersAllTransactionHistSuccess,
  getUsersAllTransactionHistFailure,
  cancelUserTransactionStart,
  cancelUserTransactionSuccess,
  cancelUserTransactionFailure,
  getMyBetsStart,
  getMyBetsSuccess,
  getMyBetsFailure,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
