import React, { useState, useEffect } from "react";

import Tick from "../../../../images/Tick.svg";

import "./circularProgress.css";

const CircularProgressBar = ({ progress, size = 28, strokeWidth = 3 }) => {
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (progress >= 100) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [progress]);

  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <div
      className="circular-progress-container"
      style={{ width: size, height: size }}
    >
      <svg width={size} height={size}>
        {/* Define the radial gradient */}
        <defs>
          <radialGradient
            id="progressGradient"
            cx="50%"
            cy="50%"
            r="200%"
            fx="60%"
            fy="50%"
          >
            <stop offset="0%" stopColor="#5D69CF" />
            <stop offset="100%" stopColor="#342A6F" />
          </radialGradient>
        </defs>

        <circle
          stroke="rgba(255, 255, 255, 0.2)"
          fill="transparent"
          strokeWidth={strokeWidth}
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          className="progress-circle"
          stroke="url(#progressGradient)"
          fill="transparent"
          strokeWidth={strokeWidth}
          r={radius}
          cx={size / 2}
          cy={size / 2}
          style={{ strokeDasharray: circumference, strokeDashoffset: offset }}
          strokeLinecap="round"
        />
      </svg>
      {isComplete ? (
        <div className="tick">
          <img src={Tick} alt="tick" />
        </div>
      ) : null}
    </div>
  );
};

export default CircularProgressBar;
