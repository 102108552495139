import React from "react";

import { Grid, /* Typography,  */ Box, Tab, Button } from "@mui/material";
//import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import Carousel from "react-multi-carousel";

import useStyles from "./Provider.styles";

const Provider = ({ offset }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { providers } = useSelector((state) => state.casinoLobby);

  const handleProviderSelect = (id, name) => {
    offset.current = 1;
    navigate(`/view-all/${id}/false/${name}`);
  };

  const cryptoResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1751 },
      items: 6,
    },
    miniDesktop1: {
      breakpoint: { max: 1750, min: 1537 },
      items: 6,
    },
    miniDesktop: {
      breakpoint: { max: 1536, min: 1200 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1199, min: 992 },
      items: 5,
    },
    miniTablet: {
      breakpoint: { max: 991, min: 768 },
      items: 4,
    },

    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 3,
    },
  };

  function CustomButtonGroupAsArrows({ next, previous }) {
    return (
      <div className={classes.providerCaraousel}>
        <Button className={classes.leftArrow} onClick={previous}>
          <KeyboardArrowLeftTwoToneIcon />
        </Button>
        <Button className={classes.rightArrow} onClick={next}>
          <NavigateNextTwoToneIcon />
        </Button>
      </div>
    );
  }

  return (
    <Box className={classes.providerWrap}>
      {isMobile ? (
        <div className={classes.providerMobile}>
          <Carousel
            responsive={cryptoResponsive}
            autoPlay={true}
            infinite={true}
            autoPlaySpeed={7000}
            arrows={false}
            containerClass="container-padding-bottom"
            customButtonGroup={<CustomButtonGroupAsArrows />}
            renderButtonGroupOutside
          >
            {providers?.map((item, index) => (
              <Tab
                component={() => (
                  <Grid
                    className={classes.providerName}
                    onClick={() => {
                      handleProviderSelect(
                        item?.masterCasinoProviderId,
                        item?.name
                      );
                    }}
                  >
                    <img
                      src={
                        item?.thumbnailUrl
                          ? item?.thumbnailUrl
                          : "/images/slotmill.png"
                      }
                      alt="provider"
                    />
                  </Grid>
                )}
                value={item?.name}
                key={item?.masterGameSubCategoryId}
                label={item?.name}
              ></Tab>
            ))}
          </Carousel>
        </div>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Provider;
