const config = {
  appEnv: process.env.REACT_APP_ENV,
  baseUrl: process.env.REACT_APP_SERVER_URL,
  clientUrl: process.env.REACT_APP_BASE_URL,
  xcoinPaymentUrl:
    process.env.REACT_APP_ENV === "production"
      ? "https://instantpay.xcoins.com"
      : "https://sandbox-onramp.xcoins.network",
};

export default config;
