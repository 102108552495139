import { put, takeLatest } from "redux-saga/effects";

import liveTableService from "../../../services/liveTable";
import {
  getTopBetsFailure,
  getTopBetsStart,
  getTopBetsSuccess,
  getHighRollersBetStart,
  getHighRollersBetSuccess,
  getHighRollersBetFailure,
  getLuckyWinnerBetStart,
  getLuckyWinnerBetSuccess,
  getLuckyWinnerBetFailure,
} from "../../redux/LiveTable";
import { showErrorMessage } from "../../redux/toast";

function* LiveTableWatcher() {
  yield takeLatest(getTopBetsStart.type, liveTableWorker);
  yield takeLatest(getHighRollersBetStart.type, liveTableWorkerHighRoller);
  yield takeLatest(getLuckyWinnerBetStart.type, liveTableWorkerLuckyWinner);
}

function* liveTableWorker() {
  try {
    const { data, error } = yield liveTableService.getTopBets();
    if (!error) {
      yield put(getTopBetsSuccess(data));
    }
    if (error) {
      yield put(
        showErrorMessage({ msg: error.message || "Error fetching bets" })
      );
      yield put(getTopBetsFailure());
    }
  } catch (error) {
    yield put(
      showErrorMessage({ msg: error.message || "Error fetching bets" })
    );
    yield put(getTopBetsFailure());
  }
}
function* liveTableWorkerHighRoller() {
  try {
    const { data, error } = yield liveTableService.getHighRollerBets();
    if (!error) {
      yield put(getHighRollersBetSuccess(data));
    }
    if (error) {
      yield put(
        showErrorMessage({ msg: error.message || "Error fetching bets" })
      );
      yield put(getHighRollersBetFailure());
    }
  } catch (error) {
    yield put(
      showErrorMessage({ msg: error.message || "Error fetching bets" })
    );
    yield put(getHighRollersBetFailure());
  }
}
function* liveTableWorkerLuckyWinner() {
  try {
    const { data, error } = yield liveTableService.getLuckyWinnersBets();
    if (!error) {
      yield put(getLuckyWinnerBetSuccess(data));
    }
    if (error) {
      yield put(
        showErrorMessage({ msg: error.message || "Error fetching bets" })
      );
      yield put(getLuckyWinnerBetFailure());
    }
  } catch (error) {
    yield put(
      showErrorMessage({ msg: error.message || "Error fetching bets" })
    );
    yield put(getLuckyWinnerBetFailure());
  }
}

export default LiveTableWatcher;
