// resetGamesMiddleware.js // Import the action to reset games state
import { resetGameState } from "./redux/casino/lobby";

const resetGamesMiddleware = (store) => (next) => (action) => {
  if (action.type === "RESET_GAMES_STATE") {
    store.dispatch(resetGameState());
  }
  return next(action);
};

export default resetGamesMiddleware;
