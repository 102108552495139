import React from "react";

import { StepsEnum } from "./constants";

export default function AchDirectRedeem({ setStep }) {
  return (
    <div className="achDirectRedeemMainWrap">
      <span className="">Choose your account type</span>
      <div id="wrapper">
        <label className="radio-button-container" disabled>
          Checking
          <input type="radio" name="radio" />
          <span className="checkmark"></span>
        </label>
        <label className="radio-button-container secondLabel">
          Saving
          <input type="radio" name="radio" />
          <span className="checkmark"></span>
        </label>
      </div>
      <div className="inputMainWrap paymentMainContentWrap">
        {/* <div className="secondCopyInput RedeemLable">
          <span>Please input your Routing number</span>
          <div className="copyWrap copyWrapSec height32">
            <p>3HUzPdnjmZHXtn4yfatbiu6W25i24Y...</p>
          </div>
        </div> */}
        <div className="secondCopyInput">
          <span>Please input your Routing number</span>
          <div className="copyWrap copyWrapSec">
            <input
              type="text"
              className="custom-input"
              placeholder="Your Routing number"
            />
          </div>
        </div>
        <div className="marginTop-24 RedeemLable">
          {/* <span>Please input your Account number</span>
          <div className="copyWrap copyWrapSec height32">
            <p>3HUzPdnjmZHXtn4yfatbiu6W25i24Y...</p>
          </div> */}
          <div className="secondCopyInput">
            <span>Please input your Account number</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="text"
                className="custom-input"
                placeholder="Your Account number"
              />
            </div>
          </div>
        </div>
        <div className="marginTop-24 RedeemLable">
          {/* <span>Please confirm your Account number</span>
          <div className="copyWrap copyWrapSec height32">
            <p>3HUzPdnjmZHXtn4yfatbiu6W25i24Y...</p>
          </div> */}
          <div className="secondCopyInput">
            <span>Please confirm your Account number</span>
            <div className="copyWrap copyWrapSec">
              <input
                type="text"
                className="custom-input"
                placeholder="Confirm your Account number"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flexBtnDiv bitCoinPaymentBtnWrap">
        <div className="select-payment-method-button" style={{ width: "100%" }}>
          <button onClick={() => setStep(StepsEnum.RedeemMethod)}>Back</button>
        </div>
        <div style={{ width: "100%" }}>
          <div className="buyNowBtnWrap">
            <button style={{ fontWeight: "500" }}>Redeem</button>
          </div>
        </div>
      </div>
    </div>
  );
}
