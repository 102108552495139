import { createSlice } from "@reduxjs/toolkit";

export const chatBoxInitialState = {
  loading: false,
  error: null,
  userList: [],
  chatList: [],
  supportChatList: [],
  unseenMsg: 0,
  socketInstance: null,
  newMessageByWS: {},
  activeUsersCount: 0,
  supportChatUnseenCount: 0,
};

const chatboxSlice = createSlice({
  name: "chatbox",
  initialState: chatBoxInitialState,
  reducers: {
    getUserListStart: (state, action) => {
      state.loading = true;
    },
    getUserListSuccess: (state, action) => {
      state.loading = true;
      state.userList = action.payload;
    },
    getUserListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPreviousChatsStart: (state, action) => {
      state.loading = true;
    },
    getPreviousChatsSuccess: (state, action) => {
      state.loading = false;
      state.chatList = action.payload.messages;
      state.unseenMsg = action.payload.unSeenMentionedCount;
    },
    getPreviousChatsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPreviousSupportChatsStart: (state, action) => {
      state.loading = true;
    },
    getUnseenCountOfSupportChatStart: (state, action) => {
      state.loading = true;
    },
    getUnseenCountOfSupportChatSuccess: (state, action) => {
      state.loading = false;
      state.supportChatUnseenCount = action.payload.unseenCount;
      // state.unseenMsg = action.payload.unSeenMentionedCount;
    },
    getUnseenCountOfSupportChatFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getPreviousSupportChatsSuccess: (state, action) => {
      state.loading = false;
      state.supportChatList = action.payload.messages;
      // state.unseenMsg = action.payload.unSeenMentionedCount;
    },
    getPreviousSupportChatsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setSocketInstance: (state, action) => {
      return {
        ...state,
        socketInstance: action.payload,
      };
    },
    setNewMessageByWS: (state, action) => {
      return {
        ...state,
        newMessageByWS: action.payload,
      };
    },
    setPlayerUnseenMessage: (state, action) => {
      return {
        ...state,
        unseenMsg: action.payload,
      };
    },
    updateUnseenCountStart: (state, action) => {
      return {
        ...state,
      };
    },
    updateUnseenCountFailure: (state, action) => {
      return {
        ...state,
      };
    },
    updateUnseenCountSuccess: (state, action) => {
      return {
        ...state,
        unseenMsg: 0,
      };
    },
    updateSupportMsgUnseenCountStart: (state, action) => {
      return {
        ...state,
      };
    },
    updateSupportMsgUnseenCountFailure: (state, action) => {
      return {
        ...state,
      };
    },
    updateSupportMsgUnseenCountSuccess: (state, action) => {
      return {
        ...state,
        supportChatUnseenCount: 0,
      };
    },
    setActivePlayers: (state, action) => {
      return {
        ...state,
        activeUsersCount: action.payload,
      };
    },
  },
});

export const {
  getUserListStart,
  getUserListSuccess,
  getUserListFailure,
  getPreviousChatsStart,
  getPreviousChatsSuccess,
  getPreviousChatsFailure,
  setSocketInstance,
  setNewMessageByWS,
  setPlayerUnseenMessage,
  updateUnseenCountStart,
  updateUnseenCountFailure,
  updateUnseenCountSuccess,
  setActivePlayers,
  getPreviousSupportChatsFailure,
  getPreviousSupportChatsSuccess,
  getPreviousSupportChatsStart,
  getUnseenCountOfSupportChatStart,
  getUnseenCountOfSupportChatSuccess,
  getUnseenCountOfSupportChatFailure,
  updateSupportMsgUnseenCountStart,
  updateSupportMsgUnseenCountSuccess,
  updateSupportMsgUnseenCountFailure,
} = chatboxSlice.actions;

export default chatboxSlice.reducer;
