/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import i18n from "i18next";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import OpenSidebar from "./OpenSidebar";
import CloseSidebar from "./CloseSidebar";
import { changeTheme } from "../../redux-saga/redux/settings/themeSettings";
import { toggleSlider } from "../Header/toggle";
import { getFeaturedGamesStart } from "../../redux-saga/redux/casino/lobby/games";

export default function PageLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { sidebarOpen, darkMode } = useSelector((state) => state.themeSettings);
  const { userDetails } = useSelector((state) => state.myProfile);
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    dispatch(getFeaturedGamesStart());
  }, []);
  const { subCategory } = useSelector((state) => state.casinoLobby);
  const [loading, setLoading] = useState(true);
  const [one, setOne] = useState(true);
  useEffect(() => {
    if (subCategory?.length > 0 && one) {
      const filterSubCat = subCategory.filter((ele) => ele?.name !== "Slot");
      setCategoryList(filterSubCat);
      setLoading(false);
      setOne(false);
    }
  }, [subCategory]);

  const handleCloseLanguage = (value) => {
    i18n.changeLanguage(value);
    if (isMobile) toggleSlider(dispatch, sidebarOpen);
  };

  const switchTheme = () => {
    dispatch(changeTheme(!darkMode));
    if (isMobile) toggleSlider(dispatch, sidebarOpen);
  };
  //const [referralLink, setReferralLink] = useState(false);
  const handleOpenReferral = (name) => {
    if (!userDetails?.user?.uuid) return;
    if (name === "Refer a Friend") {
      navigate("/referral-award");
    }
  };
  return (
    <>
      {sidebarOpen ? (
        <OpenSidebar
          handleCloseLanguage={handleCloseLanguage}
          switchTheme={switchTheme}
          loading={loading}
          categoryList={categoryList}
          handleOpenReferral={handleOpenReferral}
        />
      ) : (
        !isMobile && (
          <CloseSidebar
            handleCloseLanguage={handleCloseLanguage}
            switchTheme={switchTheme}
            loading={loading}
            categoryList={categoryList}
          />
        )
      )}
    </>
  );
}
