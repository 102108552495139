/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Helmet from "react-helmet";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import invited from "../../images/Users.png";
import copy from "../../images/Sendbutton.svg";
import {
  BonusReceiveStart,
  getReferralDetailsStart,
  getUserProfileDetailsStart,
} from "../../redux-saga/redux/profile/myProfile";
import config from "../../config";
import { getWalletDataStart } from "../../redux-saga/redux/wallet";

import "./style.css";

const ReferralAward = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { referralsDetail, userDetails, loading } = useSelector(
    (state) => state.myProfile
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [currentUsers, setCurrentUser] = useState([]);
  useEffect(() => {
    dispatch(getReferralDetailsStart());
  }, []);

  const usersPerPage = 10;
  const pageCount = Math.ceil(referralsDetail?.length / usersPerPage);
  const indexOfLastUser = (currentPage + 1) * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const calculateBonusWagerAmt = async (user) => {
    try {
      return {
        wagerAmount: user?.totalWageAmountWithGold,
      };
      // Set the wager detail including bonus
    } catch (err) {
      return { receiveBonusAmount: 0, wagerAmount: 0 };
    }
  };

  const fetchUsersWithBonus = async () => {
    if (referralsDetail.length > 0) {
      const usersWithBonus = await Promise.all(
        referralsDetail.map(async (el) => {
          const wagerDetail = await calculateBonusWagerAmt(el);
          return { ...el, wagerDetail };
        })
      );
      setCurrentUser(
        usersWithBonus
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(indexOfFirstUser, indexOfLastUser)
      );
    }
  };

  useEffect(() => {
    fetchUsersWithBonus();
  }, [referralsDetail, currentPage, indexOfFirstUser]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const handleCopy = async (text) => {
    try {
      if (text) {
        await navigator.clipboard.writeText(text);
        toast.success("Copied", { id: "copied" });
      }
    } catch (error) {
      console.error("Failed to copy text:", error);
      // Handle error or provide user feedback
    }
  };
  useEffect(() => {
    dispatch(getUserProfileDetailsStart());
    dispatch(getWalletDataStart());
  }, [referralsDetail, dispatch]);
  const handleReceive = async (user) => {
    try {
      if (!user?.id) {
        return toast.error("Refer Id not found", { id: "copied" });
      }
      if (user?.isReferReceive) {
        return toast.error("You have already receive", {
          id: "copied",
        });
      }
      const payload = {
        referId: user?.id,
      };
      dispatch(BonusReceiveStart(payload));
    } catch (err) {
      console.log("Error>>>>>>", err);
    }
  };
  const goBack = () => {
    navigate(-1);
  };
  const cutDecimal = () => {
    if (userDetails?.user?.total_refer_receive_bonus) {
      const decimalAmt =
        userDetails?.user?.total_refer_receive_bonus?.toFixed(2);
      const splitDec = decimalAmt.split(".");
      return splitDec;
    }
  };
  return (
    <div className="referral-page">
      <Helmet>
        <body className="referral-view transaction-remove-padding" />
      </Helmet>
      <div
        className="backBtnDiv referalBackBtnDiv"
        onClick={goBack}
        role="presentation"
      >
        <div className="backBtn">
          <img src="/images/back-icon.svg" alt="back-icon" />
          <p>Back</p>
        </div>
      </div>
      <div className="referral-head">
        <div className="referral-head-title">
          <h2>Referral award</h2>
          <p>Invite new member and get rewarded</p>
        </div>
        <div className="referral-head-list">
          <ul>
            <li>
              <b className="fw-600">Invited</b>{" "}
              <img src={invited} className="multiple-user-img" alt="invited" />{" "}
              <span className="fw-600">{referralsDetail?.length || 0}</span>
            </li>
            <li>
              <b className="fw-500 order_arrange"> Earned</b>{" "}
              <span className="earnSvgIcon">
                <ReferalCoinSvg />
              </span>
              <span className="fw-500">
                {userDetails?.user?.total_refer_receive_bonus ? (
                  <span>
                    {
                      cutDecimal(
                        userDetails?.user?.total_refer_receive_bonus || 0
                      )[0]
                    }
                    <span style={{ color: "#757786" }}>
                      .
                      {
                        cutDecimal(
                          userDetails?.user?.total_refer_receive_bonus || 0
                        )[1]
                      }
                    </span>
                  </span>
                ) : (
                  <span>
                    0<span style={{ color: "#757786" }}>.00</span>
                  </span>
                )}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="RoutesWraper">
        <div className="referral-content">
          <div className="referral-input-grid">
            <div className="referral-input-box">
              <label htmlFor="referralCode">Referral Code</label>
              <div className="referral-input">
                <input
                  type="text"
                  placeholder="Enter Referral Code"
                  id="referralCode"
                  value={userDetails?.user?.referral_code}
                />
                <button
                  onClick={() => handleCopy(userDetails?.user?.referral_code)}
                >
                  Copy
                </button>
                <span
                  onClick={() => handleCopy(userDetails?.user?.referral_code)}
                  role="presentation"
                >
                  <img src={copy} alt="invited" />
                </span>
              </div>
            </div>

            <div className="referral-input-box">
              <label htmlFor="referralLink">Referral Link</label>
              <div className="referral-input">
                <input
                  type="text"
                  placeholder="Enter Referral Link"
                  id="referralLink"
                  value={`${config?.clientUrl}?ref=${userDetails?.user?.referral_code}`}
                />
                <button
                  onClick={() =>
                    handleCopy(
                      `${config?.clientUrl}?ref=${userDetails?.user?.referral_code}`
                    )
                  }
                >
                  Copy
                </button>
                <span
                  onClick={() =>
                    handleCopy(
                      `${config?.clientUrl}?ref=${userDetails?.user?.referral_code}`
                    )
                  }
                  role="presentation"
                >
                  <img src={copy} alt="invited" />
                </span>
              </div>
            </div>
          </div>
          <div className="referaalTableMainWrap transaction-scroller">
            <div className="referaltheadMainWrap">
              <div className="transaction-head">
                <div className="fGap">
                  <div className="transaction-head-column">
                    <span>ID</span>
                  </div>
                </div>
                <div className="sGap">
                  <div className="transaction-head-column-new">
                    <span>Username</span>
                  </div>
                  <div className="transaction-head-column-new">
                    <span>Status</span>
                  </div>
                  <div className="transaction-head-column-new">
                    <span>Wagered</span>
                  </div>
                  <div className="transaction-head-column-new">
                    <span>Date joined</span>
                  </div>
                </div>
              </div>
              {currentUsers && currentUsers?.length > 0 ? (
                currentUsers?.map((user, index) => {
                  return (
                    <>
                      <div className="transaction-body" key={index}>
                        <div className="transaction-body-row">
                          <div className="fGap">
                            <div className="transaction-body-column">
                              <span>{indexOfFirstUser + index + 1}</span>
                            </div>
                          </div>
                          <div className="sGap">
                            <div className="transaction-body-column-new">
                              <span className="profileSpan">
                                <div className="positionrelative">
                                  <div className="clipPath">
                                    <img
                                      src={user?.profileImage || "./Avatar.png"}
                                      alt=""
                                    />
                                  </div>
                                  <span className="userProfileLevel">1</span>
                                </div>
                                <span>{user.userName}</span>
                              </span>
                            </div>
                            <div className="transaction-body-column-new">
                              <span
                                className={`${
                                  Number(user?.wagerDetail?.wagerAmount) < 100
                                    ? "profileSpan profileBG"
                                    : user.isReferReceive
                                    ? "referralGreen"
                                    : "referralBg"
                                } referralStatus `}
                                onClick={() =>
                                  !loading &&
                                  !user.isReferReceive &&
                                  Number(user?.wagerDetail?.wagerAmount) >=
                                    100 &&
                                  handleReceive(user)
                                }
                                role="presentation"
                              >
                                {Number(user?.wagerDetail?.wagerAmount) < 100
                                  ? "Progress.."
                                  : user?.isReferReceive
                                  ? "Received"
                                  : "Receive"}
                              </span>
                            </div>
                            <div className="transaction-body-column-new">
                              <span
                                className="profileSpan referral-progress-container"
                                style={{ display: "flex" }}
                              >
                                <div className="progress">
                                  <div
                                    className="progress-bar"
                                    style={{
                                      width: `${
                                        (user?.wagerDetail?.wagerAmount > 100
                                          ? 100
                                          : user?.wagerDetail?.wagerAmount) || 0
                                      }%`,
                                    }}
                                  ></div>
                                </div>
                                <span className="mobileMargin0">
                                  {(user?.wagerDetail?.wagerAmount > 100
                                    ? Number(100).toFixed(2)
                                    : user?.wagerDetail?.wagerAmount?.toFixed(
                                        2
                                      )) || "0.00"}
                                  <span
                                    style={{
                                      color: "#757786",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    /100.00
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div className="transaction-body-column-new">
                              <span className="profileSpan">
                                {dayjs(user.createdAt).format("MM/DD/YYYY")}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    color: "#757786",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  <span style={{ textAlign: "center" }}>No Data Found</span>
                </div>
              )}
            </div>
          </div>

          {referralsDetail && referralsDetail?.length > 10 ? (
            <div className="refferal-pagination">
              <ReactPaginate
                breakLabel="..."
                nextLabel={<NextArrow />}
                pageCount={pageCount}
                previousLabel={<PreArrow />}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                previousLinkClassName={"previous-page"}
                nextLinkClassName={"next-page"}
                disabledClassName={"pagination-disabled"}
                activeClassName={"pagination-active"}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default ReferralAward;

const PreArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 33 33"
      fill="none"
    >
      <g filter="url(#filter0_i_86_2806)">
        <rect width="33" height="33" rx="8" fill="#2B2B38" />
      </g>
      <rect
        x="0.7"
        y="0.7"
        width="31.6"
        height="31.6"
        rx="7.3"
        stroke="white"
        strokeOpacity="0.04"
        strokeWidth="1.4"
      />
      <path
        d="M17 13L13 16.5L17 20"
        stroke="#666773"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_i_86_2806"
          x="0"
          y="0"
          width="33"
          height="35"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_86_2806"
          />
        </filter>
      </defs>
    </svg>
  );
};

const NextArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 33 33"
      fill="none"
    >
      <g filter="url(#filter0_i_86_2809)">
        <rect
          x="33"
          y="33"
          width="33"
          height="33"
          rx="8"
          transform="rotate(-180 33 33)"
          fill="#2B2B38"
        />
      </g>
      <rect
        x="32.3"
        y="32.3"
        width="31.6"
        height="31.6"
        rx="7.3"
        transform="rotate(-180 32.3 32.3)"
        stroke="white"
        strokeOpacity="0.04"
        strokeWidth="1.4"
      />
      <path
        d="M16 20L20 16.5L16 13"
        stroke="#666773"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_i_86_2809"
          x="0"
          y="0"
          width="33"
          height="35"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_86_2809"
          />
        </filter>
      </defs>
    </svg>
  );
};

const ReferalCoinSvg = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14"
        cy="14"
        r="13.5"
        fill="url(#paint0_radial_1_14)"
        stroke="url(#paint1_linear_1_14)"
      />
      <ellipse
        cx="14"
        cy="14"
        rx="10.5"
        ry="10.5"
        fill="url(#paint2_linear_1_14)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.354 11.363L17.5914 8.60035C17.5201 8.52913 17.4235 8.48912 17.3228 8.48912H15.9161L16.9938 11.363H20.354ZM14.9317 19.0637L17.0126 12.1273H20.4808L14.9317 19.0637ZM7.51853 12.1276H10.9867L13.0676 19.0639L7.51853 12.1276ZM12.0839 8.48912L11.0062 11.363H7.64604L10.4086 8.60035C10.4798 8.52913 10.5765 8.48912 10.6772 8.48912H12.0839ZM16.2141 12.1273H11.7841L13.9991 19.5107L16.2141 12.1273ZM16.1772 11.363H11.8228L12.9005 8.48912H15.0995L16.1772 11.363Z"
        fill="#A822BF"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1_14"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14 28) rotate(-90) scale(28)"
        >
          <stop stopColor="#750D87" />
          <stop offset="1" stopColor="#EFA4FC" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_1_14"
          x1="14"
          y1="0"
          x2="14"
          y2="28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.75" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_14"
          x1="14"
          y1="38.15"
          x2="14"
          y2="3.49996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A557B2" />
          <stop offset="1" stopColor="#F6BFFE" />
        </linearGradient>
      </defs>
    </svg>
  );
};
