import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";

import session from "../../utils/session";
import { setNotificationSocketInstance } from "../../redux-saga/redux/notification";

const SOCKET_NAMESPACES = {
  NOTIFICATION: "/notification",
};

const WS_URL = process.env.REACT_APP_REST_WS_URL;

const WebNotificationSocketProvider = ({ children }) => {
  const user = session.getSessionCred;
  const token = user?.token;
  const accessToken = token;
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const { notificationSocketInstance } = useSelector(
    (state) => state.notification
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (accessToken && isLoggedIn && !notificationSocketInstance?.connected) {
      const newMessageIO = io(`${WS_URL}${SOCKET_NAMESPACES.NOTIFICATION}`, {
        transports: ["polling", "websocket"],
        auth: {
          "access-token": `${accessToken}`,
        },
      });
      dispatch(setNotificationSocketInstance(newMessageIO));
    }
  }, [
    accessToken,
    isLoggedIn,
    dispatch,
    notificationSocketInstance?.connected,
  ]);

  return children;
};

export default WebNotificationSocketProvider;
