import { put, takeLatest } from "redux-saga/effects";

import { showErrorMessage } from "../../redux/toast";
import {
  getNotificationsFailure,
  getNotificationsStart,
  getNotificationsSuccess,
} from "../../redux/notification";
import notificationService from "../../../services/notification";

function* notificationWatcher() {
  yield takeLatest(getNotificationsStart.type, getNotificationWorker);
}

function* getNotificationWorker() {
  try {
    const { data, error } = yield notificationService.getNotifications();
    if (!error) yield put(getNotificationsSuccess(data));
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getNotificationsFailure());
    }
  } catch (error) {
    console.log(error);
  }
}

export default notificationWatcher;
