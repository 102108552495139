/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Grid, Tab, Tabs, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import { isMobile } from "react-device-detect";
import ReactSelect from "react-select";

import SemiBanner from "../SemiBanner";
import useStyles from "./Lobby.styles";
import { getFeaturedGamesStart } from "../../../redux-saga/redux/casino/lobby/games";
import {
  getProvidersStart,
  getSubCategoryStart,
  getBannerImagesStart,
  getBonusImagesStart,
  getGamesStart,
  getBannerImageStart,
} from "../../../redux-saga/redux/casino/lobby";
import CategoryGames from "./CategoryGames";
import { getReferralCode } from "../../../redux-saga/redux/casino/lobby";
import SearchGames from "../SearchGames";
import { formatCategoryName } from "../../../utils/helper";

import "react-multi-carousel/lib/styles.css";

const Lobby = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { referralCode } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [category, setCategory] = useState(0);
  const { subCategory, banners } = useSelector((state) => state.casinoLobby);
  const { darkMode } = useSelector((state) => state.themeSettings);
  const subCategoryFiltered = [
    ...subCategory.filter((ele) => ele?.name !== "Slot"),
  ];
  const cryptoResponsive = {
    desktop: {
      breakpoint: { max: 5000, min: 1751 },
      items: 1,
    },
    miniDesktop1: {
      breakpoint: { max: 1750, min: 1537 },
      items: 1,
    },
    miniDesktop: {
      breakpoint: { max: 1536, min: 1200 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1199, min: 992 },
      items: 1,
    },
    miniTablet: {
      breakpoint: { max: 991, min: 768 },
      items: 1,
    },

    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  // const logoResponsive = {
  //   desktop: {
  //     breakpoint: { max: 5000, min: 1751 },
  //     items: 6,
  //   },
  //   miniDesktop1: {
  //     breakpoint: { max: 1750, min: 1537 },
  //     items: 4,
  //   },
  //   miniDesktop: {
  //     breakpoint: { max: 1536, min: 1200 },
  //     items: 4,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1199, min: 992 },
  //     items: 5,
  //   },
  //   miniTablet: {
  //     breakpoint: { max: 991, min: 768 },
  //     items: 4,
  //   },

  //   mobile: {
  //     breakpoint: { max: 768, min: 0 },
  //     items: 1,
  //   },
  // };

  useEffect(() => {
    dispatch(
      getGamesStart({
        limit: 20,
        isMobile: isMobile,
        isFeatured: true,
      })
    );
  }, []);

  useEffect(() => {
    dispatch(getFeaturedGamesStart());
  }, []);

  useEffect(() => {
    if (referralCode) {
      dispatch(getReferralCode(referralCode));
    }
    dispatch(getSubCategoryStart());
    dispatch(getProvidersStart());
  }, [dispatch, referralCode]);

  useEffect(() => {
    dispatch(getBannerImagesStart());
    dispatch(getBonusImagesStart());
    dispatch(getBannerImageStart());
  }, []);

  const handleChangeCategory = (event) => {
    setCategory(event);
  };

  const handleClick = (name, id, filterBy) => {
    if (name === "Recent Played") {
      navigate("/recents");
      return;
    }
    if (filterBy === "providers") {
      navigate(`/view-all/${id}/false/${name}`);
    }
    if (filterBy === "category") {
      navigate(`/view-all/false/${id || false}/${name}`);
    }
  };

  const handleSearch = (search) => {
    setSearchValue(search);
  };
  const reset = () => {
    setSearchValue("");
  };

  const handleUrl = (url) => {
    console.log("url", url);
    window.location.href = url;
  };

  const subCategoryOptions = subCategoryFiltered.map((item) => ({
    value: item.masterGameSubCategoryId,
    label: formatCategoryName(item.name),
  }));
  const SliderBanner =
    banners &&
    banners.length > 0 &&
    banners.filter((ele) => ele.banner_type === 2);
  return (
    <div className={`${classes.lobbyPage} lobbyContent marginToplobby`}>
      {isMobile ? (
        <div className="home-mob-slider">
          <Carousel
            responsive={cryptoResponsive}
            autoPlay={true}
            infinite={true}
            autoPlaySpeed={5000}
            arrows={true}
          >
            {SliderBanner &&
              SliderBanner.map((ele) => (
                <div
                  className={`${classes.bannerImage} banner_image`}
                  role="presentation"
                  key={ele.id}
                  onClick={() => handleUrl(ele.redirect_url)}
                >
                  <img src={ele.image_url} alt="bonus icon" />
                </div>
              ))}
          </Carousel>
        </div>
      ) : (
        <>
          <Carousel
            responsive={cryptoResponsive}
            autoPlay={false}
            infinite={true}
            autoPlaySpeed={5000}
            arrows={true}
            className="carousel-with-spacing"
          >
            {SliderBanner &&
              SliderBanner.map((ele) => (
                <div
                  className={`${classes.bannerImage} banner_image`}
                  role="presentation"
                  key={ele.id}
                  onClick={() => handleUrl(ele.redirect_url)}
                >
                  <img src={ele.image_url} alt="bonus icon" />
                </div>
              ))}
          </Carousel>
        </>
      )}

      <Grid className={classes.bonusSectionGrid}>
        <SemiBanner />
      </Grid>

      {/* <Grid className={classes.cryptoSection}>
        <Grid className={classes.cryptoText}>
          <List>
            <ListItem>
              <span>12</span>
              <span>Crypto currencies</span>
            </ListItem>

            <ListItem>
              <span>55</span>
              <span>Crypto networks</span>
            </ListItem>
          </List>
        </Grid>

        <Grid className={`${classes.cryptoLogo} cryptoLogo-2`}>
          <Carousel
            responsive={logoResponsive}
            autoPlay={true}
            infinite={true}
            autoPlaySpeed={4000}
            arrows={false}
          >
            <img src="images/crypto-1.svg" alt="crypto icon" />

            <img src="images/crypto-2.svg" alt="crypto icon" />

            <img src="images/crypto-3.svg" alt="crypto icon" />

            <img src="images/crypto-4.png" alt="crypto icon" />

            <img src="images/crypto-5.svg" alt="crypto icon" />

            <img src="images/crypto-6.svg" alt="crypto icon" />
          </Carousel>
        </Grid>

        <Grid className={classes.showAll}>
          <Box to="/" className={classes.showAllText}>
            Show All
          </Box>
        </Grid>
      </Grid> */}

      <Grid
        className={` ${classes.filterWrapCenter}  ${classes.selectBoxWrap} reactSeletContent`}
      >
        {isMobile ? (
          <ReactSelect
            className="basic-single"
            classNamePrefix="select"
            value={category}
            onChange={handleChangeCategory}
            closeMenuOnSelect={true}
            isSearchable={false}
            name="color"
            disabled={!(subCategory?.length > 0)}
            placeholder="All Games"
            options={[...subCategoryOptions]}
            styles={{
              control: (provided) => ({
                ...provided,
                width: "100% ", // Set width to 100%
              }),
              menu: (provided) => ({
                ...provided,
                borderRadius: "8px",
                marginTop: "-1px",
                width: "100%",
                padding: "0px",
              }),
              option: (provided, state) => ({
                ...provided,
                borderRadius: "8px", // Set the radius for the items
                width: "100%",
                background: state.isSelected ? "#24242b" : "transparent",
                color: state.isSelected ? "#fff" : "#fff",
                "&:hover": {
                  backgroundColor: "#24242b",
                },
              }),
              singleValue: (provided) => ({
                ...provided,
                color: darkMode ? "#fff" : "#24242b",
                borderRadius: "8px",
              }),
              menuList: (s) => ({
                ...s,
                background: "#24242b",
                borderRadius: "12px",
                fontSize: "14px",
                border: "1.4px solid rgba(255, 255, 255, 0.04)",
                boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: "8px",
              colors: {
                ...theme.colors,
                neutral0: darkMode ? "#2a2a2f" : "#f7df94",
                primary25: "rgba(123, 98, 218, 0.16)",
                neutral80: "#fff",
              },
            })}
          />
        ) : (
          subCategoryFiltered?.length > 0 && (
            <Tabs value="" indicatorColor="none" variant="scrollable">
              <div key={"All"}>
                <Grid className={`${classes.filterWrapLeft} filterDefault`}>
                  <Tab
                    onClick={() => {
                      navigate("/all-games");
                    }}
                    value={"All Games"}
                    key={"All"}
                    label={
                      <div className="tab-icon-title">
                        <img
                          style={{
                            width: "18px",
                          }}
                          src={"/images/All Games.svg"}
                          alt="Casino icon"
                        />{" "}
                        All Games
                      </div>
                    }
                  ></Tab>
                </Grid>
              </div>
              <div className="tab-space" key={"Recent Played"}>
                <Grid className={classes.filterWrapLeft}>
                  <Tab
                    onClick={() => handleClick("Recent Played", "", "category")}
                    disableRipple={true}
                    value={"Recent Played"}
                    key={"Recent Played"}
                    label={
                      <div className="tab-icon-title">
                        <img
                          src="/images/recent_played.svg"
                          alt="Casino icon"
                        />
                        {formatCategoryName("Recent Played")}
                      </div>
                    }
                  ></Tab>
                </Grid>
              </div>
              {subCategoryFiltered?.map((item, val) => (
                <div className="tab-space" key={item.tenantGameSubCategoryId}>
                  <Grid className={classes.filterWrapLeft}>
                    <Tab
                      onClick={() =>
                        handleClick(
                          item?.name,
                          item?.masterGameSubCategoryId,
                          "category"
                        )
                      }
                      disableRipple={true}
                      value={item?.name}
                      key={item?.masterGameSubCategoryId}
                      label={
                        item.iconName ? (
                          <div className="tab-icon-title">
                            <img src={item.iconName} alt="Casino icon" />
                            {formatCategoryName(item.name)}
                          </div>
                        ) : (
                          <div className="tab-icon-title">
                            <img
                              src="images/sidebar-poker.svg"
                              alt="Casino icon"
                            />
                            {formatCategoryName(item.name)}
                          </div>
                        )
                      }
                    ></Tab>
                  </Grid>
                </div>
              ))}
            </Tabs>
          )
        )}
        {!isMobile && (
          <Grid
            className={`${classes.filterWrapRight} ${classes.lobbysearchInput}`}
          >
            <Grid className={classes.formGroup}>
              <TextField
                autoComplete="off"
                autoCorrect="off"
                s
                id="outlined-basic"
                variant="outlined"
                placeholder={"Search for Game"}
                onChange={(e) => {
                  if (e?.target?.value?.length > 20) return;
                  handleSearch(e?.target?.value);
                }}
                value={searchValue}
                inputProps={{
                  maxLength: 40, // Set the maximum character limit
                }}
              />
              {searchValue ? (
                <CloseIcon onClick={reset} style={{ cursor: "pointer" }} />
              ) : (
                <img alt="srch" src="images/search.png" />
              )}
            </Grid>
          </Grid>
        )}
      </Grid>

      {isMobile && (
        <Grid className={classes.formGroup}>
          <div className={classes.mobSearch}>
            <SearchGames />
          </div>
        </Grid>
      )}

      <CategoryGames searchValue={searchValue} />
    </div>
  );
};

export default Lobby;
