/*eslint no-multiple-empty-lines: ["error", { "max": 2, "maxEOF": 0 }]*/
import React, { useState, useRef } from "react";

import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";

import { updateNotificationListAfterDismiss } from "../../../redux-saga/redux/notification/index";
import { socket } from "../../../services/webSocket/supportChatSocket";

const NotificationComponent = ({
  classes,
  handleCloseNotificationToggle,
  activeTab,
  setactiveTab,
  handleOpenComment,
  setShowAll,
  showAll,
}) => {
  const { notificationList, unseenNotifications } = useSelector(
    (state) => state.notification
  );
  const wrapperRef = useRef();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (id) => {
    setExpanded((prevState) => ({
      ...prevState,
      [id]: !prevState[id] || false,
    }));
  };

  const handleChangeTab = async (event, newValue) => {
    socket.emit("updateAndFetchNotifications", {
      activeTab: newValue,
      update: activeTab === "new",
      response: true,
    });
    setactiveTab(newValue);
  };

  const handleDismiss = async (id, status) => {
    try {
      if (!status) return;
      socket.emit("dismissNotification", {
        type: activeTab,
        id,
      });
      const updatedNotificationList = notificationList.filter(
        (notification) => notification.id !== id
      );
      dispatch(updateNotificationListAfterDismiss(updatedNotificationList));
    } catch (err) {
      console.log("Error in the Dissmiss Notification", err);
    }
  };
  return (
    <>
      <Grid ref={wrapperRef}>
        {/* {notificationToggle && ( */}
        <Box className={classes.notificationContent}>
          <Box className={classes.notificationHeader}>
            <Box className={classes.notificationHeading}>
              <h3>Notification</h3>
              {unseenNotifications > 0 && (
                <Box className={classes.notificationCount}>
                  <span>{unseenNotifications}</span>
                </Box>
              )}
            </Box>
            <Box
              className={classes.closeIconWrap}
              onClick={handleCloseNotificationToggle}
            >
              <CloseIcon style={{ cursor: "pointer" }} />
            </Box>
          </Box>
          <Grid className={classes.notificationBody}>
            <Tabs
              id="noanim-tab-example"
              value={activeTab}
              onChange={handleChangeTab}
              aria-label="notification tabs"
            >
              <Tab label="New" value="new" />
              <Tab label="All" value="all" />
            </Tabs>
            {activeTab === "new" && (
              <TabComponent
                classes={classes}
                expanded={expanded}
                handleExpandClick={handleExpandClick}
                notificationList={notificationList}
                handleDismiss={handleDismiss}
                showAll={showAll}
                setShowAll={setShowAll}
                handleOpenComment={handleOpenComment}
                unseenNotifications={unseenNotifications}
              />
            )}
            {activeTab === "all" && (
              <TabComponent
                value="all"
                classes={classes}
                expanded={expanded}
                handleExpandClick={handleExpandClick}
                notificationList={notificationList}
                handleDismiss={handleDismiss}
                showAll={showAll}
                setShowAll={setShowAll}
                handleOpenComment={handleOpenComment}
                unseenNotifications={unseenNotifications}
              />
            )}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

const TabComponent = ({
  classes,
  expanded,
  handleExpandClick,
  notificationList,
  handleDismiss,
  showAll,
  setShowAll,
  handleOpenComment,
  unseenNotifications,
}) => {
  function timeElapsed(timestamp) {
    const currentTime = new Date();
    const previousTime = new Date(timestamp);
    const elapsedMilliseconds = currentTime - previousTime;
    const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);

    if (elapsedSeconds < 60) {
      return elapsedSeconds + " seconds ago";
    } else if (elapsedSeconds < 3600) {
      const elapsedMinutes = Math.floor(elapsedSeconds / 60);
      return elapsedMinutes + " minutes ago";
    } else if (elapsedSeconds < 86400) {
      const elapsedHours = Math.floor(elapsedSeconds / 3600);
      return elapsedHours + " hours ago";
    } else {
      const elapsedDays = Math.floor(elapsedSeconds / 86400);
      return elapsedDays === 1
        ? elapsedDays + " day ago"
        : elapsedDays + " days ago";
    }
  }
  function capitalizeFirstLetter(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  function capitalizeBonusAndGold(text) {
    const regex = /\b(bonus coins|gold coins)\b/gi;
    const result = text.replace(regex, (match) => capitalizeFirstLetter(match));
    return result;
  }

  return (
    <Grid
      className={`${classes.notificationBodyScroll} ${
        isMobile ? classes.notificationBodyHeight : ""
      } ${showAll ? classes.notificationBodyHeightShow : ""}`}
    >
      <Grid className={classes.notificationBoxGrid}>
        {/* {notificationList.map((elem, i) => { */}
        {notificationList
          .slice(0, showAll || !isMobile ? notificationList.length : 3)
          .map((elem, i) => {
            let word = elem.headingMessage.split(" ");
            let lastword = word[word.length - 1];
            let notifyMsg = elem.subHeadingMessage.slice(0, 45);
            return (
              <Box className={classes.notificationBox} key={i}>
                <Grid
                  className={`${classes.notificationBoxImg} debitCardImgOuter`}
                >
                  <img
                    src={
                      elem?.typeOfRequest === "cashapp" && lastword === "Failed"
                        ? "/images/cashapp-reject.svg"
                        : elem?.typeOfRequest === "cashapp" &&
                          lastword === "Received"
                        ? "/images/cashapp-accept.svg"
                        : elem?.typeOfRequest === "bitcoin" &&
                          (lastword === "Failed" || lastword === "Rejected")
                        ? "/images/bitCoinFail.svg"
                        : elem?.typeOfRequest === "bitcoin" &&
                          lastword === "Received"
                        ? "/images/bit-coin.svg"
                        : elem?.transactiontype === "withdraw" &&
                          (lastword === "Failed" || lastword === "Rejected")
                        ? "/images/cashapp-reject.svg"
                        : elem?.transactiontype === "withdraw" &&
                          lastword === "Approved"
                        ? "/images/cashapp-accept.svg"
                        : elem?.typeOfRequest === "referral"
                        ? "/images/referral-award.svg"
                        : elem?.typeOfRequest === "xcoin" &&
                          lastword === "Started"
                        ? "/images/avtar_yellow.svg"
                        : elem?.typeOfRequest === "xcoin" &&
                          lastword === "Received"
                        ? "/images/Notification_avatar.svg"
                        : ""
                    }
                    alt="bitcoin"
                  />
                </Grid>
                <Grid className={classes.notificationBoxContent}>
                  <Grid className={classes.notificationBoxBody}>
                    <Box className={classes.notificationBoxHead}>
                      <h4
                        className={
                          lastword === "Failed" || lastword === "Rejected"
                            ? classes.notificationFailMsg
                            : elem?.typeOfRequest === "referral" ||
                              (elem?.typeOfRequest === "xcoin" &&
                                lastword === "Started")
                            ? classes.notificationReferralMsg
                            : ""
                        }
                      >
                        {elem.headingMessage}
                      </h4>
                    </Box>
                    <Box className={classes.notificationBoxText}>
                      <p
                        className={
                          expanded[elem.id] ? classes.expandedText : ""
                        }
                      >
                        {capitalizeBonusAndGold(
                          !expanded[elem.id]
                            ? notifyMsg + "... "
                            : elem.subHeadingMessage + " "
                        )}
                        <span
                          onClick={() => {
                            handleExpandClick(elem.id);
                          }}
                          className={classes.seeMore}
                          role="presentation"
                        >
                          {expanded[elem.id] ? "see less" : "see more"}
                        </span>
                      </p>
                    </Box>
                  </Grid>
                  <Box className={classes.notificationBoxFooterSection}>
                    <Box className={classes.notificationBoxFooter}>
                      <Button
                        className={`${classes.notificationBoxBtn} ${classes.dismissBtn}`}
                        onClick={() => handleDismiss(elem.id, elem.isRead)}
                      >
                        Dismiss
                      </Button>
                    </Box>
                    {!isMobile ? (
                      <p className={classes.grayTxt}>
                        {timeElapsed(elem?.createdAt)}
                      </p>
                    ) : (
                      ""
                    )}

                    {isMobile && <p>{timeElapsed(elem?.createdAt)}</p>}
                  </Box>
                </Grid>
              </Box>
            );
          })}
        {isMobile && !showAll && notificationList.length > 3 && (
          <Box className={classes.notificationBtn}>
            <Button onClick={() => setShowAll(true)}>See All</Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default NotificationComponent;
