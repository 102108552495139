import React from "react";

import { Box, Button, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "../Header/Login/Login.styles";

const ViewCommentsPopup = ({ setOpenViewComment, commentMsg }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.authPopupForm} comment-width`}>
      <div className={classes.userForm}>
        <Box
          className={`${classes.closeIconWrap} close-cross`}
          onClick={() => {
            setOpenViewComment(false);
          }}
        >
          <CloseIcon
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "20px",
              height: "20px",
            }}
          />{" "}
        </Box>
      </div>

      <Grid className={classes.loginFormWrap}>
        <div style={{ textAlign: "center" }}>
          <img
            className="pop-up-img"
            src="/images/info-banner-popup.png"
            alt="modal banner img"
          />
        </div>
        <Grid className="exit-para newMArgin">
          <p className="exit-para-head">Comment from support</p>
        </Grid>
        <Grid>
          <div className="comment-section">
            <p>{commentMsg}</p>
          </div>
        </Grid>

        <Grid className=" exit-margin-bt  exit-margin-new  ">
          <Button
            className={`${classes.btnPrimary} exit-text-btn `}
            onClick={() => {
              setOpenViewComment(false);
            }}
          >
            Dismiss
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewCommentsPopup;
