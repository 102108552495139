import { io } from "socket.io-client";

import session from "../../utils/session";
const server = process.env.REACT_APP_SERVER_URL;
const user = session.getSessionCred;
const token = user?.token;
const accessToken = token;
const socket = io(server, {
  transports: ["websocket"],
  rejectUnauthorized: false,
  auth: {
    authorization: `Bearer ${accessToken}`,
    "access-token": `${accessToken}`,
  },

  reconnection: false,
});
socket.on("connect", () => {
  console.log("connected");
});

socket.emit("join", localStorage.getItem("userId"));
socket.on("disconnect", () => {
  console.log("Disconnected");
});

const tryReconnect = () => {
  setTimeout(() => {
    socket.io.open((err) => {
      if (err) {
        tryReconnect();
      }
    });
  }, 2000);
};

socket.io.on("close", tryReconnect);

export { socket };
