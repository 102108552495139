import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  modal: {
    width: "80%",
    margin: "0 auto",
    marginTop: "80px",
    [theme.breakpoints.down(575)]: {
      width: "100%",
      marginTop: "-67px",
    },
  },

  mobileBgColor: {
    [theme.breakpoints.down(991)]: {
      width: "100%",
      marginTop: "67px",
      height: "90%",
      background: "#24242b",
      borderRadius: "12px",
      fontSize: "12px",
      border: "2px solid rgba(255, 255, 255, 0.04)",
      boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
    },
  },

  modalheader: {
    padding: "1rem",
    backgroundColor: "#24242B",
    color: theme?.palette?.text?.placeholder,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .MuiTypography-h6": {
      fontSize: "18px",
      fontWeight: "600",
    },
    "& svg": {
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
  },
}));
