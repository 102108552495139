import React, { useState } from "react";

import { Button } from "@mui/material";
import toast from "react-hot-toast";

import { validateEmail } from "../../../../utils/helper";
import authenticationService from "../../../../services/authentication";
import { ErrorMessage } from "../constants";

function EnterMail({ setForgotViews, mail, setMail, handleBackToLogin }) {
  const [error, setError] = useState("");
  const handleSendOtp = async () => {
    if (validateEmail(mail)) {
      const response = await authenticationService.sendOtp({
        email: mail.toLowerCase(),
      });
      if (response.status === 200) {
        setForgotViews("verify_otp");
        return;
      }
      if (response.message === "User Not Found") {
        setError("The Email Address is invalid!");
        return;
      }
    }
    toast.error("Invalid Email", { id: "otp-mail-validation-error" });
  };
  return (
    <div className="enterMailMainWrap masterMainWrap">
      <div className="loginFormMainWrap">
        <div className="inputWrap">
          <div className="input-login-form-wrap forgotPasswordInput">
            <div className="input-container-login">
              <label htmlFor="email" className="input-label">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="custom-input"
                placeholder="user@gmail.com"
                value={mail}
                onChange={(event) => setMail(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <ErrorMessage error={{ message: error }} />
      <div className="bottomBtnFixedWrap">
        <div className="logInBtnWrap">
          <Button type="button" key="enter_mail" onClick={handleSendOtp}>
            Send OTP
          </Button>
        </div>
        <div className="logInBtnWrap backBtnFull">
          <Button onClick={handleBackToLogin}>Back to login</Button>
        </div>
      </div>
    </div>
  );
}

export default EnterMail;
