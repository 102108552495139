import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    marginLeft: "0px",
  },
  btnPrimary: {
    color: "#FFFFFF !important",
    width: "100%",
    background: theme?.palette?.background?.buttonbackground,
    border: theme?.palette?.background?.toggleBorder,
    boxShadow: theme?.palette?.background?.toggleShadow,
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "26px",
    borderRadius: "12px !important",
    textTransform: "capitalize !important",
    "&:hover": {
      background: theme?.palette?.background?.buttonbackground,
    },
  },
  forgotForm: {
    maxWidth: "440px",
    width: "100%",
    padding: "20px",
    background: theme.palette.background?.sidebar,
    textAlign: "center",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    borderRadius: "0 0 15px 15px",
  },
  forgotFormWrap: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "10px",
    flexDirection: "column",
    "& .MuiFormControl-root": {
      "&:first-child": {
        marginRight: "15px",
      },
    },
    "& img ": {
      width: "100%",
      maxWidth: "440px",
      margin: "0 auto",
    },
  },
  disabled: {
    background:
      "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
    border: "1.4px solid rgba(106, 113, 199, 0.84)",
    boxShadow: "0px 4px 12px 2px rgba(108, 93, 193, 0.24)",
    borderRadius: "12px !important",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "26px",
    width: "100%",
    textTransform: "capitalize !important",
    color: "#FFFFFF !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
      height: "32px !important",
      borderRadius: "8px !important",
    },
    opacity: 0.5,
  },
  formInput: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: theme?.palette?.text?.color,
    marginBottom: "8px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "6px",
      lineHeight: "normal",
    },
  },
  formGroup: {
    position: "relative",
    marginBottom: "10px",
    width: "100%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },

  forgotBtnWrap: {
    marginTop: "0 !important",
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "10px",
    fontSize: "14px",
    textDecoration: "none",
    color: theme?.palette?.text?.default,
    cursor: "pointer",
  },
  forgotFormContainer: {
    "& fieldset": {
      border: "none",
    },
    "& .MuiTypography-root": {
      textAlign: "center",
      fontSize: "16px",
      marginBottom: "20px",
    },
    "& .MuiInputBase-input": {
      height: "40px",
      padding: "0px 20px",
      background: theme?.palette?.background?.default2,
      color: theme?.palette?.text?.default,
      boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "12px",
      fontWeight: "500",
      "&::placeholder": {
        color: "#4D4D5B",
        fontWeight: "500",
        fontSize: "16px",
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
        },
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#7458DC",
        },
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiFormLabel-root": {
      // top: "-5px",
      fontSize: "12px",
      color: "rgba(255, 255, 255, 0.3)",
    },
  },
  authBtnWrap: {
    margin: "15px 0",
    "& button": {
      fontSize: "14px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },
}));
