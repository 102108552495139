import * as yup from "yup";

const LOGIN_SIGNUP_ENUMS = Object.freeze({
  login: "login",
  signup: "signup",
});

const LOGIN_SIGNUP_STATES = Object.freeze({
  RegisterConfirmation: "register_confirmation",
  RegisterNameSelection: "register_name_selection",
});

const ERROR_ENUMS = Object.freeze({
  email: "Invalid Email",
  passwordLength: "Password must be at least 8 characters long.",
  passwordChar:
    "Password must contain at least one uppercase letter (A-Z), one lowercase letter (a-z), one number (0-9), and one special character (e.g., @, #, $, %).",
  passwordMismatch: "Confirm passwords do not match",
});

const registerValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

const GAME_IMG = [
  {
    id: "1",
    gameImage: "/images/game1.png",
  },
  {
    id: "2",
    gameImage: "/images/game2.png",
  },
  {
    id: "3",
    gameImage: "/images/game3.png",
  },
  {
    id: "4",
    gameImage: "/images/game1.png",
  },
  {
    id: "5",
    gameImage: "/images/game1.png",
  },
  {
    id: "6",
    gameImage: "/images/game2.png",
  },
  {
    id: "7",
    gameImage: "/images/game3.png",
  },
  {
    id: "8",
    gameImage: "/images/game1.png",
  },
  {
    id: "9",
    gameImage: "/images/game1.png",
  },
  {
    id: "10",
    gameImage: "/images/game2.png",
  },
  {
    id: "11",
    gameImage: "/images/game3.png",
  },
  {
    id: "12",
    gameImage: "/images/game1.png",
  },
];

const forgotPasswordValidationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(
      /[@$!%*?&#]/,
      "Password must contain at least one special character"
    )
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .required("This field must be filled"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password doesn't match")
    .required("This field must be filled"),
});

const ErrorMessage = ({ error }) => {
  if (error?.message)
    // eslint-disable-next-line react/react-in-jsx-scope
    return <p className="form-error-message-submit">{error?.message}</p>;
  return null;
};

export {
  LOGIN_SIGNUP_ENUMS,
  LOGIN_SIGNUP_STATES,
  ERROR_ENUMS,
  registerValidationSchema,
  GAME_IMG,
  forgotPasswordValidationSchema,
  ErrorMessage,
};
