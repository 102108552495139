import { LEADERBOARD_TABLE_LIMIT } from "../../utils/constants";
import axiosClient from "../axios";

export default class liveTableService {
  static async getTopBets() {
    try {
      const { data } = await axiosClient.get(
        `/casino/getAllRecentBet?limit=${LEADERBOARD_TABLE_LIMIT}`,
        {
          headers: {
            "with-token": false,
          },
        }
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async getHighRollerBets() {
    try {
      const { data } = await axiosClient.get(
        `/casino/getAllHighRollersBets?limit=${LEADERBOARD_TABLE_LIMIT}`,
        {
          headers: {
            "with-token": false,
          },
        }
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }

  static async getLuckyWinnersBets() {
    try {
      const { data } = await axiosClient.get(
        `/casino/getAllLuckyWinnerBets?limit=${LEADERBOARD_TABLE_LIMIT}`,
        {
          headers: {
            "with-token": false,
          },
        }
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
}
