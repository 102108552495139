import { createSlice } from "@reduxjs/toolkit";

export const cashAppInitialState = {
  loading: false,
  success: false,
  error: null,
  tanDetails: {},
  cashAppPayList: null,
  withDetails: {},
  cashAppWithdrawList: null,
  defaultCashAppData: null,
  remainingWithdrawal: "",
};

const cashAppSlice = createSlice({
  name: "cashAppTransaction",
  initialState: cashAppInitialState,
  reducers: {
    // deposit api action start from here
    cashApptransactionsSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.tanDetails = action.payload;
    },
    cashApptransactionsFailure: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    cashAppTransactionStart: (state, action) => {
      state.loading = true;
    },
    getCashAppTransactionStart: (state, action) => {
      state.loading = true;
    },
    getCashAppTransactionSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.cashAppPayList = action.payload;
    },
    getCashAppTranscationFailure: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    cashAppStatusChangeStart: (state, action) => {
      state.loading = true;
    },
    cashAppStatusChangeSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
    },
    cashAppStatusChangeFailure: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },

    // Withdraw api action start from here
    cashAppWithdrawSuccess: (state, action) => {
      state.loading = false;
      state.loading = true;
      state.withDetails = action.payload;
      state.openPopUp = true;
      state.remainingWithdrawal = -1;
      state.error = "";
    },
    cashAppWithdrawStart: (state, action) => {
      state.loading = true;
      state.remainingWithdrawal = "";
      state.error = null;
    },
    cashAppWithdrawFailure: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload.message;
      state.remainingWithdrawal = action?.payload?.data?.remainingWithdrawal;
    },
    cashAppCloseNotificationPopup: (state, action) => {
      state.remainingWithdrawal = "";
    },
    getCashAppWithdrawStart: (state, action) => {
      state.loading = true;
    },
    getCashAppWithdrawSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.remainingWithdrawal = "";
    },
    getCashAppWithdrawFailure: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    getDefaultPayDataStart: (state, action) => {
      state.loading = true;
    },
    getDefaultPayDataSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.defaultCashAppData = action.payload;
    },
    getDefaultPayDataFailure: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    clearDepositStates: (state) => {
      state.remainingWithdrawal = "";
    },
  },
});

export const {
  cashApptransactionsSuccess,
  cashApptransactionsFailure,
  cashAppTransactionStart,
  getCashAppTranscationFailure,
  getCashAppTransactionSuccess,
  getCashAppTransactionStart,
  cashAppStatusChangeStart,
  cashAppStatusChangeSuccess,
  cashAppStatusChangeFailure,
  cashAppWithdrawSuccess,
  cashAppWithdrawStart,
  cashAppWithdrawFailure,
  getCashAppWithdrawStart,
  getCashAppWithdrawSuccess,
  getCashAppWithdrawFailure,
  getDefaultPayDataStart,
  getDefaultPayDataFailure,
  getDefaultPayDataSuccess,
  cashAppCloseNotificationPopup,
  clearDepositStates,
} = cashAppSlice.actions;

export default cashAppSlice.reducer;
