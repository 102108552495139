import axiosClient from "../axios";

export default class chatboxService {
  static async getPrevousChatsList({ language }) {
    try {
      const { data } = await axiosClient.get(
        `/user/get-messages?language=${language}`
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async getPreviousSupportChatsList({ language }) {
    try {
      if (localStorage.getItem("userId")) {
        const { data } = await axiosClient.get(
          `/user/get-supportChat?language=${language}&&roomId=${localStorage.getItem(
            "userId"
          )}`
        );
        return data;
      }
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async updateUnseenCount() {
    try {
      const { data } = await axiosClient.post("/user/update-unseen-count");
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async updateSupportMsgUnseenCount() {
    try {
      const { data } = await axiosClient.post(
        "/user/update-unseen-count-ofSupportMsg"
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async getUnseenCountOfSupportChat() {
    try {
      const { data } = await axiosClient.get("/user/get-newSupportMsgCount");
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async getUsersList({ search }) {
    try {
      const { data } = await axiosClient.get(
        `/user/getUserList?search=${search}`
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
}
