/* eslint-disable no-lone-blocks */
import React, { useState } from "react";

import { Button } from "@mui/material";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import authenticationService from "../../../../services/authentication";
import { ErrorMessage, forgotPasswordValidationSchema } from "../constants";

export default function EnterNewPassword({ setForgotViews, mail, setOpen }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordValidationSchema),
    defaultValues: { password: "", confirmPassword: "" },
  });
  const onSubmit = async (data) => {
    const response = await authenticationService.updatePassword({
      email: mail,
      password: window.btoa(data?.password),
    });
    if (response.status === 200) {
      setForgotViews("enter_password");
      setOpen(false);
      return;
    }
    toast.error("Internal Server Error", {
      id: "password-change-error",
    });
  };
  return (
    <div className="enterMailMainWrap errormsgWrap masterMainWrap">
      <form>
        <div className="loginFormMainWrap forgotPasswordInput">
          <div className="inputWrap">
            <div className="input-container-login newLoginInput">
              <label htmlFor="password">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="custom-input"
                placeholder="********"
                {...register("password")}
              />
              <img
                src={
                  errors?.password
                    ? "/images/redEye.png"
                    : "/images/showPassword.png"
                }
                alt=""
                className="showPassword"
                onClick={() => setShowPassword((p) => !p)}
                role="presentation"
              />
              <ErrorMessage error={errors?.password} />
            </div>
            <div className="input-container-login newLoginInput marginTop12">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                className="custom-input"
                placeholder="********"
                {...register("confirmPassword")}
              />
              <img
                src={
                  errors?.confirmPassword
                    ? "/images/redEye.png"
                    : "/images/showPassword.png"
                }
                alt=""
                className="showPassword"
                onClick={() => setShowConfirmPassword((p) => !p)}
                role="presentation"
              />
              <div className="errorWrap">
                {" "}
                <ErrorMessage error={errors?.confirmPassword} />
              </div>
            </div>
          </div>
        </div>
        <div className="bottomBtnFixedWrap">
          <div className="logInBtnWrap">
            <Button onClick={handleSubmit(onSubmit)}>Reset password</Button>
          </div>
        </div>
      </form>
    </div>
  );
}
