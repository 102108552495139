import { createTransform } from "redux-persist";

import { authInitialState } from "./redux/auth/authentication";
import { lobbyInitialState } from "./redux/casino/lobby";
import { resetPswdInitialState } from "./redux/auth/resetPassword";
import { kycVerifInitialState } from "./redux/kycVerification";
import { myProfInitialState } from "./redux/profile/myProfile";
import { themeInitialState } from "./redux/settings/themeSettings";
import { currencyInitialState } from "./redux/currencies/currencies";
import { cmsInitialState } from "./redux/cms";
import { promotionInitialState } from "./redux/promotions";
import { walletInitialState } from "./redux/wallet";
import { chatBoxInitialState } from "./redux/chatbox";
import { favoritesInitialState } from "./redux/casino/lobby/games";
import { paymentInitialState } from "./redux/payment";
import { liveTableInitialState } from "./redux/LiveTable";
import { cashAppInitialState } from "./redux/CashApp";
import { notificationInitialState } from "./redux/notification";
import { transactionInitialState } from "./redux/transaction";

// Custom transform to persist only the `games` state and reset other keys
const onlyGamesTransform = createTransform(
  (inboundState, key) => {
    // Only persist the `games` state in `casinoLobby`
    if (key === "casinoLobby") {
      return { games: inboundState.games };
    }
    return inboundState;
  },
  (outboundState, key) => {
    // Reset the state to the initial state, but keep `games` in `casinoLobby`
    if (key === "casinoLobby") {
      return {
        ...lobbyInitialState,
        games: outboundState.games || [],
      };
    }
    if (key === "authentication") {
      return {
        ...authInitialState,
      };
    }
    if (key === "resetPassword") {
      return {
        ...resetPswdInitialState,
      };
    }
    if (key === "kycVerification") {
      return {
        ...kycVerifInitialState,
      };
    }
    if (key === "myProfile") {
      return {
        ...myProfInitialState,
      };
    }
    if (key === "transactions") {
      return {
        ...transactionInitialState,
      };
    }
    if (key === "themeSettings") {
      return {
        ...themeInitialState,
      };
    }
    if (key === "currencies") {
      return {
        ...currencyInitialState,
      };
    }
    if (key === "cms") {
      return {
        ...cmsInitialState,
      };
    }
    if (key === "promotions") {
      return {
        ...promotionInitialState,
      };
    }
    if (key === "wallet") {
      return {
        ...walletInitialState,
      };
    }
    if (key === "chatbox") {
      return {
        ...chatBoxInitialState,
      };
    }
    if (key === "favorites") {
      return {
        ...favoritesInitialState,
      };
    }
    if (key === "payment") {
      return {
        ...paymentInitialState,
      };
    }
    if (key === "liveTable") {
      return {
        ...liveTableInitialState,
      };
    }
    if (key === "cashAppTransaction") {
      return {
        ...cashAppInitialState,
      };
    }
    if (key === "notification") {
      return {
        ...notificationInitialState,
      };
    }
    return outboundState;
  },
  {
    whitelist: [
      "casinoLobby",
      "authentication",
      "resetPassword",
      "kycVerification",
      "myProfile",
      "transactions",
      "themeSettings",
      "currencies",
      "cms",
      "promotions",
      "wallet",
      "chatbox",
      "favorites",
      "payment",
      "liveTable",
      "cashAppTransaction",
      "notification",
    ],
  }
);

export default onlyGamesTransform;
