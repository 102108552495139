import React from "react";

export default function PaymentSuccess({ transactionStatus }) {
  return (
    <div className="payment-confirmation">
      <div className="paymentConfirmation-Img text-center">
        <img
          src={
            transactionStatus.status === 200
              ? "/images/paymentSuccess.png"
              : "/images/failed.png"
          }
          alt=""
        />
      </div>
      <div className="payment-information-wrap">
        <h3>Payment success</h3>
        <p>Amount</p>
        <h4>${transactionStatus?.amount}</h4>
      </div>
      <div className="payment-details-table">
        <h3>Payment details</h3>
        <div className="tableWrap">
          <div className="tableFlexDiv">
            <p>Transaction ID</p>
            <span>#{transactionStatus?.id}</span>
          </div>
          <div className="tableFlexDiv">
            <p>Time created</p>
            <span>{transactionStatus?.createdAt}</span>
          </div>
          <div className="tableFlexDiv">
            <p>Payment type</p>
            <span>
              <img
                src="/images/greenDollar.png"
                className="greenDollarImg"
                alt=""
              />
              {transactionStatus?.payment}
            </span>
          </div>
          <div className="tableFlexDiv">
            <p>Receiver address</p>
            <span>https://cash.app/$dianafta</span>
          </div>
          <div className="flexBottomDivBonuscoin">
            <div className="tableFlexDiv">
              <p className="bottom13">Amount GC</p>
              <span className="spanWeight">
                {transactionStatus?.goldCoins}
                <img
                  src="/images/Gold-coin1.png"
                  className="goldCoinsImg"
                  alt=""
                />
              </span>
            </div>
            <div className="tableFlexDiv marginLeft64">
              <p className="bottom13">Bonus</p>
              <span className="spanWeight">
                {transactionStatus?.bonusCoins}
                <img
                  src="/images/Bonus-coin.png"
                  className="goldCoinsImg"
                  alt=""
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flexBtnDiv">
        <div className="select-payment-method-button" style={{ width: "100%" }}>
          <button>
            <img src="/images/share.png" className="buttonIcon" alt="" /> Share
          </button>
        </div>
        <div className="select-payment-method-button" style={{ width: "100%" }}>
          <button>
            <img src="/images/printer.png" className="buttonIcon" alt="" />{" "}
            Print receipt
          </button>
        </div>
      </div>
    </div>
  );
}
