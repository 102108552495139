import React from "react";

import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "./Comment.styles";

const CommentPopup = ({ handleCloseComment, commentPopup }) => {
  const classes = useStyles();

  return (
    <div className={classes.authPopupForm}>
      <Grid
        className={`${classes.loginFormWrap} ${classes.referralForm1} ${classes.commentPopup}`}
      >
        <div className={classes.commentSupport}>
          <Box
            onClick={handleCloseComment}
            className={classes.closeIconComment}
          >
            <CloseIcon style={{ cursor: "pointer" }} />
          </Box>
        </div>
        <form action="#" method="post">
          <Grid className={classes.formGroup}>
            <Typography className={`${classes.commentHeadingTop}`}>
              Comment From Support
            </Typography>
            <Box className="textAreaBox">
              <TextField
                id="message"
                multiline
                rows={5}
                variant="outlined"
                value={commentPopup}
                disabled
                InputLabelProps={{ shrink: false }}
                className={`${classes.textField} textFeildAreaPd`}
              />
            </Box>
          </Grid>
          <Button
            className={`${classes.dismisButton} btn-comment`}
            onClick={handleCloseComment}
          >
            Dismiss
          </Button>
        </form>
      </Grid>
    </div>
  );
};

export default CommentPopup;
