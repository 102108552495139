import React from "react";

import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import { StepsEnum } from "./constants";
import { clearDepositData } from "../../../../redux-saga/redux/payment";

export default function BitCoinPayment({ activeCard, setStep }) {
  const dispatch = useDispatch();
  const { generateAddress, convertedDepositBalance } = useSelector(
    (state) => state.payment
  );

  const copyHandler = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied", {
      id: "PAYMENT.PAYMENT_COPIED_CLIPBOARD",
    });
  };

  return (
    <Grid className="QRpaymentDetailsWrap">
      <span>Please send your BTC to our address below method</span>
      <div className="paymentMainContentWrap">
        <div className="copyWrap">
          <p>{generateAddress?.slice?.(0, 26)}...</p>
          <span
            className="mobileCopyImg"
            role="button"
            tabIndex="0"
            onKeyDown={() => {}}
            onClick={() => copyHandler(generateAddress)}
          >
            <img src="/images/copyIcon.png" alt="" />
          </span>
        </div>
        <div className="bitCoinQRWrap">
          <div className="qr-inside-wrap">
            <img src="/images/bitcoinQR.png" alt="" />
            <div className="contentWrap">
              <div>
                <p>Price</p>
                <h4>{convertedDepositBalance?.amount_from} USD</h4>
              </div>
              <div className="marginbottom8">
                <p>Amount</p>
                <h4>{convertedDepositBalance?.estimated_amount} btc</h4>
              </div>
              <div className="LoaderWrap">
                <div className="loaderNew"></div>
                <p>Waiting</p>
              </div>
            </div>
          </div>
        </div>
        <div className="secondCopyInput marginTop24">
          <span>Share a permanent link to a hosted page:</span>
          <div className="copyWrap copyWrapSec">
            <p>https://www.blockchain.com/e...</p>
            <span className="mobileCopyImg">
              <img src="/images/copyIcon.png" alt="" />
            </span>
          </div>
        </div>
      </div>
      <div className="flexBtnDiv bitCoinPaymentBtnWrap">
        <div className="select-payment-method-button" style={{ width: "100%" }}>
          <button
            onClick={() => {
              dispatch(clearDepositData());
              setStep(StepsEnum.Method);
            }}
          >
            Back
          </button>
        </div>
        <div style={{ width: "100%" }}>
          <div className="buyNowBtnWrap">
            <button>Pay ${activeCard?.price}</button>
          </div>
        </div>
      </div>
    </Grid>
  );
}
