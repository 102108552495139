import React, { useEffect, useState } from "react";

import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

import { GAME_IMG, LOGIN_SIGNUP_STATES } from "./constants";
import { signUpStart } from "../../../redux-saga/redux/auth/authentication";

function GrandRegister({ setState, data, setOpen }) {
  const [activeIndex, setActiveIndex] = useState(null);
  const handleImageClick = (item) => {
    setActiveIndex(item);
  };
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");

  const auth = useSelector((state) => state.authentication);

  useEffect(() => {
    if (auth.signup && auth.success) {
      setOpen(false);
    }
  }, [auth.signup, auth.success, setOpen]);

  const handleSubmit = () => {
    if (userName.length === 0) {
      toast.error("Username is Required", {
        id: "username-validation-error",
      });
      return;
    }
    dispatch(
      signUpStart({
        userName,
        email: data.email,
        password: window.btoa(data.password),
        confirmPassword: window.btoa(data.confirmPassword),
        referralCode: "",
      })
    );
  };

  return (
    <div className="loginFormMainWrap grandRegisterMainWrap">
      <div className="inputWrap">
        <div className="input-login-form-wrap">
          <div className="input-container-login">
            <label htmlFor="name" className="input-label">
              Display name
            </label>
            <input
              type="name"
              id="name"
              className="custom-input"
              placeholder="Enter your in-game name"
              value={userName}
              onChange={(event) => setUserName(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="divider-container">
        <span className="divider-text">Select your game avatar</span>
      </div>
      <div className="selectGameMainWrap">
        {GAME_IMG.map((item) => {
          return (
            <div
              className={`game-item ${
                activeIndex?.id === item.id ? "active" : ""
              }`}
              onClick={() => handleImageClick(item)}
              key={item.id}
              role="presentation"
            >
              <img src={item.gameImage} alt="" />
            </div>
          );
        })}
      </div>
      <div className="selectGameLevelWrap">
        <div className="levelCardTop">
          <div className="profileCountWrap">
            <img src={activeIndex?.gameImage || "/images/Polygon.png"} alt="" />
            <span>1</span>
          </div>
          <div className="cardDetails">
            <p>{userName}</p>
            <p className="mailTxt">{data?.email}</p>
          </div>
          <div>
            {" "}
            <img src="/images/Award.png" alt="" />
          </div>
        </div>
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${0}%` }}></div>
        </div>
        <div className="lastLabelWrap">
          <p>
            <span>Current:</span> Grand I
          </p>
          <p>
            <span>Next:</span> Grand II
          </p>
        </div>
      </div>
      <div className="loginSocialBtnWrap">
        <Button
          onClick={() => setState(LOGIN_SIGNUP_STATES.RegisterConfirmation)}
        >
          Back
        </Button>
        <Button onClick={handleSubmit} className="agreeBtn">
          Register
        </Button>
      </div>
    </div>
  );
}

export default GrandRegister;
