/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  TextField,
  Button,
  FormLabel,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useTranslation } from "react-i18next";
// import { MenuItem, Select } from "@material-ui/core";
import moment from "moment";
import ReactSelect from "react-select";
//import PhoneInput from "react-phone-input-2";

import useStyles from "./KycVerification.styles";
import {
  verifyKycStart,
  resetAuthenticationState,
} from "../../../redux-saga/redux/auth/authentication";
import { useKycVerificationHook } from "./useKycVerificationHook";
import { validateName } from "../../../utils/helper";
import {
  getUserKycDetailsStart,
  resetKycDetails,
} from "../../../redux-saga/redux/kycVerification";

export default function KycVerification({ closeKycModel, openSelfieModel }) {
  const {
    countryList,
    stateList,
    cityList,
    getStatesOfCountry,
    getCitiesOfState,
  } = useKycVerificationHook();

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  // const [city, setCity] = useState("");
  const [onceChange, setOnceChange] = useState(true);
  const [frontId, setFrontId] = useState("");
  const [backId, setBackId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [streetAddress1, setStreetAddress1] = useState("");
  const [streetAddress2, setStreetAddress2] = useState("");
  const [secondAddress, setSecondAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  // const [countryPhoneCode, setCountryPhoneCode] = useState("");
  const classes = useStyles();
  const { success, loading, isNeedHouseAddress, userId } = useSelector(
    (state) => state.authentication
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dateFormat = "MM-DD-YYYY";
  const maxBirthdayDate = new Date();
  maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18, 11, 31);

  const { kycData, isUpdateKycData, loadKycData } = useSelector(
    (state) => state.kycVerification
  );

  useEffect(() => {
    // eslint-disable-next-line
    dispatch(
      getUserKycDetailsStart({ id: localStorage.getItem("signId") || userId })
    );
  }, [dispatch, userId]);

  useEffect(() => {
    if (isUpdateKycData && !loadKycData) {
      getStatesOfCountry(kycData?.country_id);
      getCitiesOfState(kycData?.state_id);

      setFirstName(kycData?.firstName);
      setFrontId(kycData?.kycDetails?.kycDocFrontImg);
      setBackId(kycData?.kycDetails?.kycDocBackImg);
      setSelectedCountry(kycData?.country_id);
      setDateOfBirth(kycData?.date_of_birth);
      setAddressNumber(kycData?.addressNumber);
      setStreetAddress1(kycData?.streetAddress1);
      setStreetAddress2(kycData?.streetAddress2);
      setSecondAddress(kycData?.secondAddress);
      setZipCode(kycData?.zipCode);
      setPhoneNumber(kycData?.phoneNumber);
      LoadFrontId({ update: true, event: kycData?.kycDetails?.kycDocFrontImg });
      LoadBackId({ update: true, event: kycData?.kycDetails?.kycDocBackImg });
      //setCountryPhoneCode(kycData?.countryPhoneCode);
      setSelectedCity(kycData?.city);
      setSelectedState(kycData?.state_id);
      setSelectedCountry(kycData?.country_id);
      const regex = /^[A-Za-z]*$/;
      if (!regex.test(kycData?.lastName)) {
        setLastName(""); // Reset to an empty string or handle it as needed
      } else {
        setLastName(kycData?.lastName);
      }
    }
  }, [kycData, isUpdateKycData, loadKycData]);
  useEffect(() => {
    if (
      stateList?.length &&
      cityList?.length &&
      isUpdateKycData &&
      !loadKycData &&
      onceChange
    ) {
      setOnceChange(false);
      setSelectedState(kycData?.state_id);
      //setSelectedCity(kycData?.city_id);
    }
  }, [stateList, cityList]);

  useEffect(() => {
    return () => {
      dispatch(resetKycDetails());
      dispatch(resetAuthenticationState());
    };
  }, []);

  useEffect(() => {
    // dispatch(getCurrenciesStart());
    if (success) {
      dispatch(resetKycDetails());
      handleOpneSelfieModel();
    }
    // eslint-disable-next-line
  }, [dispatch, success]);

  const handleOpneSelfieModel = () => {
    closeKycModel();
    openSelfieModel();
  };

  const LoadFrontId = ({ update, event }) => {
    var output = document.getElementById("output");
    if (!update) output.src = URL.createObjectURL(event.target.files[0]);
    else output.src = event;
    output.onload = function () {
      URL.revokeObjectURL(output.src);
    };
    if (!update) setFrontId(event.target.files[0]);
  };

  const LoadBackId = ({ update, event }) => {
    var output = document.getElementById("outputBack");
    if (!update) output.src = URL.createObjectURL(event.target.files[0]);
    else output.src = event;
    output.onload = function () {
      URL.revokeObjectURL(output.src);
    };
    if (!update) setBackId(event.target.files[0]);
  };
  const handleSubmitKyc = () => {
    const city = selectedCity;
    const country = countryList.filter(
      (country) => country.master_country_id === selectedCountry
    )[0];
    const state = stateList.filter(
      (state) => state.master_state_id === selectedState
    )[0]?.name;
    const usrId = parseInt(localStorage.getItem("signId"));
    const dobd =
      kycData?.date_of_birth && !dateOfBirth?.$d
        ? dateOfBirth
        : dateOfBirth?.$d;
    const dob = moment(dobd)?.format("YYYY-MM-DD");
    const toDateFormat = moment(dob).format(dateFormat);
    const validation = [];
    if (!usrId) {
      validation.push(t("HEADER.USERID_VALIDATION"));
    } else if (!firstName?.trim()) {
      validation.push(t("HEADER.FIRSTNAME_EMPTY_VALIDATION"));
    } else if (!validateName(firstName?.trim())) {
      validation.push(t("HEADER.FIRSTNAME_VALIDATION"));
    } else if (!lastName?.trim()) {
      validation.push(t("HEADER.LASTNAME_EMPTY_VALIDATION"));
    } else if (!validateName(lastName.trim())) {
      validation.push(t("HEADER.LASTNAME_VALIDATION"));
    } else if (!dateOfBirth) {
      validation.push("Required DOB");
    } else if (!moment(toDateFormat, dateFormat, true).isValid()) {
      validation.push("Please enter a valid date in the format MM-DD-YYYY.");
    } else if (!moment(dob).isBefore(moment().subtract(18, "years"))) {
      validation.push("You must be at least 18 years old to proceed.");
    } else if (!phoneNumber) {
      validation.push(t("Phone number is required"));
    } else if (!addressNumber) {
      validation.push(t("House number is required"));
    } else if (!streetAddress1) {
      validation.push(t("First street address  is required"));
    } else if (!streetAddress2) {
      validation.push(t("Second street Address is required"));
    } else if (!zipCode) {
      validation.push(t("Zip code is required"));
    } else if (!country) {
      validation.push(t("HEADER.COUNTRY_VALIDATION"));
    } else if (!state) {
      validation.push(t("HEADER.STATE_VALIDATION"));
    } else if (!city) {
      validation.push(t("HEADER.CITY_VALIDATION"));
    } else if (!frontId) {
      validation.push(t("HEADER.FRONTID_VALIDATION"));
    } else if (frontId) {
      if (frontId?.type) {
        if (
          !(
            frontId.type === "image/png" ||
            frontId.type === "image/jpeg" ||
            frontId.type === "image/jpg"
          )
        ) {
          validation.push("Please upload front id png,jpg or jpeg format");
        }
      }
    } else if (!backId) {
      validation.push(t("HEADER.BACKID_VALIDATION"));
    } else if (backId) {
      if (backId?.type) {
        if (
          !(
            backId.type === "image/png" ||
            backId.type === "image/jpeg" ||
            backId.type === "image/jpg"
          )
        ) {
          validation.push("Please upload back id in png,jpg or jpeg format");
        }
      }
    }

    if (validation?.length !== 0) {
      validation.forEach((message) => {
        toast.error(message, { id: "kycformError" });
      });
      return;
    }
    const fd = new FormData();
    if (backId?.name) fd.append("backImg", backId);
    if (frontId?.name) fd.append("frontImg", frontId);
    fd.append("id", usrId);
    fd.append("firstName", firstName?.trim());
    fd.append("dateOfBirth", dob);
    fd.append("country", country?.name);
    fd.append("city", city);
    fd.append("state", state);
    fd.append("countryCode", country?.code);
    fd.append("lastName", lastName?.trim());
    fd.append("addressNumber", addressNumber);
    fd.append("streetAddress1", streetAddress1);
    fd.append("secondAddress", secondAddress);
    fd.append("streetAddress2", streetAddress2);
    fd.append("phoneNumber", phoneNumber);
    fd.append("zipCode", zipCode);
    fd.append("countryPhoneCode", 1);
    fd.append("isHouseAddress", isNeedHouseAddress);

    dispatch(verifyKycStart(fd));
  };

  const handleCountry = (event) => {
    const { value } = event;
    const findCountry = countryList?.find((elem) => elem.name === value);
    if (findCountry) {
      getStatesOfCountry(findCountry?.master_country_id);
      setSelectedCountry(findCountry?.master_country_id);
      setSelectedCity("");
      setSelectedState("");
    }
  };

  const handleState = (event) => {
    const { value } = event;
    const findState = stateList?.find((elem) => elem.name === value);
    if (findState) {
      setSelectedCity("");
      setSelectedState(findState?.master_state_id);
    }
  };

  const handleCountryValue = (Id) => {
    const data = countryList?.find((elem) => elem.master_country_id === Id);
    if (data) {
      return {
        value: data?.name,
        label: <div className="depositMenuListItem">{data.name}</div>,
      };
    }
  };

  const handleStateValue = (Id) => {
    if (!Id) {
      return "";
    }
    const data = stateList?.find((elem) => elem.master_state_id === Id);

    if (data) {
      return {
        value: data?.name,
        label: <div className="depositMenuListItem">{data.name}</div>,
      };
    }
  };

  const { darkMode } = useSelector((state) => state.themeSettings);
  return (
    <div className="tabWrapContent kyc-form">
      <Grid className={classes.signupContent}>
        <Grid container>
          <Grid className={classes.wFull}>
            <div className={classes.userForm}>
              {/* <Box onClick={hide} className={classes.closeIconWrap}> */}
              <Box className={classes.closeIconWrap}>
                <CloseIcon
                  onClick={closeKycModel}
                  style={{ cursor: "pointer" }}
                />
              </Box>
              {darkMode ? (
                <img src="/images/modal-banner.png" alt="modal banner img" />
              ) : (
                <img
                  src="/images/light-modal-banner.png"
                  alt="modal banner img"
                />
              )}
            </div>

            {!loadKycData && (
              <Grid
                className={`${classes.signupFromWrap} ${classes.kycContent}`}
              >
                <Grid className={classes.verifyHeading}>
                  <p>Verification</p>
                </Grid>

                <Grid className={classes.formGroup}>
                  <FormLabel required className={classes.formInput}>
                    First Name
                  </FormLabel>
                  <TextField
                    autoComplete="off"
                    autoCorrect="off"
                    id="fname"
                    pattern="[A-Za-z]+"
                    placeholder="Enter your first name"
                    variant="outlined"
                    value={firstName}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^[A-Za-z]*$/; // Allow empty string as well
                      if (regex.test(value)) {
                        setFirstName(e.target.value);
                      }
                    }}
                    inputProps={{
                      maxLength: 30, // Set the maximum character limit
                    }}
                  />
                </Grid>

                <Grid className={classes.formGroup}>
                  <FormLabel required className={classes.formInput}>
                    Last Name
                  </FormLabel>
                  <TextField
                    autoComplete="off"
                    pattern="[A-Za-z]+"
                    autoCorrect="off"
                    id="lname"
                    placeholder="Enter your last name"
                    variant="outlined"
                    value={lastName}
                    //defaultValue={lastName}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^[A-Za-z]*$/; // Allow empty string as well
                      if (regex.test(value)) {
                        setLastName(value);
                      }
                    }}
                    inputProps={{
                      maxLength: 30, // Set the maximum character limit
                    }}
                  />
                </Grid>

                <Grid className={`${classes.formGroup} ${classes.calIcon}`}>
                  <FormLabel required className={classes.formInput}>
                    Birthday
                  </FormLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      id="start-date"
                      placeholder={t("Enter your Date of Birth")}
                      variant="outlined"
                      disableFuture
                      value={dateOfBirth}
                      inputMode="decimal"
                      pattern="[0-9]*"
                      inputFormat="MM-DD-YYYY"
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(date) => {
                        if (!date?.today) {
                          setDateOfBirth(date);
                        }
                      }}
                      maxDate={maxBirthdayDate}
                      yearRange={"1950:" + maxBirthdayDate.getFullYear()}
                      // maxDate={endDate ? endDate : null}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid className={classes.formGroup}>
                  <FormLabel required className={classes.formInput}>
                    Phone Number
                  </FormLabel>
                  <div className="d-flex form-outer-inp">
                    <TextField
                      autoComplete="off"
                      autoCorrect="off"
                      id="lname"
                      placeholder="Enter your Phone Number"
                      variant="outlined"
                      value={"+1"}
                      inputProps={{
                        maxLength: 20, // Set the maximum character limit
                      }}
                      className="phone_inp1"
                    />
                    <TextField
                      autoComplete="off"
                      autoCorrect="off"
                      type="number"
                      inputMode="decimal"
                      pattern="[0-9]*"
                      id="lname"
                      placeholder="Enter your Phone Number"
                      variant="outlined"
                      value={phoneNumber}
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[0-9]*$/; // Allow empty string as well
                        if (regex.test(value)) {
                          setPhoneNumber(value);
                        }
                      }}
                      inputProps={{
                        maxLength: 20, // Set the maximum character limit
                      }}
                      className="phone_inp2"
                    />
                  </div>
                </Grid>
                <Grid className={classes.formGroup}>
                  <FormLabel required className={classes.formInput}>
                    House Number
                  </FormLabel>
                  <TextField
                    autoComplete="off"
                    autoCorrect="off"
                    type="number"
                    inputmode="decimal"
                    pattern="[0-9]*"
                    id="email"
                    placeholder="Enter your House Number"
                    variant="outlined"
                    name="addressNumber"
                    value={addressNumber}
                    onChange={(e) => setAddressNumber(e?.target?.value)}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </Grid>
                <Grid className={classes.formGroup}>
                  <FormLabel required className={classes.formInput}>
                    Street Address 1
                  </FormLabel>
                  <TextField
                    autoComplete="off"
                    autoCorrect="off"
                    id="email"
                    placeholder="Enter your First Street Address"
                    variant="outlined"
                    name="streetAddress-1"
                    value={streetAddress1}
                    onChange={(e) => setStreetAddress1(e?.target?.value)}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </Grid>
                <Grid className={classes.formGroup}>
                  <FormLabel required className={classes.formInput}>
                    Street Address 2
                  </FormLabel>
                  <TextField
                    autoComplete="off"
                    autoCorrect="off"
                    id="email"
                    placeholder="Enter your Second Street Address"
                    variant="outlined"
                    name="streetAddress"
                    value={streetAddress2}
                    onChange={(e) => setStreetAddress2(e?.target?.value)}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </Grid>
                <Grid className={classes.formGroup}>
                  <FormLabel /* required */ className={classes.formInput}>
                    Second Address(Optional)
                  </FormLabel>
                  <TextField
                    autoComplete="off"
                    autoCorrect="off"
                    id="email"
                    placeholder="Enter your second address"
                    variant="outlined"
                    name="secondAddress"
                    value={secondAddress}
                    onChange={(e) => setSecondAddress(e?.target?.value)}
                    inputProps={{
                      maxLength: 30,
                    }}
                  />
                </Grid>
                <Grid className={`${classes.formGroup} languageButton`}>
                  <FormLabel required className={classes.formInput}>
                    Country
                  </FormLabel>
                  <ReactSelect
                    className={`${classes.depositInput} basic-single`}
                    classNamePrefix="select"
                    value={handleCountryValue(selectedCountry)}
                    onChange={handleCountry}
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    name="color"
                    placeholder="Select your Country"
                    options={countryList.map((option) => ({
                      value: option?.name,
                      label: (
                        <div className="depositMenuListItem">{option.name}</div>
                      ),
                    }))}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        borderRadius: "8px",
                        marginTop: "-1px",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        borderRadius: "8px", // Set the radius for the items
                        background: state.isSelected
                          ? "#24242b"
                          : "transparent",
                        color: state.isSelected ? "#fff" : "#fff",
                        "&:hover": {
                          backgroundColor: "#24242b",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: darkMode ? "#fff" : "#24242b",
                        borderRadius: "8px",
                      }),
                      menuList: (s) => ({
                        ...s,
                        background: "#24242b",
                        borderRadius: "12px",
                        fontSize: "12px",
                        border: "1.4px solid rgba(255, 255, 255, 0.04)",
                        boxShadow:
                          "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: "8px",
                      colors: {
                        ...theme.colors,
                        neutral0: darkMode ? "#2a2a2f" : "#f7df94",
                        primary25: "rgba(123, 98, 218, 0.16)",
                        neutral80: "#fff",
                      },
                    })}
                  />
                </Grid>
                <Grid className={`${classes.formGroup} languageButton`}>
                  <FormLabel required className={classes.formInput}>
                    State
                  </FormLabel>
                  <ReactSelect
                    className={`${classes.depositInput} basic-single`}
                    classNamePrefix="select"
                    value={handleStateValue(selectedState)}
                    onChange={handleState}
                    closeMenuOnSelect={true}
                    isSearchable={true}
                    name="color"
                    placeholder="Select your State"
                    options={stateList.map((option) => ({
                      value: option?.name,
                      label: (
                        <div className="depositMenuListItem">{option.name}</div>
                      ),
                    }))}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        borderRadius: "8px",
                        marginTop: "-1px",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        borderRadius: "8px", // Set the radius for the items
                        background: state.isSelected
                          ? "#24242b"
                          : "transparent",
                        color: state.isSelected ? "#fff" : "#fff",
                        "&:hover": {
                          backgroundColor: "#24242b",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: darkMode ? "#fff" : "#24242b",
                        borderRadius: "8px",
                      }),
                      menuList: (s) => ({
                        ...s,
                        background: "#24242b",
                        borderRadius: "12px",
                        fontSize: "12px",
                        border: "1.4px solid rgba(255, 255, 255, 0.04)",
                        boxShadow:
                          "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: "8px",
                      colors: {
                        ...theme.colors,
                        neutral0: darkMode ? "#2a2a2f" : "#f7df94",
                        primary25: "rgba(123, 98, 218, 0.16)",
                        neutral80: "#fff",
                      },
                    })}
                  />
                </Grid>
                <Grid className={classes.formGroup}>
                  <FormLabel required className={classes.formInput}>
                    City
                  </FormLabel>
                  <TextField
                    autoComplete="off"
                    autoCorrect="off"
                    id="City"
                    placeholder="Enter your city"
                    variant="outlined"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e?.target?.value)}
                  />
                </Grid>
                <Grid className={classes.formGroup}>
                  <FormLabel required className={classes.formInput}>
                    Zip Code
                  </FormLabel>
                  <TextField
                    type="number"
                    autoComplete="off"
                    autoCorrect="off"
                    id="zipCode"
                    placeholder="Enter your Zip Code"
                    variant="outlined"
                    value={zipCode}
                    name="zipCode"
                    onChange={(e) => setZipCode(e?.target?.value)}
                  />
                </Grid>

                <Grid className={`${classes.formGroup} ${classes.fileUpload}`}>
                  <FormLabel required className={classes.formInput}>
                    Front of ID
                  </FormLabel>

                  <Button
                    variant="contained"
                    component="label"
                    className={classes.fileUploadBtn}
                  >
                    <input
                      type="file"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => LoadFrontId({ update: false, event: e })}
                    />
                    <img
                      className="frondface"
                      src="/images/upload-icon.svg"
                      alt=""
                      id="output"
                    />
                  </Button>
                </Grid>

                <Grid className={`${classes.formGroup} ${classes.fileUpload}`}>
                  <FormLabel required className={classes.formInput}>
                    Back of ID
                  </FormLabel>

                  <Button
                    variant="contained"
                    component="label"
                    className={classes.fileUploadBtn}
                  >
                    <input
                      type="file"
                      accept=".png,.jpg,.jpeg"
                      onChange={(e) => LoadBackId({ update: false, event: e })}
                    />
                    <img src="/images/upload-icon.svg" alt="" id="outputBack" />
                  </Button>
                </Grid>

                <Grid className={classes.authBtnWrap}>
                  <Button
                    className={classes.btnPrimary}
                    onClick={handleSubmitKyc}
                  >
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Verify"
                    )}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
