import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Tab, Tabs, Box, DialogContent } from "@mui/material";
// import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import ReactSelect from "react-select";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

import { getUsersAllTransactionHistStart } from "../../redux-saga/redux/transaction";
import useStyles from "../../container/Casino/Lobby/Lobby.styles";
import TransactionPage from "./table";
import Modal from "../../hoc/modal/Modal";
import ExitPopup from "../pop-ups/ExitPopup";
import ViewCommentsPopup from "../pop-ups/view_comments";
import transactionsService from "../../services/transactions";
const TransactionTable = () => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageLimit = 10;

  const { transactionHistoryCount, userTransactionHistory, loading } =
    useSelector((state) => state.transactions);
  const { darkMode } = useSelector((state) => state.themeSettings);
  const [pageCount, setPageCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({
    paymentType: "all",
    period: "Today",
  });
  const [openExitGame, setOpenExitGame] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [cancelPayload, setCancelPayload] = useState({
    transactionType: "",
    transactionId: "",
  });
  const [openViewComment, setOpenViewComment] = useState(false);
  const [commentMsg, setCommentMsg] = useState("");
  useEffect(() => {
    setPageCount(Math.ceil(transactionHistoryCount / pageLimit));
  }, [transactionHistoryCount]);
  useEffect(() => {
    dispatch(
      getUsersAllTransactionHistStart({
        offset: skip,
        limit: pageLimit,
        ...filterData,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, filterData]);
  const handleChange = (event, newValue) => {
    setFilterData({ ...filterData, period: event?.target?.textContent });
    setValue(newValue);
  };
  const handleCoinSelect = (e) => {
    setFilterData({ ...filterData, paymentType: e?.value });
  };

  const handlePageClick = ({ selected }) => {
    setSkip(selected * pageLimit);
    setCurrentPage(selected);
  };

  const handleCanceTransaction = (transaction) => {
    console.log("Transaction>>>>", transaction);
    if (!transaction?.transaction_id) {
      return toast.error("Oops! You can't cancel this transaction", {
        id: "messgaeLength",
      });
    }
    setOpenExitGame(true);
    setCountdown(10);
    setCancelPayload({
      ...cancelPayload,
      transactionType: transaction?.payment,
      transactionId: transaction?.transaction_id,
    });
  };
  const handleDo = async () => {
    if (!cancelPayload?.transactionId) {
      return toast.error("Oops! You can't cancel this transaction", {
        id: "messgaeLength",
      });
    }
    const res = await transactionsService.cancelUserTransaction(cancelPayload);
    const {
      data: { updated },
    } = res || {};
    if (updated) {
      toast?.success("Transaction cancelled successfully", {
        toastId: "Asdasdasd",
      });
      setOpenExitGame(false);
      dispatch(
        getUsersAllTransactionHistStart({
          offset: skip,
          limit: pageLimit,
          ...filterData,
        })
      );
    }
  };

  const handleViewComments = (comment) => {
    if (comment) {
      setCommentMsg(comment);
      setOpenViewComment(!openViewComment);
    }
    console.log("handleView Comment >>>>");
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Helmet>
        <body className="transaction-remove-padding" />
      </Helmet>

      <section>
        <Grid
          className={`${classes.transLobbyPage} transactionPageBannerSection`}
        >
          <div className="contentSec">
            {/* back button  */}
            <div className="backBtnDiv" onClick={goBack} role="presentation">
              <div className="backBtn">
                <img src="/images/back-icon.svg" alt="back-icon" />
                <p>Back</p>
              </div>
            </div>

            <h1>TRANSACTION HISTORY</h1>
          </div>
        </Grid>
      </section>
      <section className="RoutesWraper">
        <Grid className={classes.latestBetSectionTarnsection}>
          <Grid className={" transactionThemeCard"}>
            <div className="transDivFlex">
              <Box className="transactionSelectBox">
                <div>
                  <p className="transactionHeading transactionPaymentType">
                    Payment Type
                  </p>
                  <ReactSelect
                    className={`${classes.depositInput} basic-single transactionSelectBox`}
                    onChange={handleCoinSelect}
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    isSearchable={false}
                    name="color"
                    defaultValue={{ label: "All", value: "all" }}
                    options={[
                      { label: "All", value: "all" },
                      { label: "Deposit", value: "3" },
                      { label: "Redeem", value: "4" },
                    ]}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        borderRadius: "8px",
                        marginTop: "-1px",
                        overflow: "auto",
                        zIndex: "9999",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        borderRadius: "8px",
                        overflow: "auto",
                        zIndex: "9999",
                        background: state.isSelected
                          ? "#24242b"
                          : "transparent",
                        color: state.isSelected ? "#fff" : "#fff",
                        "&:hover": {
                          backgroundColor: "#24242b",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: darkMode ? "#fff" : "#24242b",
                        borderRadius: "8px",
                      }),
                      menuList: (s) => ({
                        ...s,
                        background: "#24242b",
                        borderRadius: "12px",
                        fontSize: "12px",
                        border: "1.4px solid rgba(255, 255, 255, 0.04)",
                        boxShadow:
                          "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: "8px",
                      colors: {
                        ...theme.colors,
                        neutral0: darkMode ? "#2a2a2f" : "#f7df94",
                        primary25: "rgba(123, 98, 218, 0.16)",
                        neutral80: "#fff",
                      },
                    })}
                  />
                </div>
              </Box>
              <Box
                className={`${classes.tabSectionTransaction} tabTransitionCustom`}
              >
                <p className="transactionHeading">Period</p>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  style={{ width: "100%" }}
                  className="Tabsection"
                >
                  <Tab label="Today" />
                  <Tab label="Yesterday" />
                  <Tab label="7 Days" />
                  <Tab label="One Month" />
                </Tabs>
              </Box>
            </div>

            {loading ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                <div className="load" />
              </Box>
            ) : (
              <Grid className={classes.betTableWrap}>
                <TransactionPage
                  data={userTransactionHistory}
                  currentPage={currentPage}
                  pageLimit={pageLimit}
                  handleCanceTransaction={handleCanceTransaction}
                  handleViewComments={handleViewComments}
                />
              </Grid>
            )}
            <Modal
              isShowing={openExitGame}
              content={
                <DialogContent className={classes.loginWrap}>
                  <ExitPopup
                    setOpenExitGame={setOpenExitGame}
                    countdown={countdown}
                    setCountdown={setCountdown}
                    text="Are you sure you want to cancel this redeem request?"
                    handleDo={handleDo}
                    from="transaction"
                  />
                </DialogContent>
              }
              hide={() => setOpenExitGame(false)}
            />
            <Modal
              isShowing={openViewComment}
              content={
                <DialogContent className={classes.loginWrap}>
                  <ViewCommentsPopup
                    setOpenViewComment={setOpenViewComment}
                    commentMsg={commentMsg}
                  />
                </DialogContent>
              }
              hide={() => setOpenExitGame(false)}
            />

            {Number(transactionHistoryCount || 0) > 10 ? (
              <div className="refferal-pagination  transaction-pagination">
                <ReactPaginate
                  previousLabel={<PreArrow />}
                  breakLabel="..."
                  nextLabel={<NextArrow />}
                  pageCount={pageCount}
                  // marginPagesDisplayed={-2}
                  // pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  forcePage={currentPage}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous-page"}
                  nextLinkClassName={"next-page"}
                  disabledClassName={"pagination-disabled"}
                  activeClassName={"pagination-active"}
                />
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default TransactionTable;

const PreArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 33 33"
      fill="none"
    >
      <g filter="url(#filter0_i_86_2806)">
        <rect width="33" height="33" rx="8" fill="#2B2B38" />
      </g>
      <rect
        x="0.7"
        y="0.7"
        width="31.6"
        height="31.6"
        rx="7.3"
        stroke="white"
        strokeOpacity="0.04"
        strokeWidth="1.4"
      />
      <path
        d="M17 13L13 16.5L17 20"
        stroke="#666773"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_i_86_2806"
          x="0"
          y="0"
          width="33"
          height="35"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_86_2806"
          />
        </filter>
      </defs>
    </svg>
  );
};

const NextArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 33 33"
      fill="none"
    >
      <g filter="url(#filter0_i_86_2809)">
        <rect
          x="33"
          y="33"
          width="33"
          height="33"
          rx="8"
          transform="rotate(-180 33 33)"
          fill="#2B2B38"
        />
      </g>
      <rect
        x="32.3"
        y="32.3"
        width="31.6"
        height="31.6"
        rx="7.3"
        transform="rotate(-180 32.3 32.3)"
        stroke="white"
        strokeOpacity="0.04"
        strokeWidth="1.4"
      />
      <path
        d="M16 20L20 16.5L16 13"
        stroke="#666773"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_i_86_2809"
          x="0"
          y="0"
          width="33"
          height="35"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_86_2809"
          />
        </filter>
      </defs>
    </svg>
  );
};
