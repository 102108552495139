import React from "react";

import { Box } from "@mui/material";

import GrandRegister from "./grandRegister";
import TermsAndCondition from "./termsAndCondition";
import { LOGIN_SIGNUP_STATES } from "./constants";

import "./login.css";

function AgreeData({ state, setState, setOpen, data }) {
  const isView = state === LOGIN_SIGNUP_STATES.RegisterConfirmation;
  return (
    <div className="agreeDataMainWrap">
      <div className="loginHeadeWrap agreeHeader">
        <h4>{isView ? "Before you continue to FortunaRush" : "Register"}</h4>
        <Box onClick={() => setOpen(false)} className="closeIcon">
          <img src="/images/loginCross.png" alt="" />
        </Box>
      </div>
      {isView ? (
        <TermsAndCondition setState={setState} />
      ) : (
        <GrandRegister data={data} setState={setState} setOpen={setOpen} />
      )}
    </div>
  );
}

export default AgreeData;
