import { createSlice } from "@reduxjs/toolkit";

export const myProfInitialState = {
  loading: false,
  imageUpdateLoading: false,
  success: false,
  error: null,
  userDetails: {},
  walletAmount: "",
  walletLoading: false,
  userGameHistory: {},
  socketInstance: null,
  referralsDetail: [],
  depositLimit: 0,
};

const myProfileSlice = createSlice({
  name: "myProfile",
  initialState: myProfInitialState,
  reducers: {
    getUserGameHistoryStart: (state, action) => {
      state.loading = true;
    },
    getUserGameHistoryError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    getUserGameHistorySuccess: (state, action) => {
      state.userGameHistory = action.payload;
      state.loading = false;
    },
    getUserProfileDetailsStart: (state, action) => {
      state.loading = true;
    },
    getUserProfileDetailsSuccess: (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
    },
    getUserProfileDetailsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateUserProfileStart: (state, action) => {
      state.loading = true;
    },
    updateUserProfileSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
      state.userDetails = action.payload;
    },
    updateUserProfileFailure: (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
    },
    getWalletBalanceSuccess: (state, action) => {
      state.walletLoading = false;
      state.walletAmount = action.payload;
    },
    startPoll: () => {},
    resetMyProfileState: (state) => {
      state.userDetails = myProfInitialState.userDetails;
      state.walletAmount = myProfInitialState.walletAmount;
      state.userGameHistory = myProfInitialState.userGameHistory;
    },
    setSocketBalanceInstance: (state, action) => {
      state.socketInstance = action.payload;
    },
    updateProfileImageStart: (state) => {
      state.imageUpdateLoading = true;
      state.success = false;
    },
    updateProfileImageSuccess: (state, action) => {
      state.userDetails.user.profile_image = action.payload.profileImage;
      state.imageUpdateLoading = false;
      state.success = true;
    },
    updateProfileImageFailure: (state, action) => {
      state.imageUpdateLoading = false;
      state.error = action.payload;
    },
    getReferralDetailsStart: (state, action) => {
      state.loading = true;
    },
    getReferralDetailsSuccess: (state, action) => {
      state.loading = false;
      state.referralsDetail = action.payload.allReferrals || [];
      state.depositLimit = action.payload.depositLimit || 0;
    },
    getReferralDetailsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    BonusReceiveStart: (state, action) => {
      state.loading = true;
    },
    BonusReceiveSuccess: (state, action) => {
      state.loading = false;
      state.referralsDetail = action.payload.allReferrals || [];
      state.depositLimit = action.payload.depositLimit || 0;
    },
    BonusReceiveFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setSocketBalanceInstance,
  getUserProfileDetailsStart,
  getUserProfileDetailsSuccess,
  getUserProfileDetailsFailure,
  updateUserProfileStart,
  updateUserProfileSuccess,
  updateUserProfileFailure,
  getWalletBalanceSuccess,
  startPoll,
  resetMyProfileState,
  getUserGameHistoryStart,
  getUserGameHistoryError,
  getUserGameHistorySuccess,
  updateProfileImageStart,
  updateProfileImageSuccess,
  updateProfileImageFailure,
  getReferralDetailsStart,
  getReferralDetailsSuccess,
  getReferralDetailsFailure,
  BonusReceiveStart,
  BonusReceiveSuccess,
  BonusReceiveFailure,
} = myProfileSlice.actions;

export default myProfileSlice.reducer;
