/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import {
  ListItem,
  Grid,
  List,
  Input,
  IconButton,
  Dialog,
  DialogContent,
  Select,
  MenuItem,
} from "@mui/material";
import i18next from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { MentionsInput, Mention } from "react-mentions";
import EmojiPicker from "emoji-picker-react";
import moment from "moment";
import ClearIcon from "@material-ui/icons/Clear";
// import { Link } from "@mui/material";
import { isMobile } from "react-device-detect";
import ReactGiphySearchbox from "react-giphy-searchbox";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";

import session from "../../utils/session";
import useStyles from "./ChatBox.styles";
//import { toggleChatBox } from "../../redux-saga/redux/settings/themeSettings";
import {
  getUnseenCountOfSupportChatStart,
  getUnseenCountOfSupportChatSuccess,
  getUserListStart,
  //updateSupportMsgUnseenCountStart,
  updateUnseenCountStart,
} from "../../redux-saga/redux/chatbox";
import {
  getHighlightedMention,
  replaceMentionWithValue,
} from "../../utils/helper";
import { useSendMessage } from "../../services/webSocket/sendMessage";
import { FilterHacked } from "../../utils/filterBadWords";
import ChatRules from "./chatRules";
import Modal from "../../hoc/modal/Modal";
import SupportChat from "./SupportChat";
import { socket } from "../../services/webSocket/supportChatSocket";

const ChatBox = () => {
  let customFilter = new FilterHacked();
  customFilter.removeWords("hells", "sadist", "hell");
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showPickerr, setShowPickerr] = useState(false);
  const [previousChats, setPreviousChats] = useState([]);
  const [userId, setUserId] = useState("");
  const [sendMessage, sendMessageLoading] = useSendMessage();
  const [activeUsers, setActiveUsers] = useState([]);
  const [unseenCount, setUnseenCount] = useState(0);
  const messagesEndRef = React.createRef();
  const submitRef = React.createRef();
  const inputRef = useRef();
  const [message, setMessage] = useState("");
  const [messageTyp, setMessageTyp] = useState("text");
  const [file, setFile] = useState(null);
  const [showGIPFY, setShowGIPFY] = useState(false);
  const [chatRule, setChatRule] = useState(false);
  const [preview, setPreview] = useState(null);
  const [selectedChatType, setSelectedChatType] = useState("support");
  const [disableButton, setDisableButton] = useState(false);
  const [launcherOpacity, setLauncherOpacity] = useState(false);
  const {
    userList,
    chatList,
    newMessageByWS,
    unseenMsg,
    activeUsersCount,
    supportChatUnseenCount,
  } = useSelector((state) => state.chatbox);
  const { darkMode, isChatBoxOpen } = useSelector(
    (state) => state.themeSettings
  );
  // const { isLoggedIn } = useSelector((state) => state.authentication);

  const handleOnKeyDownInput = (event) => {
    setDisableButton(true);
    if (event.key === "Enter") {
      event?.preventDefault();
      submitRef?.current?.click();
    }
  };
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };
  useEffect(() => {
    if (isChatBoxOpen && unseenCount > 0) {
      dispatch(updateUnseenCountStart());
    }
  }, [isChatBoxOpen, unseenCount, dispatch]);
  useEffect(() => {
    const newMentionCount = unseenMsg;
    if (newMentionCount && isChatBoxOpen) {
      dispatch(updateUnseenCountStart());
    } else if (newMentionCount) setUnseenCount(newMentionCount);
  }, [unseenMsg, isChatBoxOpen, dispatch]);

  useEffect(() => {
    if (previousChats?.length) {
      scrollToBottom();
    }
  }, [previousChats?.length, previousChats, isChatBoxOpen]);

  const fetchUserListUpdated = (search) => {
    dispatch(getUserListStart({ search }));
    return activeUsers;
  };

  useEffect(() => {
    fetchUserListUpdated("");
    dispatch(getUnseenCountOfSupportChatStart());
  }, []);

  useEffect(() => {
    setActiveUsers(
      userList?.map((state) => ({
        id: state?.id,
        display: `${state?.user_name}`,
      }))
    );
  }, [userList]);

  useEffect(() => {
    setUnseenCount(unseenMsg);
  }, [unseenMsg]);

  useEffect(() => {
    const user = session?.getSessionCred;
    setUserId(user?.userId);
  }, []);

  useEffect(() => {
    if (Object?.keys(newMessageByWS)?.length) {
      setPreviousChats((prev) => [...prev, newMessageByWS]);
    }
  }, [newMessageByWS]);
  const handleChatRules = () => {
    setChatRule((s) => !s);
  };

  useEffect(() => {
    setPreviousChats(chatList);
  }, [chatList]);

  const handleGIPHYSelect = async (url) => {
    setMessageTyp("file");
    setDisableButton(false);
    const response = await fetch(url?.images?.fixed_height_downsampled?.url);
    setPreview(url?.images?.fixed_height_downsampled?.url);
    const blob = await response?.blob();
    setFile(blob);
    setShowGIPFY(false);
  };

  const onEmojiClick = (event, emojiObject) => {
    setDisableButton(false);
    setMessage((prevInput) => prevInput + event?.emoji);
    setShowPickerr(false);
    inputRef?.current?.focus();
  };
  const handleChatBox = () => {
    sessionStorage.setItem("ZD-widgetOpen", false);
    sessionStorage.setItem("ZD-sendApiBlips", false);
    zE("messenger:set", "locale", "en");
    zE("messenger", "show");
    zE("messenger", "open");
    setLauncherOpacity(true);
  };

  useEffect(() => {
    const handleClose = () => {
      zE("messenger", "hide");
      setLauncherOpacity(false);
    };

    // Initialize the messenger widget
    zE("messenger", "hide");

    // Set up the event listener for closing the widget
    zE("messenger:on", "close", handleClose);

    // Clean up event listener on component unmount
    return () => {
      zE("messenger:on", "close", () => {});
    };
  }, []);
  useEffect(() => {
    if (launcherOpacity) {
      const intervalId = setInterval(() => {
        const iframe = window.document.querySelector(
          "iframe[title='Messaging window']"
        );

        if (iframe) {
          iframe.style.borderRadius = "0";
          const iframeWindow = iframe.contentWindow;
          const iframeDocument =
            iframe.contentDocument || iframeWindow.document;
          const iframeBody1 =
            iframeDocument.querySelector(".xMqsn") ||
            iframeDocument.querySelector(".eJNOcI");
          iframeBody1?.remove();
          if (!iframeBody1) {
            return;
          }
          iframeBody1.style.opacity = "0";
          iframeBody1.style.visibility = "hidden";
          iframeBody1.style.display = "none";
          // iframe.style.visibility = "visible";
          clearInterval(intervalId);
        }
      }, 100);
    }
  }, [launcherOpacity]);

  console.log(launcherOpacity);
  const handleSendMessage = async () => {
    setDisableButton(true);
    if (messageTyp === "text") {
      const trimmedMsg = message.trim();
      if (trimmedMsg?.length) {
        try {
          await sendMessage({
            message: trimmedMsg,
            messageType: messageTyp,
            language: i18next.language || "en",
            userId,
          });
          setMessage("");
        } catch (error) {
          console.log("error  while sending message ", error);
        }
      }
    } else if (messageTyp === "file") {
      if (file) {
        try {
          await sendMessage({
            message: file,
            messageType: messageTyp,
            language: i18next?.language || "en",
            userId,
          });
          setFile("");
          setPreview(null);
        } catch (error) {
          console.log("error  while sending message ", error);
        }
      }
    }
  };

  const handleFileChange = (event) => {
    setMessageTyp("file");
    setDisableButton(false);
    const selectedFile = event?.target?.files[0];
    setFile(selectedFile);
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setPreview(null);
    }
  };

  // const handleButtonClick = () => {
  //   setShowGIPFY(true);
  // };

  const handleClearClick = () => {
    setMessageTyp("text");
    setFile(null);
    setPreview(null);
  };

  const modifyMessage = (message) => {
    const msg = replaceMentionWithValue(
      message || "We are winning the lottery but from two different sides!"
    );
    return getHighlightedMention(msg);
  };
  const handleChange = (e) => {
    setSelectedChatType(e?.target?.value);
  };

  useEffect(() => {
    const user = session?.getSessionCred;
    socket.on("supportChatUnseenMsgCount", (data) => {
      if (Number(data?.receiver) === Number(user?.userId)) {
        dispatch(getUnseenCountOfSupportChatSuccess(data));
      }
    });
    return () => {
      socket.off("supportChatUnseenMsgCount");
    };
  }, []);

  useEffect(() => {
    const body = document.body;
    if (isChatBoxOpen && isMobile) {
      body.style.position = "fixed";
      body.style.width = "100%";
      body.style.overflow = "hidden";
    } else {
      body.style.position = "";
      body.style.width = "";
      body.style.overflow = "";
    }
  }, [isChatBoxOpen, isMobile]);

  return !isMobile ? (
    isChatBoxOpen ? (
      <section className={classes.chatBoxWrapper}>
        <Grid
          className={`${classes.chatBoxHeading} chatboxSelect`}
          display="flex"
          alignItems={"center"}
        >
          <Link onClick={handleChatBox}>
            <img
              src="/images/toggle-icon.svg"
              alt="toggle icon"
              className={classes.sidebarToggleIconClose}
            />
          </Link>
          <Select
            className={`${classes.chatHeading} chatboxSelectContent`}
            display="flex"
            autoWidth="true"
            IconComponent={() => (
              <img src="/images/arrow-d.svg" alt="arrow down" />
            )}
            value={selectedChatType}
            onChange={handleChange}
          >
            {/* <MenuItem value="global">Global Chat</MenuItem> */}
            <MenuItem value="support">Support Chat</MenuItem>
            {/* Add other options as needed */}
          </Select>
          <Grid
            className={classes.userNumber}
            display="flex"
            alignItems={"center"}
          >
            <b>{activeUsersCount || 0}</b>
            {darkMode ? (
              <>
                <span>
                  <img
                    className={classes.chatIcon}
                    src="/images/chat-icon.svg"
                    alt="arrow down"
                  />
                </span>

                {isMobile && (
                  <Link onClick={handleChatBox}>
                    <img
                      src="/images/mob-chat-close.svg"
                      alt="mob-chat-close"
                    />
                  </Link>
                )}
              </>
            ) : (
              <>
                <span>
                  <img src="/images/arrow-square-left.svg" alt="arrow down" />
                </span>

                {isMobile && (
                  <Link onClick={handleChatBox}>
                    <img
                      src="/images/mob-chat-close.svg"
                      alt="mob-chat-close"
                    />
                  </Link>
                )}
              </>
            )}
          </Grid>
        </Grid>
        {selectedChatType === "support" ? (
          <SupportChat selectedChatType={selectedChatType} />
        ) : (
          <>
            <div className={classes.scrollCustom}>
              <Grid className={classes.chatMessageGroup}>
                {previousChats?.map((user, index) => (
                  <Grid
                    key={`${user?.userId}${user?.messageData?.message}${index}`}
                    className={
                      user.userId === userId
                        ? classes.chatMessage
                        : classes.chatMessageMine
                    }
                  >
                    <Grid className={classes.profileImage}>
                      <span className={classes.userBorder}>
                        <img
                          src={
                            user.profileImage || "/images/casino-banner-2.png"
                          }
                          alt="player-icon"
                        />
                      </span>

                      <span className={classes.userProfileLevel}>
                        {user?.userLevelId}
                      </span>
                    </Grid>
                    <Grid className={classes.message}>
                      <p className={classes.userNameTime}>
                        <span>{user?.userName || "Ngin"}</span>
                        <span>
                          {moment(
                            user?.dateTime || "2023-04-13T07:38:19.125Z"
                          ).format(" h:mm A") || "1:12PM"}
                        </span>
                      </p>
                      {user?.messageData?.messageType === "text" && (
                        <p className={classes.userParagraph}>
                          {modifyMessage(user?.messageData?.message)}
                        </p>
                      )}
                      {user?.messageData?.messageType === "file" && (
                        <img
                          style={{ maxWidth: "100px", maxHieght: "100px" }}
                          src={
                            user?.messageData?.message || "/images/bunny.avif"
                          }
                          alt="gif"
                        ></img>
                      )}
                    </Grid>
                  </Grid>
                ))}
                {previousChats?.length === 0 && (
                  <Grid className={classes.Nochats}>No chats Found 🥲</Grid>
                )}
              </Grid>
              <div ref={messagesEndRef} />
            </div>
            <Grid key="chatbox" className={classes.chatBoxFooter}>
              <Grid key="chatbox2" className={classes.footerInput}>
                <Dialog
                  open={showGIPFY}
                  onClose={() => setShowGIPFY(false)}
                  className={classes.dropShadow}
                >
                  <ReactGiphySearchbox
                    apiKey={process.env.REACT_APP_GIFY_API_KEY}
                    onSelect={(item) => handleGIPHYSelect(item)}
                    masonryConfig={[
                      { columns: 2, imageWidth: 110, gutter: 5 },
                      { mq: "700px", columns: 3, imageWidth: 120, gutter: 5 },
                    ]}
                  />
                </Dialog>
                <Dialog
                  open={showPickerr}
                  onClose={() => setShowPickerr(false)}
                  className={classes.dropShadow}
                >
                  <EmojiPicker
                    skinTonesDisabled
                    height={"260px"}
                    width={"298px"}
                    theme="light"
                    className={classes.picker}
                    disableAutoFocus={true}
                    previewConfig={{
                      showPreview: false,
                      showSuggested: false,
                      showFrequent: false,
                    }}
                    onEmojiClick={onEmojiClick}
                  />
                </Dialog>
                {!preview && (
                  <MentionsInput
                    className="comments-textarea"
                    placeholder="Type your message"
                    value={message}
                    onChange={(e) => {
                      setMessageTyp("text");
                      if (e?.target?.value && e?.target?.value?.length > 100) {
                        toast.error(
                          "Oops! Seems like your message is too long ",
                          { id: "messgaeLength" }
                        );
                        return;
                      }
                      if (e?.target?.value) {
                        setMessage(customFilter?.cleanHacked(e?.target?.value));
                      } else setMessage("");
                      inputRef?.current?.focus();
                    }}
                    a11ySuggestionsListLabel={"Suggested mentions"}
                    onKeyDown={handleOnKeyDownInput}
                    key="message"
                    inputRef={inputRef}
                  >
                    <Mention
                      trigger="@"
                      style={{
                        backgroundColor: "red",
                      }}
                      data={fetchUserListUpdated}
                      displayTransform={(_id, display) => {
                        return `@${display} `;
                      }}
                    />
                  </MentionsInput>
                )}
                {preview && (
                  <div className={classes.previewContainer}>
                    <IconButton
                      className={classes.closeButton}
                      onClick={handleClearClick}
                    >
                      <ClearIcon />
                    </IconButton>
                    {preview ? (
                      <img
                        src={preview}
                        alt="Preview"
                        className={classes.previewImage}
                      />
                    ) : (
                      <p>Unsupported file type</p>
                    )}
                  </div>
                )}
                <Grid className={classes.searchIcons}>
                  {!preview && (
                    <Link
                      onClick={() => {
                        setShowPickerr(true);
                      }}
                    >
                      <img src="/images/wpf_attach.png" alt="emoji" />
                    </Link>
                  )}
                  <span>
                    {sendMessageLoading ? (
                      <Link
                        disabled
                        ref={submitRef}
                        onClick={handleSendMessage}
                      >
                        <img
                          className={classes.disbled}
                          src="/images/load.gif"
                          alt="sending"
                        />
                      </Link>
                    ) : (
                      <Link ref={submitRef} onClick={handleSendMessage}>
                        <img src="/images/sent-icon.svg" alt="sent" />
                      </Link>
                    )}
                  </span>
                </Grid>
              </Grid>
              <div className={classes.root}>
                <Input
                  id="file-input"
                  type="file"
                  onChange={handleFileChange}
                  className={classes.input}
                />
              </div>
            </Grid>
          </>
        )}

        <Modal
          isShowing={chatRule}
          className={classes.dialogcontainer}
          content={
            <DialogContent>
              <ChatRules handleChatRules={handleChatRules} />
            </DialogContent>
          }
          hide={handleChatRules}
        />
      </section>
    ) : (
      <>
        {!isMobile && (
          <div role="button">
            <section
              className={classes.chatBoxCloseBotton}
              onClick={handleChatBox}
              role="presentation"
            >
              {supportChatUnseenCount > 0 && (
                <div className={classes.chatCount}>
                  <span>{supportChatUnseenCount}</span>
                </div>
              )}
              <List>
                <ListItem className={classes.chatToggle}>
                  <img src="/images/toggle-icon.svg" alt="toggle icon" />
                </ListItem>
              </List>
            </section>
            <section
              className={classes.chatSupportBox}
              onClick={() => handleChatBox()}
              role="presentation"
            >
              {/* <Link to="http://m.me/CryptoRollsGamingClub" target="_blank"> */}
              <img src="/images/Facebook-Messenger.svg" alt="facebook" />
              {/* </Link> */}
            </section>
          </div>
        )}
      </>
    )
  ) : (
    isChatBoxOpen && (
      <Grid className={classes.mobChatPannel}>
        <section className={classes.chatBoxWrapper}>
          <Grid
            className={`${classes.chatBoxHeading} chatboxSelect`}
            display="flex"
            alignItems={"center"}
          >
            <Select
              className={`${classes.chatHeading} chatboxSelectContent`}
              display="flex"
              autoWidth="true"
              IconComponent={() => (
                <img src="/images/arrow-d.svg" alt="arrow down" />
              )}
              value={selectedChatType}
              onChange={handleChange}
            >
              {/* <MenuItem value="global">Global Chat</MenuItem> */}
              <MenuItem value="support">Support Chat</MenuItem>
              {/* Add other options as needed */}
            </Select>

            <Grid
              className={classes.userNumber}
              display="flex"
              alignItems={"center"}
            >
              <b>{activeUsersCount || 0}</b>
              {darkMode ? (
                <>
                  <span>
                    <img
                      className={classes.chatIcon}
                      src="/images/chat-icon.svg"
                      alt="arrow down"
                    />
                  </span>

                  {isMobile && (
                    <Link onClick={handleChatBox}>
                      <img
                        src="/images/mob-chat-close.svg"
                        alt="mob-chat-close"
                      />
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <span>
                    <img src="/images/arrow-square-left.svg" alt="arrow down" />
                  </span>

                  {isMobile && (
                    <Link onClick={handleChatBox}>
                      <img
                        src="/images/mob-chat-close.svg"
                        alt="mob-chat-close"
                      />
                    </Link>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          {selectedChatType === "support" ? (
            <SupportChat selectedChatType={selectedChatType} />
          ) : (
            <>
              <div className={classes.scrollCustom}>
                <Grid className={classes.chatMessageGroup}>
                  {previousChats?.map((user, index) => (
                    <Grid
                      key={`${user?.userId}${user?.messageData?.message}${index}`}
                      className={
                        user.userId === userId
                          ? classes.chatMessage
                          : classes.chatMessageMine
                      }
                    >
                      <Grid className={classes.profileImage}>
                        <span className={classes.userBorder}>
                          <img
                            src={
                              user.profileImage || "/images/casino-banner-2.png"
                            }
                            alt="player-icon"
                          />
                        </span>
                        <span className={classes.userProfileLevel}>
                          {user?.userLevelId}
                        </span>
                      </Grid>
                      <Grid className={classes.message}>
                        <p className={classes.userNameTime}>
                          <span>{user?.userName || "Ngin"}</span>
                          <span>
                            {moment(
                              user?.dateTime || "2023-04-13T07:38:19.125Z"
                            ).format("h:mm A") || "1:12PM"}
                          </span>
                        </p>
                        {user?.messageData?.messageType === "text" && (
                          <p className={classes.userParagraph}>
                            {modifyMessage(user?.messageData?.message)}
                          </p>
                        )}
                        {user?.messageData?.messageType === "file" && (
                          <img
                            style={{ maxWidth: "100px", maxHieght: "100px" }}
                            src={
                              user?.messageData?.message || "/images/bunny.avif"
                            }
                            alt="gif"
                          ></img>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <div ref={messagesEndRef} />
              </div>
              <Grid key="chatbox" className={classes.chatBoxFooter}>
                <Grid key="chatbox2" className={classes.footerInput}>
                  <Dialog
                    open={showGIPFY}
                    onClose={() => setShowGIPFY(false)}
                    className={classes.dropShadow}
                  >
                    <ReactGiphySearchbox
                      apiKey={
                        process.env.GIFY_API_KEY ||
                        "dJqz97Qx99lnUooxTKr5GuoSEI86Hl9A"
                      }
                      onSelect={(item) => handleGIPHYSelect(item)}
                      masonryConfig={[
                        { columns: 4, imageWidth: 60, gutter: 5 },
                        { mq: "700px", columns: 4, imageWidth: 60, gutter: 5 },
                      ]}
                    />
                  </Dialog>
                  <Dialog
                    open={showPickerr}
                    onClose={() => setShowPickerr(false)}
                    className={classes.dropShadow}
                  >
                    <EmojiPicker
                      skinTonesDisabled
                      height={"260px"}
                      width={"298px"}
                      theme="light"
                      className={classes.picker}
                      disableAutoFocus={true}
                      previewConfig={{
                        showPreview: false,
                        showSuggested: false,
                        showFrequent: false,
                      }}
                      onEmojiClick={onEmojiClick}
                    />
                  </Dialog>
                  {!preview && (
                    <MentionsInput
                      className="comments-textarea"
                      placeholder="Type your message"
                      value={message}
                      onChange={(e) => {
                        setMessageTyp("text");
                        if (
                          e?.target?.value &&
                          e?.target?.value?.length > 100
                        ) {
                          toast.error(
                            "Oops! Seems like your message is too long ",
                            { id: "messgaeLength" }
                          );
                          return;
                        }
                        if (e?.target?.value) {
                          setMessage(
                            customFilter?.cleanHacked(e?.target?.value)
                          );
                        } else setMessage("");
                        inputRef?.current?.focus();
                      }}
                      a11ySuggestionsListLabel={"Suggested mentions"}
                      onKeyDown={handleOnKeyDownInput}
                      key="message"
                      inputRef={inputRef}
                    >
                      <Mention
                        trigger="@"
                        style={{
                          backgroundColor: "red",
                        }}
                        data={(search) => {
                          dispatch(getUserListStart({ search }));
                          return activeUsers;
                        }}
                        displayTransform={(_id, display) => {
                          return `@${display} `;
                        }}
                      />
                    </MentionsInput>
                  )}
                  {preview && (
                    <div className={classes.previewContainer}>
                      <IconButton
                        className={classes.closeButton}
                        onClick={handleClearClick}
                      >
                        <ClearIcon />
                      </IconButton>
                      {preview ? (
                        <img
                          src={preview}
                          alt="Preview"
                          className={classes.previewImage}
                        />
                      ) : (
                        <p>Unsupported file type</p>
                      )}
                    </div>
                  )}
                  <Grid className={classes.searchIcons}>
                    {!preview && (
                      <Link
                        onClick={() => {
                          setShowPickerr(true);
                        }}
                      >
                        <img src="/images/wpf_attach.png" alt="emoji" />
                      </Link>
                    )}
                    <span>
                      {sendMessageLoading ? (
                        <Link
                          disabled
                          ref={submitRef}
                          onClick={handleSendMessage}
                        >
                          <img
                            className={classes.disbled}
                            src="/images/load.gif"
                            alt="sending"
                          />
                        </Link>
                      ) : (
                        <Link
                          ref={submitRef}
                          disabled={disableButton}
                          onClick={handleSendMessage}
                        >
                          <img src="/images/sent-icon.svg" alt="sent" />
                        </Link>
                      )}
                    </span>
                  </Grid>
                </Grid>
                <div className={classes.root}>
                  <Input
                    id="file-input"
                    type="file"
                    onChange={handleFileChange}
                    className={classes.input}
                  />
                </div>
              </Grid>
              <Modal
                isShowing={chatRule}
                className={classes.dialogcontainer}
                content={
                  <DialogContent>
                    <ChatRules handleChatRules={handleChatRules} />
                  </DialogContent>
                }
              />
            </>
          )}
        </section>
      </Grid>
    )
  );
};

export default ChatBox;
