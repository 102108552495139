import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  leftArrow: {
    background: "#2B2B38!important",
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "8px !important",
    height: "33px",
    width: "33px",
    minWidth: "33px !important",
    padding: "0 !important",
    display: "flex !important",
    opacity: "1 !important",
    [theme.breakpoints.down(1028)]: {
      height: "25px",
      width: "25px",
      minWidth: "25px !important",
    },
    "& svg": {
      fill: "#666773",
      [theme.breakpoints.down("md")]: {
        width: "15px",
      },
      [theme.breakpoints.down(1028)]: {
        width: "15px",
      },
    },
    [theme.breakpoints.down("md")]: {
      height: "26px",
      width: "26px",
      minWidth: "26px !important",
    },
    "&:hover": {
      backgroundColor: "rgba(43, 43, 56, 0.8)",
    },
  },
  rightArrow: {
    background: "#2B2B38!important",
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "8px !important",
    height: "33px",
    width: "33px",
    minWidth: "33px !important",
    padding: "0 !important",
    [theme.breakpoints.down(1028)]: {
      height: "25px",
      width: "25px",
      minWidth: "25px !important",
    },
    "& svg": {
      fill: "#666773",
      [theme.breakpoints.down("md")]: {
        width: "15px",
      },
      [theme.breakpoints.down(1028)]: {
        width: "15px",
      },
    },
    [theme.breakpoints.down("md")]: {
      height: "26px",
      width: "26px",
      minWidth: "26px !important",
    },
    "&:hover": {
      backgroundColor: "rgba(43, 43, 56, 0.8)",
    },
  },
  providerWrap: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    // display: "none",
    "& .horizontalScrollWrapper": {
      position: "relative",
      "& .react-horizontal-scrolling-menu--scroll-container": {
        gap: "6px",
      },
    },
    "& .MuiButtonBase-root": {
      padding: "0 !important",
      width: "33px",
      minWidth: "33px !important",
      background: theme.palette?.background?.highlightSocialApp,
      border: "1.4px solid rgba(255, 255, 255, 0.04)",
      boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
      borderRadius: "8px !important",
      opacity: "1 !important",
      [theme.breakpoints.down("md")]: {
        width: "26px",
        minWidth: "26px !important",
        borderRadius: "6px !important",
      },
      "& svg": {
        fill: "#666773",
      },
    },
    "& .MuiTabs-scrollableX": {
      margin: "0 10px",
    },
    "& .react-horizontal-scrolling-menu--arrow-left": {
      top: "-55px",
      right: "40px",
      position: "absolute",
      transform: "translateY(0)",
      [theme.breakpoints.down("md")]: {
        top: "-36px",
        right: "30px",
      },
    },

    "& .react-horizontal-scrolling-menu--arrow-right": {
      top: "-55px",
      right: "0px",
      position: "absolute",
      transform: "translateY(0)",
      [theme.breakpoints.down("md")]: {
        top: "-36px",
      },
    },
  },

  providerSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "22px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "13px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
    "& h6": {
      fontSize: "20px",
      lineHeight: "normal",
    },
  },
  providerMobile: {
    "& .react-multi-carousel-list": {
      padding: "0px 1px",
    },
  },
  heading: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    [theme.breakpoints.down("md")]: {
      gap: "8px",
    },
    "& h6": {
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        lineHeight: "20px",
      },
    },
    "& img": {
      [theme.breakpoints.down("md")]: {
        width: "15px",
      },
    },
  },

  providerDetail: {
    gap: "12px",
    justifyContent: "center",
    display: "flex",
  },

  providerName: {
    cursor: "pointer",
    background: theme.palette?.background?.providers,
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "12px",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "9px 0 !important",
    minWidth: "189px",
    // marginRight: "12px",
    height: "80px",
    [theme.breakpoints.down("md")]: {
      borderRadius: "8px",
      minWidth: "auto",
      height: "48px",
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: "8px",
      marginRight: "6px",
    },
    "& span": {
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
      },
    },
    "& img": {
      width: "70%",
      objectFit: "contain",
      aspectRatio: "12/4",
    },
  },

  providerArrow: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    "& span": {
      border: "1.4px solid rgba(255, 255, 255, 0.04)",
      boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
      borderRadius: "8px",
      display: "inline-block",
      padding: "2px 11px",
      background: theme.palette?.background?.highlightSocialApp,
    },
  },

  providerCaraousel: {
    position: "absolute",
    top: "0px",
    right: "0px",
    display: "flex",
    gap: "5px",
    [theme.breakpoints.down("sm")]: {
      top: "0px",
      right: "5px",
    },
  },
}));
