import React, { useEffect, useState } from "react";

import {
  Button,
  CircularProgress,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "./ForgotForm.styles";
import { isMatchPassword, validatePassword } from "../../../utils/helper";
import {
  resetError,
  resetSuccess,
  verifyPasswordTokenStart,
} from "../../../redux-saga/redux/auth/authentication";

const ForgotForm = ({ id, token }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const [checked, setChecked] = useState(false);

  const { loading, success, error } = useSelector(
    (state) => state.authentication
  );

  const canBeSubmitted = () => {
    return checked ? setIsDisabled(true) : setIsDisabled(false);
  };

  const onCheckboxClick = () => {
    setChecked(!checked);
    return canBeSubmitted();
  };

  const handleUpdatePassword = () => {
    if (!validatePassword(password)) {
      toast.error(t("COMMON.PASSWORD_VALIDATION"));
      return;
    }
    if (!isMatchPassword(password, confirmPassword)) {
      toast.error(t("CHANGE_PASSWORD.PASSWORD_MATCH_VALIDATION"));
      dispatch(resetError());
      return;
    }
    dispatch(
      verifyPasswordTokenStart({
        id,
        token,
        password: window.btoa(password),
      })
    );
  };

  useEffect(() => {
    if (success && !loading) {
      dispatch(resetSuccess());
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }

    if (error && !loading) {
      toast.error(error);
      dispatch(resetError());
    }
  }, [loading, success, error, navigate, dispatch, t]);

  const { darkMode } = useSelector((state) => state.themeSettings);

  return (
    <div className={classes.container}>
      <Grid className={classes.forgotFormContainer}>
        <Grid className={classes.forgotFormWrap}>
          {darkMode ? (
            <img src="/images/modal-banner.png" alt="modal banner img" />
          ) : (
            <img src="/images/light-modal-banner.png" alt="modal banner img" />
          )}

          <Grid className={classes.forgotForm}>
            <Typography variant="h4" gutterBottom>
              {t("HEADER.FORGOT_PASSWORD_UCASE")}
            </Typography>
            <Grid className={classes.forgotFormWrap}>
              {/* <Grid> */}
              <Grid className={classes.formGroup}>
                <FormLabel className={classes.formInput}>
                  New Password
                </FormLabel>
                <div className={`${classes.formGroup} formGroupMb`}>
                  <form noValidate>
                    <TextField
                      autoComplete="off"
                      autoCorrect="off"
                      type={showPassword ? "text" : "password"}
                      placeholder={t("CHANGE_PASSWORD.NEW_PASSWORD")}
                      variant="outlined"
                      onChange={(e) => setPassword(e.target.value)}
                      inputProps={{
                        maxLength: 40, // Set the maximum character limit
                      }}
                    />
                    <Link
                      href="#"
                      className={classes.showLink}
                      onClick={() => setShowPassword((prevState) => !prevState)}
                    >
                      {!showPassword ? t("COMMON.SHOW") : t("COMMON.HIDE")}
                    </Link>
                  </form>
                </div>
              </Grid>
              <Grid className={classes.formGroup}>
                <FormLabel className={classes.formInput}>
                  Confirm New Password
                </FormLabel>
                <div className={classes.formGroup}>
                  <TextField
                    autoComplete="off"
                    autoCorrect="off"
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder={t("CHANGE_PASSWORD.CONFIRM_NEW_PASSWORD")}
                    variant="outlined"
                    onChange={(e) => setconfirmPassword(e.target.value)}
                    inputProps={{
                      maxLength: 40, // Set the maximum character limit
                    }}
                  />
                  <Link
                    href="#"
                    className={classes.showLink}
                    onClick={() =>
                      setShowConfirmPassword((prevState) => !prevState)
                    }
                  >
                    {!showConfirmPassword ? t("COMMON.SHOW") : t("COMMON.HIDE")}
                  </Link>
                </div>
              </Grid>
              {/* </Grid> */}
            </Grid>
            <div className="forgot-terms">
              <div className="custom-checkbox">
                <label className="container">
                  <input type="checkbox" onClick={onCheckboxClick} />
                  <span className="checkmark"></span>I confirm the information I
                  provided is correct.
                </label>
              </div>
            </div>
            <Grid className={`${classes.authBtnWrap} ${classes.forgotBtnWrap}`}>
              <Button
                disabled={isDisabled}
                className={isDisabled ? classes.disabled : classes.btnPrimary}
                // className={classes.btnPrimary}
                onClick={handleUpdatePassword}
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  t("HEADER.UPDATE_PASSWORD")
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotForm;
