import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Tab, Tabs, Box } from "@mui/material";
import ReactSelect from "react-select";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

import TransactionPage from "./table";
import { getUsersAllBonusHistStart } from "../../redux-saga/redux/transaction";
import useStyles from "../../container/Casino/Lobby/Lobby.styles";

const BonusHistoryPage = () => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pageLimit = 10;
  const { bonusCount, userBonusHistory, loading } = useSelector(
    (state) => state.transactions
  );
  const { darkMode } = useSelector((state) => state.themeSettings);
  const [pageCount, setPageCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({
    paymentType: "All",
    period: "Today",
  });
  useEffect(() => {
    setPageCount(Math.ceil(bonusCount / pageLimit));
  }, [bonusCount]);
  useEffect(() => {
    dispatch(
      getUsersAllBonusHistStart({
        offset: skip,
        limit: pageLimit,
        ...filterData,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, filterData]);
  const handleChange = (event, newValue) => {
    setFilterData({ ...filterData, period: event?.target?.textContent });
    setValue(newValue);
  };
  const handleCoinSelect = (e) => {
    setFilterData({ ...filterData, paymentType: e?.value });
  };

  const handlePageClick = ({ selected }) => {
    setSkip(selected * pageLimit);
    setCurrentPage(selected);
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Helmet>
        <body className="transaction-remove-padding" />
      </Helmet>

      <section>
        <Grid
          className={`${classes.transLobbyPage} transactionPageBannerSection`}
        >
          <div className="contentSec">
            {/* back button  */}
            <div className="backBtnDiv" onClick={goBack} role="presentation">
              <div className="backBtn">
                <img src="/images/back-icon.svg" alt="back-icon" />
                <p>Back</p>
              </div>
            </div>
            <h1>BONUS HISTORY </h1>
          </div>
        </Grid>
      </section>
      <section className="RoutesWraper">
        <Grid className={classes.bonusHistorySection}>
          <Grid className={" transactionThemeCard"}>
            <div className="transDivFlex">
              <Box className="transactionSelectBox">
                <div>
                  <p className="transactionHeading transactionPaymentType">
                    Payment Type
                  </p>
                  <ReactSelect
                    className={`${classes.depositInput} basic-single transactionSelectBox`}
                    onChange={handleCoinSelect}
                    classNamePrefix="select"
                    closeMenuOnSelect={true}
                    isSearchable={false}
                    name="color"
                    placeholder="Select Payment"
                    defaultValue={{ label: "All", value: "all" }}
                    options={[{ label: "All", value: "all" }]}
                    styles={{
                      menu: (provided) => ({
                        ...provided,
                        borderRadius: "8px",
                        marginTop: "-1px",
                        overflow: "auto",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        borderRadius: "8px",
                        background: state.isSelected
                          ? "#24242b"
                          : "transparent",
                        color: state.isSelected ? "#fff" : "#fff",
                        "&:hover": {
                          backgroundColor: "#24242b",
                        },
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        color: darkMode ? "#fff" : "#24242b",
                        borderRadius: "8px",
                      }),
                      menuList: (s) => ({
                        ...s,
                        background: "#24242b",
                        borderRadius: "12px",
                        fontSize: "12px",
                        border: "1.4px solid rgba(255, 255, 255, 0.04)",
                        boxShadow:
                          "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
                      }),
                    }}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: "8px",
                      colors: {
                        ...theme.colors,
                        neutral0: darkMode ? "#2a2a2f" : "#f7df94",
                        primary25: "rgba(123, 98, 218, 0.16)",
                        neutral80: "#fff",
                      },
                    })}
                  />
                </div>
              </Box>
              <Box
                className={`${classes.tabSectionTransaction} tabTransitionCustom`}
              >
                <p className="transactionHeading">Period</p>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  className="Tabsection"
                >
                  <Tab label="Today" />
                  <Tab label="Yesterday" />
                  <Tab label="7 Days" />
                  <Tab label="One Month" />
                </Tabs>
              </Box>
            </div>

            {loading ? (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                }}
              >
                <div className="load" />
              </Box>
            ) : (
              <Grid className={classes.betTableWrap}>
                <TransactionPage
                  data={userBonusHistory}
                  currentPage={currentPage}
                  pageLimit={pageLimit}
                />
              </Grid>
            )}
            {Number(bonusCount || 0) > 10 ? (
              <div className="refferal-pagination  transaction-pagination">
                <ReactPaginate
                  previousLabel={<PreArrow />}
                  breakLabel="..."
                  nextLabel={<NextArrow />}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  forcePage={currentPage}
                  containerClassName={"pagination"}
                  previousLinkClassName={"previous-page"}
                  nextLinkClassName={"next-page"}
                  disabledClassName={"pagination-disabled"}
                  activeClassName={"pagination-active"}
                />
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </section>
    </>
  );
};

export default BonusHistoryPage;

const PreArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 33 33"
      fill="none"
    >
      <g filter="url(#filter0_i_86_2806)">
        <rect width="33" height="33" rx="8" fill="#2B2B38" />
      </g>
      <rect
        x="0.7"
        y="0.7"
        width="31.6"
        height="31.6"
        rx="7.3"
        stroke="white"
        strokeOpacity="0.04"
        strokeWidth="1.4"
      />
      <path
        d="M17 13L13 16.5L17 20"
        stroke="#666773"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_i_86_2806"
          x="0"
          y="0"
          width="33"
          height="35"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_86_2806"
          />
        </filter>
      </defs>
    </svg>
  );
};

const NextArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 33 33"
      fill="none"
    >
      <g filter="url(#filter0_i_86_2809)">
        <rect
          x="33"
          y="33"
          width="33"
          height="33"
          rx="8"
          transform="rotate(-180 33 33)"
          fill="#2B2B38"
        />
      </g>
      <rect
        x="32.3"
        y="32.3"
        width="31.6"
        height="31.6"
        rx="7.3"
        transform="rotate(-180 32.3 32.3)"
        stroke="white"
        strokeOpacity="0.04"
        strokeWidth="1.4"
      />
      <path
        d="M16 20L20 16.5L16 13"
        stroke="#666773"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_i_86_2809"
          x="0"
          y="0"
          width="33"
          height="35"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_86_2809"
          />
        </filter>
      </defs>
    </svg>
  );
};
