import "./index.css";

const ErrorMessage = ({ error }) => {
  if (error?.message)
    // eslint-disable-next-line react/react-in-jsx-scope
    return <p className="form-error-message-submit">{error?.message}</p>;
  return null;
};

export default ErrorMessage;
