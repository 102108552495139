import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  scrollCustom: {
    height: "calc(100vh - 263px)",
    overflowY: "scroll",
    padding: "0 10px",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0 0",
    },
  },
  fileUploadBtn: {
    height: "1x`0px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: `${theme?.palette?.background?.default} !important`,
    borderRadius: "12px !important",
    padding: "20px !important",
    position: "relative",
    "& input": {
      height: "100%",
      width: "100%",
      opacity: "0",
    },
    "& img": {
      position: "absolute",
      height: "100%",
      width: "100%",
      objectFit: "contain",
    },
    "&:hover": {
      backgroundColor: `${theme?.palette?.background?.default} !important`,
    },
    "& button": {
      height: "200px",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#1D1D23",
      borderRadius: "12px",
      "&:hover": {
        background: "#1D1D23",
      },
    },
  },
  // chatCrossIcon : {
  //   position: "absolute",
  //   top: "0",
  //   left: "0",
  // },
  fix: {},
  dialogBox: {
    padding: "0",
  },
  dialog: {
    position: "absolute",
    right: "0px",
    bottom: "103px",
    backdropFilter: "none",
  },

  chatBoxWrapper: {
    background: "#24242B",
    display: "inline-block",
    width: "340px",
    height: "calc(100% - 120px)",
    backdropFilter: "blur(15px)",
    borderRadius: "20px",
    padding: "14px 10px",
    marginTop: "100px",
    borderTop: "2px solid rgba(255, 255, 255, 0.2)",
    borderBottom: "2px solid rgba(255, 255, 255, 0.2)",
    position: "fixed",
    right: "20px",
    zIndex: "9",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      border: "none",
      height: "100%",
      padding: "0px 10px",
      marginTop: "0",
      borderRadius: "0 !important",
      display: "flex",
      flexDirection: "column",
      position: "relative",
      right: "0",
    },
  },
  chatBoxCloseBotton: {
    top: "80px",
    right: "0px",
    position: "fixed",
    marginTop: "40px",
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    display: "flex",
    background: theme?.palette?.background?.chatbtncolor,
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    alignItems: "center",
    borderRadius: "20px 0 0 20px",
    justifyContent: "center",
    width: "60px",
    height: "57px",
    cursor: "pointer",
    [theme.breakpoints.down("767")]: {
      display: "none !important",
    },
    "& ul": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      padding: "0",
      margin: "0",
      position: "relative",
      "& li": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        padding: "0",
        margin: "0",
        width: "100%",
        cursor: "pointer",
      },
    },
  },

  chatCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "radial-gradient(113.34% 127.34% at 50.43% -17.05%, #FFD233 0%, #FF8A00 100%)",
    boxShadow: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.25))",
    borderRadius: "24px",
    height: "20px",
    minWidth: "20px",
    width: "20px",
    fontSize: "14px",
    position: "absolute",
    top: "-5px",
    left: "0px",
    "& span": {
      color: "#fff",
      fontSize: "10px",
    },
  },

  sidebarToggleIconClose: {
    left: "0px",
    marginRight: "12px",
    paddingBottom: "5px",
    textAlign: "center",
    transform: "rotate(-180deg)",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      display: "none",
    },
  },
  chatBoxHeading: {
    width: "calc(100% + 20px)",
    marginLeft: "-10px",
    marginTop: "-14px",
    padding: "3px 20px",
    borderRadius: "20px 20px 0 0",
    background: theme.palette.background?.btnbackground,
    marginBottom: "20px",
    [theme.breakpoints.down(991)]: {
      marginTop: "0",
      borderRadius: "0",
      padding: "7px 20px",
      marginBottom: "0",
      justifyContent: "space-between",
    },
  },

  chatHeading: {
    flexGrow: "1",
    gap: "12px",
    fontWeight: "600",
    fontSize: "16px",
    pointerEvents: "none",
    color: theme.palette.text?.default,
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      maxWidth: "150px",
    },
    "& img": {
      position: "relative",
      top: "0px",
      right: "85px",
      cursor: "pointer",
      zIndex: "9",
      display: "none",
      [theme.breakpoints.down("md")]: {
        right: "30px",
      },
    },
    "& MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      boxShadow: "none",
      background: "transparent",
      "&:hover": {
        border: "none",
        boxShadow: "none",
        background: "transparent",
      },
    },
    "& MuiSelect-icon": {
      display: "none",
    },
    "& .MuiSelect-select": {
      // maxWidth: "100px",
      zIndex: "99",
      width: "100%",
      paddingRight: "0px !important",
      padding: "0",
      minHeight: "32px",
      display: "flex",
      alignItems: "center",
    },
  },

  chatToggle: {
    marginRight: "12px",
  },

  userNumber: {
    fontWeight: "600",
    fontSize: "16px",
    color: theme.palette.text?.default,
    gap: "12px",
    "& img": {
      position: "relative",
      top: "3px",
      width: "32px",
      [theme.breakpoints.down("md")]: {
        width: "28px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "28px",
      },
    },

    [theme.breakpoints.down("md")]: {
      fontSize: "16px",
    },
  },
  chatIcon: {
    boxShadow: "0px 0px 7px #4e51aaa6",
    borderRadius: "6px",
  },
  profileImage: {
    maxHeight: "40px",
    maxWidth: "40px",
    minWidth: "40px",
    position: "relative",
    marginTop: "-3px",
    "& img": {
      width: "100%",
      clipPath:
        "polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%)",
      objectFit: "cover",
      aspectRatio: "1",
      borderRadius: "4px",
      maxHeight: "100%",
      maxWidth: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& svg": {
      stroke: theme.palette.background?.userImgBorder,
    },
  },

  userProfileLevel: {
    position: "absolute",
    background: theme.palette.background?.chatTagBg,
    border: theme.palette.background?.chatTagBorder,
    boxShadow: theme.palette.background?.chatTagShadow,
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "15px",
    textTransform: "capitalize",
    color: theme.palette.text?.default,
    padding: "0px 9px",
    left: "3px",
    bottom: "-9px",
    width: "100%",
    minWidth: "32px",
    maxWidth: "32px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
      bottom: "-4px",
      minWidth: "24px",
      maxWidth: "24px",
      left: "50%",
      height: "12px",
      fontWeight: "600",
      transform: "translateX(-50%)",
      padding: "0px 2px",
      borderRadius: "2px",
      marginLeft: "-1px",
    },
  },

  userBorder: {
    width: "100%",
    clipPath:
      "polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%)",
    objectFit: "cover",
    aspectRatio: "1",
    borderRadius: "4px",
    maxHeight: "38px",
    maxWidth: "38px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background?.userImgBorder,
    border: "2px solid",
    borderColor: theme.palette.background?.userImgBorder,
  },

  root: {
    height: "1px",
    display: "none",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  previewContainer: {
    background: `${theme.palette?.background?.default2} !important`,
    boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "12px",
    border: "none",
    lineHeight: "40px",
    height: "40px",
    color: `${theme.palette?.text?.default} !important`,
    position: "relative",
    textAlign: "center",
  },
  previewImage: {
    maxWidth: "100%",
    maxHeight: "39px",
  },
  closeButton: {
    position: "absolute",
    top: "0px",
    right: "85px",
    height: "100%",
    "& svg": {
      fontSize: "17px",
    },
  },

  chatMessageGroup: {
    width: "100%",
    display: "inline-block",
  },

  chatMessage: {
    background: "rgba(53, 53, 66, 0.32)",
    boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
    borderRadius: "12px",
    padding: "8px 12px 12px 12px",
    display: "flex",
    gap: "6px",
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 12px 10px 12px",
      marginBottom: "4px",
      gap: "10px",
    },
  },
  chatMessageMine: {
    borderRadius: "12px",
    padding: "8px 12px 12px 12px",
    display: "flex",
    gap: "12px",
    marginBottom: "8px",
    background: theme.palette.background?.chatMessageBg,
    boxShadow: theme.palette.background?.chatMessageShadow,
  },

  userNameTime: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0",
    marginBottom: "4px",
    marginTop: "0",
    "& span": {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#757786",
      textTransform: "capitalize",
      [theme.breakpoints.down(991)]: {},
      "&:nth-child(2)": {
        fontSize: "12px",
        [theme.breakpoints.down(991)]: {
          fontSize: "12px",
        },
      },
    },
    [theme.breakpoints.down(991)]: {},
  },

  userParagraph: {
    margin: "0",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "normal",
    color: theme.palette.text.default,
    wordBreak: "break-word",
    [theme.breakpoints.down(991)]: {},
  },

  userImage: {
    height: "34px",
    width: "34px",
    position: "relative",
    "& img": {
      clipPath: "polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)",
      borderRadius: "4px",
      width: "100%",
    },
    "& span": {
      position: "absolute",
      left: "1",
      width: "32px",
      lineHeight: "18px",
      fontWeight: "500",
      fontSize: "14px",
      textTransform: "capitalize",
      color: "#FFFFFF",
      background: "#383E4E",
      border: "1px solid #383E4E",
      boxShadow: "inset 0px 4px 4px rgba(255, 255, 255, 0.04)",
      borderRadius: "4px",
      textAlign: "center",
      bottom: "-12px",
    },
  },

  message: {
    width: "calc(100% - 34px)",
    "& img": {
      borderRadius: "10px",
      width: "200px",
      marginTop: "4px",
    },
  },
  accountRight: {
    padding: "20px",
    background: theme?.palette?.background?.tablebackground,
    boxShadow: "inset 0px 2px 4px rgba(255, 255, 255, 0.02)",
    borderRadius: "12px",

    [theme.breakpoints.down("sm")]: {
      borderRadius: "8px",
      width: "100%",
      padding: "14px 10px",
    },
  },
  zoomedImage: {
    height: "400px",
    // overflowY: "auto",
    [theme.breakpoints.down("md")]: {
      height: "400px",
    },
    "& img": {
      maxWidth: "100%",
      width: "400px",
      height: "400px",
      objectFit: "contain",
      borderRadius: "8px",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  },

  chatBoxFooter: {
    display: "inline-block",
    width: "100%",
    marginTop: "0",
    [theme.breakpoints.down("md")]: {
      padding: "0px 0 15px",
    },
    "& .MuiInputBase-input": {
      width: "100%",
      background: theme.palette?.background?.buttonWallet,
      color: theme.palette.text?.placeholder,
      boxShadow: "inset 0px 2px 8px rgb(0 0 0 / 12%)",
      borderRadius: "12px",
      lineHeight: "40px",
      minHeight: "28px",
      padding: "6px 10px",
      fontSize: "15px",
      paddingRight: "100px",
    },
    "& fieldset": {
      border: "none",
    },
    "& p": {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#757786",
      textAlign: "center",
      margin: "12px 0",
      "& a": {
        color: "#00DAEA",
        textDecoration: "none",
        cursor: "pointer",
      },
    },
  },
  chatCrossIcon: {
    position: "absolute",
    top: "5px",
    left: "5px",
  },
  picker: {
    "& .EmojiPickerReact": {
      "& .epr-emoji-size": {
        size: "1px",
      },
    },
  },
  footerInput: {
    width: "100%",
    position: "relative",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .EmojiPickerReact": {
      width: "100% !important",
    },
    "& .comments-textarea__control": {
      marginTop: "20px",
      [theme.breakpoints.down(991)]: {
        margin: "0",
      },
    },
    "& .comments-textarea__input": {
      background: `${theme.palette?.background?.default2} !important`,
      wordBreak: "break-all",
      boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "12px",
      transition: "0.3s ease-in-out",
      padding: "0 100px 0 16px",
      fontSize: "16px",
      border: "none",
      lineHeight: "40px",
      overflow: "auto !important",
      height: "40px",
      maxHeight: "60px",
      color: `${theme.palette?.text?.default} !important`,
      "&:focus,&:active": {
        outline: "none",
        border: "none",
      },
      "&::placeholder": {
        color: "#4D4D5B",
        fontWeight: "500",
        opacity: "1",
        [theme.breakpoints.down("md")]: {},
      },
    },
    "& .comments-textarea__highlighter": {
      padding: "10px",
      height: "40px",
      maxHeight: "60px",
    },
    "& .comments-textarea__suggestions__list": {
      backgroundColor: theme.palette.background?.sidebar,
      color: theme.palette.text?.default,
      border: "1px solid rgba(0, 0, 0, 0.15)",
      fontSize: "16px",
      maxHeight: "200px",
      overflow: "auto",
    },
    "& .comments-textarea__suggestions__item": {
      padding: "5px 15px",
      color: theme.palette.text?.default,
      borderBottom: "1px solid rgba(0, 0, 0, 0.15)",
    },
    "& .comments-textarea__suggestions__item — focused ": {
      backgroundColor: "red",
    },
  },
  supprtChatInput: {
    marginTop: "20px",
    "& .MuiInputBase-input": {
      width: "100%",
      paddingLeft: "16px",
      "&::placeholder": {
        color: "#4D4D5B",
        fontWeight: "500",
        fontSize: "16px",

        opacity: "1",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
      },
    },
    "& .MuiInput-underline": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
  },
  chatMenu: {
    bottom: "100px !important",
    right: "0px !important",
  },
  searchIcons: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    right: "0",
    top: "0",
    transform: "translateY(0px)",
    gap: "10px",
    "& a": {
      display: "flex",
    },
    "& img": {
      cursor: "pointer",
      width: "20px",
      height: "20px",
    },
    "& span": {
      background:
        "radial-gradient(216.25% 216.25% at 50% -86.25%, #342A6F 0%, #5D69CF 100%)",
      border: "1.4px solid rgba(106, 113, 199, 0.84)",
      boxShadow: "0px 4px 12px rgba(108, 93, 193, 0.24)",
      borderRadius: "12px",
      lineHeight: "11px",
      padding: "10px",
      marginLeft: "2px",
    },
  },
  mobChatPannel: {
    position: "fixed",
    left: "0",
    bottom: "0",
    top: "0px",
    zIndex: "9",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingTop: "60px",
      paddingBottom: "75px",
      background: "#24242b",
    },
  },
  disbled: {
    maxWidth: "17px",
  },
  Nochats: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "calc(100vh - 240px)",
  },
  dropShadow: {
    "& .MuiBackdrop-root": {
      opacity: "0 !important",
    },
    "& .MuiDialog-paper": {
      position: "absolute",
      right: "0px",
      bottom: "100px",
      backdropFilter: "none",
      borderRadius: "8px !important",
      margin: "32px 15px",
    },
    "& .EmojiPickerReact": {
      background: theme.palette.background?.sidebar,
      borderColor: "rgba(255, 255, 255, 0.2) !important",
    },
    "& .reactGiphySearchbox-componentWrapper": {
      background: theme.palette.background?.sidebar,
      borderColor: "rgba(255, 255, 255, 0.2) !important",
      minWidth: "275px!important",
      padding: "10px",
    },
    "& .epr-emoji-category-label": {
      backgroundColor: `${theme.palette?.background?.default2} !important`,
    },
    "& .reactGiphySearchbox-searchForm-input": {
      backgroundColor: `${theme.palette?.background?.default2} !important`,
      borderColor: `${theme.palette?.background?.default2} !important`,
      color: theme.palette?.text?.default,
      borderRadius: "6px",
      padding: "6px 12px",
      fontSize: "14px",
    },
    "& input.epr-search": {
      backgroundColor: `${theme.palette?.background?.default2} !important`,
      borderColor: `${theme.palette?.background?.default2} !important`,
      color: `${theme.palette?.text?.default} !important`,
    },
  },
  chatRuleSection: {
    padding: "20px",
    backgroundColor: theme?.palette?.background?.providers,
    borderRadius: "0 0 12px 12px !important",
    maxWidth: "600px",
    "& li": {
      marginBottom: "20px",
      color: theme?.palette?.text?.default,
    },
  },
  chatRules: {
    fontSize: "16px",
    color: theme?.palette?.text?.default,
  },
  closeIconWrap: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    color: " #858DA0",

    "& .MuiTypography-h4": {
      color: theme?.palette?.text?.default,
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "23px",
      marginBottom: "16px",
      textTransform: "capitalize",
    },
  },

  closeIcon: {
    padding: "8px",
    justifyContent: "center",
    alignItems: "center",
    opacity: "1",
    width: "24px",
    height: "24px",
    background: theme?.palette?.background?.iframeIconBg,
    border: theme?.palette?.background?.iframeBorder,
    boxShadow: theme?.palette?.background?.iframeIconShadow,
    borderRadius: "6px",
    display: "flex",
    cursor: "pointer",
    color: " #858DA0",
    float: "right",
    marginBottom: "15px",
    "& .MuiSvgIcon-root": {
      fontSize: "17px",
    },
    "&:hover": {
      transform: "rotate(90deg)",
      transition: "transform 0.5s ease-in-out",
    },
  },
  chatSupportBox: {
    borderRadius: "8px",
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    background: "#2B2B38",
    boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
    position: "fixed",
    bottom: "30px",
    right: "30px",
    width: "60px",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("767")]: {
      display: "none !important",
    },
    "& img": {
      width: "35px",
      height: "35px",
      // "& ": {
      //   width: "35px !important",
      //   height: "35px !important",
      // },
    },
    [theme.breakpoints.down("md")]: {
      bottom: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      bottom: "60px",
      right: "0px",
    },
  },

  uploadImages: {
    position: "relative",
    "& img": {
      "& :hover": {
        "& ::before": {
          content: "''",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          borderRadius: "8px",
          cursor: "pointer",
          zIndex: "1",
        },
        "& chatCrossIcon": {
          display: "block",
        },
      },
    },
    "& chatCrossIcon": {
      display: "none",
    },
  },
  loginWrap: {
    // borderRadius: "12px",
    width: "440px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));
