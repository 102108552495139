import React, { useEffect, useRef, useState } from "react";

import { Grid, Box, LinearProgress, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import lodash from "lodash";
import { t } from "i18next";
import toast from "react-hot-toast";

import useStyles from "./MyProfile.styles";
import {
  getUserGameHistoryStart,
  updateProfileImageStart,
} from "../../../redux-saga/redux/profile/myProfile";
import { getTimeDifference } from "../../../utils/durationBtwTwoDates";

const UserProfile = ({ userDetails, gameProgress, handleCloseAccountMenu }) => {
  const { userGameHistory, loading, imageUpdateLoading } = useSelector(
    (state) => state.myProfile
  );
  const [recentGamesGrid, setRecentGamesGrid] = useState([]);
  const [mostPlayedGrid, setMostPlayedGrid] = useState([]);

  const fileInputRef = useRef(null);

  const classes = useStyles({ gameProgressWidth: gameProgress });
  const dispatch = useDispatch();

  useEffect(() => {
    setRecentGamesGrid(
      lodash.chunk(userGameHistory?.lastRecent, 2).slice(0, 4)
    );
    setMostPlayedGrid(
      lodash.chunk(userGameHistory?.mostGamePlayed, 2).slice(0, 4)
    );
  }, [userGameHistory]);

  useEffect(() => {
    dispatch(getUserGameHistoryStart());
  }, [dispatch]);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const selfiee = e.target.files[0];
    const usrId = parseInt(localStorage.getItem("useId"));
    const validation = [];
    if (!selfiee) {
      validation.push(t("HEADER.SELFIEE_VALIDATION"));
    } else {
      if (selfiee.size <= 0) {
        validation.push("Please Upload file");
      }

      if (
        selfiee.type &&
        !/(image\/png|image\/jpeg|image\/jpg)/.test(selfiee.type)
      ) {
        validation.push("Please upload selfie in png, jpg, or jpeg format");
      }
    }
    if (!usrId) {
      validation.push(t("HEADER.USERID_VALIDATION"));
    }
    if (validation.length !== 0) {
      validation.forEach((msg) => {
        toast.error(msg, { id: "imageUploadError" });
      });
      return;
    }
    const fd = new FormData();
    if (selfiee?.name) {
      fd.append("image", selfiee);
      fd.append("id", usrId);
      dispatch(updateProfileImageStart(fd));
    }
  };

  return (
    <div className={`${classes.accountRight} ${classes.userProfileUpdate}`}>
      <Grid className={classes.myprofileBottom}>
        <Grid className={classes.myprofileForm}>
          <Box className={classes.closeIconWrap}>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={handleCloseAccountMenu}
            />
          </Box>
          <Grid className={classes.sidebarUserIcon}>
            {imageUpdateLoading ? (
              <Skeleton
                variant="circular"
                animation="wave"
                width={50}
                height={50}
                style={{
                  clipPath:
                    "polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%)",
                }}
              />
            ) : (
              <>
                <span
                  className={`${classes.userBorder} profileImgHover userProfileBorderProfile`}
                >
                  <img
                    src={
                      (userDetails && userDetails?.user?.profile_image) ||
                      "/images/user_icon.svg"
                    }
                    alt="player-icon"
                  />
                  <Box className="userProfileHover">
                    <input
                      type="file"
                      id="imageUpload"
                      name="file"
                      accept=".png, .jpg, .jpeg"
                      ref={fileInputRef}
                      onChange={handleImageUpload}
                    />
                    <p onClick={handleClick} role="presentation">
                      Change
                    </p>
                  </Box>
                </span>
                <Grid className={classes.sidebarUserNameWrap}>
                  <span className={classes.sidebarUserName}>
                    {userDetails?.user?.user_name || "no name found"}
                  </span>
                  {/* {userDetails?.user?.online && ( */}
                  <Box className={classes.onlineIndicatorWrap}>
                    <Box className={classes.onlineIndicator}>Online</Box>
                    <Box className={classes.greenBlink}></Box>
                  </Box>
                  {/* )} */}
                </Grid>
              </>
            )}
          </Grid>

          <Box className={classes.progressBar}>
            <Grid className={classes.profileTopIcon}>
              <img
                src={
                  userDetails?.currentLevel?.id >= 1
                    ? `/images/level_icons/_Group_ (${
                        userDetails?.currentLevel?.id - 1
                      }).png`
                    : "/images/level_icons/_Group_ (0).webp"
                }
                alt="profile"
              />
            </Grid>

            <Grid className={classes.rightSection}>
              <Grid className={classes.grandStep}>
                <p>
                  <span>Current:</span>
                  <span>{userDetails?.currentLevel?.userLevel}</span>
                </p>

                {userDetails?.nextLevel?.userLevel && (
                  <p>
                    <span>Next:</span>
                    <span>{userDetails?.nextLevel?.userLevel}</span>
                  </p>
                )}
              </Grid>
              <LinearProgress
                thickness={6}
                value={gameProgress}
                variant="determinate"
                className={classes.linearBar}
              />
            </Grid>
          </Box>

          <Grid className={classes.totalSection}>
            <Grid className={classes.total}>
              <p>{userDetails?.user?.TotalWins || 0}</p>
              <p>Total Wins</p>
            </Grid>

            <Grid className={classes.total}>
              <p>{userDetails?.user?.TotalBets || 0}</p>
              <p>Total Bets</p>
            </Grid>

            <Grid className={classes.total}>
              <p>
                $
                {userDetails?.user?.TotalWageredAmount
                  ? userDetails?.user?.TotalWageredAmount.toFixed(2)
                  : 0.0}
              </p>
              <p>Total Wagered</p>
            </Grid>
          </Grid>

          <Grid className={classes.gameHeading}>
            <p>Most Game Played</p>
            <p>
              <span>{"Wagered  :"}</span>
              <span>
                $
                {userGameHistory?.totalWageredAndDate?.TotalWageredAmount
                  ? userGameHistory?.totalWageredAndDate?.TotalWageredAmount.toFixed(
                      2
                    )
                  : 0.0}
              </span>
            </p>
          </Grid>

          <Box className={classes.gameWrapper}>
            {loading ? (
              <Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={50}
                      height={50}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={50}
                      height={50}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : mostPlayedGrid?.length !== 0 ? (
              <>
                {mostPlayedGrid?.map((game, index) => (
                  <Grid key={index} className={classes.gameSection}>
                    {game?.map((g, i) => (
                      <Grid key={g?.game_id} className={classes.floatLeft}>
                        <div className={classes.gameImage}>
                          <img
                            src={
                              g?.MasterCasinoGame?.thumbnailUrl ||
                              "/images/casino-banner-2.png"
                            }
                            alt="id"
                          />
                        </div>

                        <div className={classes.gameNameDetail}>
                          <p
                            className={classes.gameName}
                            title={g?.MasterCasinoGame?.name}
                          >
                            {g?.MasterCasinoGame?.name}
                          </p>
                          <p>
                            <span> {"Wagered   :"}</span>
                            <span>
                              $
                              {g?.WageredAmount
                                ? g?.WageredAmount.toFixed(2)
                                : 0.0}
                            </span>
                          </p>
                          <p>
                            <span>{"Bets :"}</span>
                            <span>{g?.TotalBet || 0}</span>
                          </p>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </>
            ) : (
              <>
                <span style={{ fontSize: "12px" }}>No games found</span>
              </>
            )}
          </Box>

          <Grid className={classes.gameHeading}>
            <p>Last Recent</p>
            <p>
              <span>
                {getTimeDifference(
                  userGameHistory?.totalWageredAndDate?.TransactionDate
                ) || "0 Hours 0 Minutes"}
              </span>
            </p>
          </Grid>

          <Box className={classes.gameWrapper}>
            {loading ? (
              <Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={50}
                      height={50}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Skeleton
                      variant="rectangular"
                      animation="wave"
                      width={50}
                      height={50}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ) : recentGamesGrid?.length !== 0 ? (
              <>
                {recentGamesGrid?.map((game, index) => (
                  <Grid key={index} className={classes.gameSection}>
                    {game?.map((g, i) => (
                      <Grid key={g?.game_id} className={classes.floatLeft}>
                        <div className={classes.gameImage}>
                          <img
                            src={
                              g?.MasterCasinoGame?.thumbnailUrl ||
                              "/images/casino-banner-2.png"
                            }
                            alt="id"
                          />
                        </div>

                        <div className={classes.gameNameDetail}>
                          <p
                            className={classes.gameName}
                            title={g?.MasterCasinoGame?.name}
                          >
                            {g?.MasterCasinoGame?.name}
                          </p>
                          <p>
                            <span>{"Wagered  :"}</span>
                            <span>
                              $
                              {g?.WageredAmount
                                ? g?.WageredAmount.toFixed(2)
                                : "0.00"}
                            </span>
                          </p>
                          <p>
                            <span>
                              {getTimeDifference(g?.TransactionDate) ||
                                "0 hours 0 minutes"}
                            </span>
                          </p>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </>
            ) : (
              <>
                <span style={{ fontSize: "12px" }}>No games found</span>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default UserProfile;
