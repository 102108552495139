import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { validatePassword } from "../../../utils/helper";
import { ErrorMessage } from "./constants";
import {
  loginStart,
  resetLoginApiState,
} from "../../../redux-saga/redux/auth/authentication";

import "./login.css";

function LoginForm({ setState }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.authentication);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validation = [];
    if (formData?.email?.length === 0) {
      validation.push(t("Username Or Email Required"));
    } else if (!validatePassword(formData?.password)) {
      validation.push(t("HEADER.PASSWORD_VALIDATION_FAILED"));
    }
    if (validation?.length !== 0) {
      validation.forEach((message) => {
        toast.error(message, { id: "loginError" });
      });
      return;
    }
    dispatch(
      loginStart({
        userNameOrEmail: formData.email,
        password: window.btoa(formData.password),
      })
    );
  };
  useEffect(
    () => () => {
      dispatch(resetLoginApiState());
    },
    [dispatch]
  );
  return (
    <div className="loginFormMainWrap">
      <form onSubmit={handleSubmit}>
        <div className="inputWrap">
          <div className="input-login-form-wrap">
            <div className="input-container-login">
              <label htmlFor="email" className="input-label">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="custom-input"
                placeholder="user@gmail.com"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="input-container-login position-relative">
              <label htmlFor="password" className="input-label">
                Password
              </label>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                className="custom-input password-input"
                placeholder="********"
                value={formData.password}
                onChange={handleChange}
              />
              <img
                src={
                  authStore?.loginFailure?.message === "Wrong Password"
                    ? "/images/redEye.png"
                    : "/images/showPassword.png"
                }
                alt=""
                className="showPassword"
                onClick={() => setShowPassword((p) => !p)}
                role="presentation"
              />
            </div>
          </div>
          <ErrorMessage
            error={{
              message:
                authStore?.loginFailure?.message === "Wrong Password"
                  ? "Incorrect Password"
                  : "",
            }}
          />
          <div className="incorrectPassword">
            <Link>Incorrect password</Link>
          </div>
          <div className="forgotPasswordTxtWrap">
            <Link type="button" onClick={() => setState("forgot_password")}>
              Forgot password?
            </Link>
          </div>
          <div className="logInBtnWrap">
            <Button type="submit">Log in</Button>
          </div>
          <div className="divider-container">
            <span className="divider-text">Or continue with social media</span>
          </div>
        </div>
      </form>
      <div className="loginSocialBtnWrap">
        <Button>
          <img src="/images/loginMeta.png" alt="" />
          Meta
        </Button>
        <Button>
          <img src="/images/loginGoogle.png" alt="" />
          Google
        </Button>
      </div>
    </div>
  );
}

export default LoginForm;
