import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  searchContainer: {
    display: "flex",
    "&  .MuiInputBase-root": {
      [theme.breakpoints.down("md")]: {
        background: "#fff !important",
      },
    },
  },

  category: {
    background: theme?.palette?.background?.sidebar,
    padding: "0 15px",
  },

  accordian: {
    "&:before": {
      display: "none",
    },
    "& .MuiAccordionSummary-root": {
      width: "100%",
      margin: "0 !important",
      minHeight: "44px !important",
      padding: "10px !important",
      background: theme?.palette?.background?.default,
      boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "12px !important",
      marginBottom: "20px !important",
      "& p": {
        fontSize: "12px",
      },
      "& .MuiAccordionSummary-content": {
        margin: "0",
      },

      color: theme?.palette?.text?.color,
      "&:last-child": {
        marginBottom: "0px !important",
      },
    },
  },

  search: {
    padding: "0 15px 20px 15px",
    display: "flex",
    flexDirection: "column",
    rowGap: "0.5rem",
    backgroundColor: "#24242B",
    width: "100%",
    height: "100%",
  },

  selectemItemBtn: {
    marginTop: "10px",
    textTransform: "none",
  },
  searchGames: {
    minHeight: "23rem",
    margin: "0",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme?.palette?.text?.placeholder,
  },
  searchGamesshow: {
    paddingLeft: "1rem",
  },
  headerSearch: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    "& .MuiTextField-root": {
      width: "100%",
    },
    "& .MuiInputBase-input": {
      color: theme?.palette?.text?.default,
      "&::placeholder": {
        color: "#757786",
        fontWeight: "500",
        fontSize: "16px",
        [theme.breakpoints.down("md")]: {
          fontSize: "16px",
        },
      },
    },
    "& .MuiInputBase-root": {
      borderRadius: "12px",
      height: "44px",
      fontSize: "14px",
      background: "#17171C !important",
      boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
      color: theme?.palette?.text?.default,
      padding: "0 20px",
      paddingLeft: "25px",
      "&::placeholder": {
        color: "#757786",
        fontWeight: "500",
        fontSize: "16px",
        [theme.breakpoints.down("md")]: {
          fontSize: "12px",
        },
      },
      [theme.breakpoints.down("md")]: {
        borderRadius: "8px",
      },
    },
    "& img": {
      top: "0px",
      left: "10px",
      position: "absolute",
      bottom: "0px",
      margin: "auto",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  searchImg: {
    textAlign: "center",
    "& img": {
      width: "300px",
    },
    "& .MuiTypography-h6": {
      fontSize: "14px",
      [theme.breakpoints.down("md")]: {
        background: "transparent",
        textAlign: "center",
        padding: "0 10px",
        color: "#757786",
      },
    },
  },
  gamesWrapper: {
    justifyContent: "center",
    "& .MuiGrid-root": {
      gap: "3px",
      justifyContent: "space-between",
      width: "100%",
    },
    "& .MuiGrid-item": {
      float: "left",
      marginBottom: "50px",
      width: "152px",
      height: "200px",
      aspectRatio: "1",
      marginRight: "18px",
      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
        marginBottom: "0",
        width: "32.15%",
        height: "190px",
        aspectRatio: "2",
      },
    },
    "& .casinoCard": {
      "& .MuiTypography-body1": {
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
          overflowX: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: "120px",
        },
      },
    },
  },

  loadMoreButton: {
    border: "1.4px solid rgba(106, 113, 199, 0.84)",
    background:
      "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%) !important",
    borderRadius: "12px !important",
    padding: "6px 20px",
    color: "#fff !important",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "capitalize",
    marginTop: "20px",
  },
}));
