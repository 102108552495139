import { createSlice } from "@reduxjs/toolkit";

export const notificationInitialState = {
  loading: false,
  success: false,
  error: false,
  notificationList: [],
  unseenNotifications: "",
  notificationSocketInstance: null,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState: notificationInitialState,
  reducers: {
    getNotificationsStart: (state, action) => {
      state.loading = true;
    },
    getNotificationsSuccess: (state, action) => {
      state.loading = false;
      state.notificationList = action.payload?.notificationList;
      state.unseenNotifications = action.payload?.unseenNotifications || "";
    },
    getNotificationsFailure: (state, action) => {
      state.loading = false;
    },

    updateNotificationsCount: (state, action) => {
      state.unseenNotifications = "";
    },

    updateNotificationList: (state, action) => {
      state.notificationList = action?.payload?.notificationListData;
      if (
        action?.payload?.notificationListData &&
        !action?.payload?.notificationToggleValue
      ) {
        const filtered = action.payload.notificationListData.filter(
          (elem) => !elem.isRead
        );
        state.unseenNotifications = filtered?.length || "";
      }
    },
    updateNotificationListAfterDismiss: (state, action) => {
      state.notificationList = action.payload;
    },
    addNewNotifications: (state, action) => {
      state.notificationList = [
        action.payload?.data,
        ...state.notificationList,
      ];
      if (!action?.payload?.notificationToggleValue) {
        state.unseenNotifications = (state.unseenNotifications || 0) + 1;
      }
    },

    // updateNotificationsStart:(state,action)=>{
    //   state.unseenNotifications = ""
    //   state.loading = true;
    // },
    // updateNotificationsSuccess:(state,action)=>{
    //   state.loading = false;
    //   state.notificationList = action.payload?.notificationList;
    //   state.unseenNotifications = action.payload?.unseenNotifications||"";
    // },
    // updateNotificationFailed:(state, action) => {
    //   state.loading = false;
    // },

    setNotificationSocketInstance: (state, action) => {
      return {
        ...state,
        notificationSocketInstance: action.payload,
      };
    },
  },
});

export const {
  getNotificationsStart,
  getNotificationsSuccess,
  getNotificationsFailure,
  setNotificationSocketInstance,
  updateNotificationsCount,
  updateNotificationList,
  addNewNotifications,
  updateNotificationListAfterDismiss,
} = notificationSlice.actions;

export default notificationSlice.reducer;
