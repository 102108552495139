import React from "react";

import { Grid } from "@material-ui/core";

import { StepsEnum } from "./constants";

function DebitCardPayment({ activeCard, debitPaymentUrl, setStep }) {
  return (
    <div>
      <Grid className="QRpaymentDetailsWrap  debitCardMainWrap">
        <span>Please complete your payment through this link</span>
        <div className="paymentMainContentWrap">
          <div className="copyWrap">
            <p>{debitPaymentUrl}</p>
            <span
              onClick={() => navigator.clipboard.writeText(debitPaymentUrl)}
              tabIndex="0"
              onKeyDown={() => {}}
              className="mobileCopyImg"
              role="button"
            >
              <img src="/images/copyIcon.png" alt="" />
            </span>
          </div>
          <div className="debitCardInputWrap">
            <span className="amountTxt">Amount</span>
            <div className="copyWrap">
              <p>${activeCard?.price}</p>
            </div>
          </div>
          <div className="divider debitWrap">
            <span>OR</span>
          </div>
          <div className="redirecTxt">
            <p>Redirect on site</p>
          </div>
          <div className="buyNowBtnWrap">
            <button
              onClick={() =>
                window.open(
                  "https://sandbox-onramp.xcoins.network/?ref=b6e08863-a401-43e7-a2aa-5bb67bd51e3a",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
            >
              Make payment
            </button>
          </div>
          <div className="select-payment-method-button select-btn-mobile">
            <button onClick={() => setStep(StepsEnum.Method)}>Back</button>
          </div>
        </div>
      </Grid>
    </div>
  );
}

export default DebitCardPayment;
