import axiosClient from "../axios";

export default class storeService {
  static async getCoinPackages({ setLoader }) {
    setLoader(true);
    try {
      const { data } = await axiosClient.get("store/all-packages");
      setLoader(false);
      return data;
    } catch (error) {
      setLoader(false);
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
}
