import { put, takeLatest } from "redux-saga/effects";

import { showSuccessMessage, showErrorMessage } from "../../redux/toast";
import cashAppTransactionServices from "../../../services/cashApp";
import {
  cashApptransactionsFailure,
  cashApptransactionsSuccess,
  cashAppTransactionStart,
  getCashAppTransactionStart,
  getCashAppTransactionSuccess,
  getCashAppTranscationFailure,
  cashAppStatusChangeStart,
  cashAppStatusChangeSuccess,
  cashAppStatusChangeFailure,
  cashAppWithdrawStart,
  cashAppWithdrawSuccess,
  cashAppWithdrawFailure,
  getCashAppWithdrawStart,
  getCashAppWithdrawSuccess,
  getCashAppWithdrawFailure,
  getDefaultPayDataStart,
  getDefaultPayDataFailure,
  getDefaultPayDataSuccess,
} from "../../redux/CashApp";

function* cashAppTransactinWatcher() {
  yield takeLatest(cashAppTransactionStart.type, cashAppTransactionWorker);
  yield takeLatest(
    getCashAppTransactionStart.type,
    getCashAppTranscationWorker
  );
  yield takeLatest(cashAppStatusChangeStart.type, cashAppStatusChangeWorker);
  yield takeLatest(cashAppWithdrawStart.type, cashAppWithdrawWorker);
  yield takeLatest(getCashAppWithdrawStart.type, getCashAppWithdrawWorker);
  yield takeLatest(getDefaultPayDataStart.type, getDefaultPayDataWorker);
}

// CashApp Deposit Api action start from here

function* cashAppTransactionWorker(action) {
  try {
    const { data, error } = yield cashAppTransactionServices.cashAppTransaction(
      action.payload
    );
    if (!error) {
      yield put(
        showSuccessMessage({
          tKey: "Your Transaction Request has been success",
        })
      );
      yield put(cashApptransactionsSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(cashApptransactionsFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
  }
}

function* cashAppWithdrawWorker(action) {
  try {
    const { data, error } = yield cashAppTransactionServices.cashAppWithdraw(
      action.payload
    );
    if (!error) {
      yield put(
        showSuccessMessage({ tKey: "Your Withdraw Request has been success" })
      );
      yield put(cashAppWithdrawSuccess(data));
    }
    if (error) {
      const errorMessage =
        error.message === "Exceeded daily withdrawal limit"
          ? ""
          : error.message;
      yield put(showErrorMessage({ msg: errorMessage }));
      yield put(cashAppWithdrawFailure(error));
    }
  } catch (error) {
    const errorMessage =
      error.message === "Exceeded daily withdrawal limit" ? "" : error.message;
    yield put(showErrorMessage({ msg: errorMessage }));
    yield put(cashAppWithdrawFailure(error));
  }
}

function* cashAppStatusChangeWorker(action) {
  try {
    const { data, error } =
      yield cashAppTransactionServices.cashAppStatusChange(action.payload);
    if (!error) {
      yield put(cashAppStatusChangeSuccess(data));
    }
    if (error) {
      yield put(cashAppStatusChangeFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
  }
}

// CashApp Withdraw Api Action start from here
function* getCashAppTranscationWorker() {
  try {
    const { data, error } =
      yield cashAppTransactionServices.getCashAppTransaction();
    if (!error) {
      yield put(getCashAppTransactionSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getCashAppTranscationFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
  }
}

function* getDefaultPayDataWorker() {
  try {
    const { data, error } =
      yield cashAppTransactionServices.getDefaultPayData();
    if (!error) {
      yield put(getDefaultPayDataSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getDefaultPayDataFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
  }
}

function* getCashAppWithdrawWorker() {
  try {
    const { data, error } =
      yield cashAppTransactionServices.getCashAppWithdraw();
    if (!error) {
      yield put(getCashAppWithdrawSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getCashAppWithdrawFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
  }
}

export default cashAppTransactinWatcher;
