import { put, takeLatest } from "redux-saga/effects";

import transactionsService from "../../../services/transactions/index.js";
import {
  getTransactionsStart,
  getTransactionsSuccess,
  getTransactionsFailure,
  getMyBetsStart,
  getMyBetsSuccess,
  getMyBetsFailure,
  getUsersAllBetsSuccess,
  getUsersAllBetsFailure,
  getUsersAllBetsStart,
  getUsersAllBonusHistStart,
  getUsersAllBonusHistSuccess,
  getUsersAllBonusHistFailure,
  getUsersAllTransactionHistStart,
  getUsersAllTransactionHistSuccess,
  getUsersAllTransactionHistFailure,
  cancelUserTransactionStart,
  cancelUserTransactionSuccess,
  cancelUserTransactionFailure,
} from "../../redux/transaction";
import { showErrorMessage } from "../../redux/toast";

function* transactionsWatcher() {
  yield takeLatest(getTransactionsStart.type, getTransactionsWorker);
  yield takeLatest(getMyBetsStart.type, getMyBetsWorker);
  yield takeLatest(getUsersAllBetsStart.type, getUserAllBetsWorker);
  yield takeLatest(getUsersAllBonusHistStart.type, getUserAllBonusHistWorker);
  yield takeLatest(
    getUsersAllTransactionHistStart.type,
    getUserAllTransactionsHistWorker
  );
  yield takeLatest(
    cancelUserTransactionStart.type,
    cancelUserTransactionWorker
  );
}

function* getTransactionsWorker(action) {
  try {
    const { data, error } = yield transactionsService.getTransactions(
      action.payload
    );
    if (!error) {
      yield put(getTransactionsSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getTransactionsFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(getTransactionsFailure());
  }
}

function* getMyBetsWorker(action) {
  try {
    const { data, error } = yield transactionsService.getMyBets(action.payload);
    if (!error) {
      yield put(getMyBetsSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getMyBetsFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(getMyBetsFailure());
  }
}
function* getUserAllBetsWorker(action) {
  console.log("Action payload >>>>>", action);
  try {
    const { data, error } = yield transactionsService.getUsersAllBets(
      action.payload
    );
    if (!error) {
      yield put(getUsersAllBetsSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getUsersAllBetsFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(getUsersAllBetsFailure());
  }
}
function* getUserAllBonusHistWorker(action) {
  console.log("Action payload >>>>>", action);
  try {
    const { data, error } = yield transactionsService.getUsersAllBonusHist(
      action.payload
    );
    console.log("Data>>>>>>", data);
    if (!error) {
      yield put(getUsersAllBonusHistSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getUsersAllBonusHistFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(getUsersAllBonusHistFailure());
  }
}
function* getUserAllTransactionsHistWorker(action) {
  console.log("Action payload >>>>>", action);
  try {
    const { data, error } =
      yield transactionsService.getUsersAllTransactionHist(action.payload);
    console.log("Data>>>>>>", data);
    if (!error) {
      yield put(getUsersAllTransactionHistSuccess(data));
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(getUsersAllTransactionHistFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(getUsersAllTransactionHistFailure());
  }
}
function* cancelUserTransactionWorker(action) {
  try {
    const { data, error } = yield transactionsService.cancelUserTransaction(
      action.payload
    );
    if (!error) {
      //yield put(showErrorMessage({ msg: error.message }));
      yield put(cancelUserTransactionSuccess(data));
      return data;
    }
    if (error) {
      yield put(showErrorMessage({ msg: error.message }));
      yield put(cancelUserTransactionFailure());
    }
  } catch (error) {
    yield put(showErrorMessage({ msg: error.message }));
    yield put(cancelUserTransactionFailure());
  }
}
export default transactionsWatcher;
