/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react-hooks/exhaustive-deps */
import { createRef, useEffect, useRef, useState } from "react";

import { Box, Dialog, DialogContent, Grid, Input } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { MentionsInput } from "react-mentions"
import toast from "react-hot-toast";
import { Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// //import EmojiPicker from "emoji-picker-react";

// import { async } from "q";

import { socket } from "../../services/webSocket/supportChatSocket";
import useStyles from "./ChatBox.styles";
import session from "../../utils/session";
import {
  getHighlightedMention,
  replaceMentionWithValue,
} from "../../utils/helper";
import { FilterHacked } from "../../utils/filterBadWords";
import { getPreviousSupportChatsStart } from "../../redux-saga/redux/chatbox";
import axiosClient from "../../services/axios";
import Modal from "../../hoc/modal/Modal";
/* eslint-disable react/react-in-jsx-scope */
const SupportChat = ({ selectedChatType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { supportChatList } = useSelector((state) => state.chatbox);
  const { userDetails } = useSelector((state) => state.myProfile);
  let customFilter = new FilterHacked();
  customFilter.removeWords("hells", "sadist", "hell");
  const [message, setMessage] = useState("");
  const [previousChats, setPreviousChats] = useState([]);
  const [userId, setUserId] = useState("");
  const messagesEndRef = createRef();
  const inputRef = useRef();
  const submitRef = createRef();
  const [showPickerr, setShowPickerr] = useState(false);
  const [chatImage, setChatImage] = useState("");
  const [loading, setLoading] = useState(false);
  //const [isZoomed, setIsZoomed] = useState(false);
  useEffect(() => {
    // if (selectedChatType === "support") {
    dispatch(getPreviousSupportChatsStart());
    // setPreviousChats(supportChatList);
    // }
  }, []);

  useEffect(() => {
    if (supportChatList?.length) setPreviousChats(supportChatList);
  }, [supportChatList?.length]);

  useEffect(() => {
    const user = session?.getSessionCred;
    setUserId(user?.userId);
    socket.on("updatedChatMessage", (data) => {
      if (Number(data?.receiver) === Number(user?.userId)) {
        setPreviousChats(data?.userChats);
      }
    });
  }, []);
  useEffect(() => {
    if (previousChats?.length) {
      scrollToBottom();
    }
  }, [previousChats, previousChats /* selectedChatType */]);
  const modifyMessage = (message) => {
    const msg = replaceMentionWithValue(message || "");
    return getHighlightedMention(msg);
  };
  const handleOnKeyDownInput = (event) => {
    if (event.key === "Enter") {
      event?.preventDefault();
      submitRef?.current?.click();
      handleSendMessage();
    }
  };
  const [imagePreview, setImagePreview] = useState(null);
  const handleFileUpload = (event) => {
    setChatImage(event.target.files[0]);
    if (event.target.files[0]?.name) {
      const reader = new FileReader();

      reader.onloadend = () => {
        // Once the file is read, set the result as the image preview source
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(event.target.files[0]);
    }
    inputRef?.current?.focus();
  };

  const handleSendMessage = async () => {
    let fileDetail = {};
    setLoading(true);
    if (chatImage?.name) {
      const fd = new FormData();
      fd.append("files", chatImage);
      fd.append(
        "id",
        session?.getSessionCred?.userId ||
          Number(localStorage.getItem("useId")) ||
          userDetails?.id
      );
      const {
        data: {
          data: { supportChatImage, fileType },
        },
        status,
      } = await axiosClient.post("/user/uploadSupportChatImage", fd, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (status === 200) {
        fileDetail.location = supportChatImage;
        fileDetail.fileType = fileType;
      }
    }
    setMessage("");
    if (!chatImage?.name && !message) {
      return;
    }
    const preChat = [...previousChats];
    preChat.push({
      roomId: localStorage.getItem("userId"),
      text: message,
      sender:
        session?.getSessionCred?.userId ||
        Number(localStorage.getItem("useId")) ||
        userDetails?.id,
      receiver: 1,
      sender_type: "user",
      receiver_type: "admin",
      sender_name: userDetails?.user?.user_name || "user",
      receiver_name: "admin",
      sender_profile_picture: userDetails?.user?.profile_image,
      chat_file: fileDetail?.location || "",
      file_type: fileDetail?.fileType || "",
    });
    setPreviousChats(preChat);
    const pdata = {
      text: message,
      roomId: localStorage.getItem("userId"),
      userId:
        session?.getSessionCred?.userId ||
        Number(localStorage.getItem("useId")),
      chat_image: fileDetail?.location || "",
      fileType: fileDetail?.fileType || "",
      userName: userDetails?.user?.user_name || "user",
    };
    socket.emit("chatMessageByUser", pdata);
    setImagePreview(null);
    setChatImage("");
    setLoading(false);
  };
  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };
  const [openChatImage, setOpenChatImage] = useState({
    show: false,
    file: "",
    fileType: "",
  });

  const handleDoubleClick = (image, fileTYpe) => {
    setOpenChatImage({ show: true, file: image, fileType: fileTYpe });
  };
  const handleClose = () => {
    setOpenChatImage({ show: false, file: "", fileType: "" });
  };

  const fileInputRef = useRef(null);

  const handleLinkClick = () => {
    fileInputRef.current.click();
  };
  const handleResetUpload = () => {
    setImagePreview(null);
    setChatImage("");
  };

  return (
    <>
      <div className={classes.scrollCustom}>
        <Grid className={classes.chatMessageGroup}>
          {previousChats?.map((user, index) => (
            <Grid
              key={`${user?.userId}${user?.text}${index}`}
              className={
                user.userId === userId
                  ? classes.chatMessage
                  : classes.chatMessageMine
              }
            >
              <Grid className={classes.profileImage}>
                <span className={classes.userBorder}>
                  <img
                    src={
                      user?.sender_profile_picture !== "admin"
                        ? user?.sender_profile_picture
                        : "/images/admin-profile.png"
                    }
                    onError={(e) => {
                      e.target.src = "/images/admin-profile.png";
                    }}
                    alt="player-icon"
                  />
                </span>
                {user?.sender_name === "Admin" ? (
                  ""
                ) : (
                  <span className={classes.userProfileLevel}>
                    {userDetails?.userLevelId}
                  </span>
                )}
              </Grid>
              <Grid className={classes.message}>
                <p className={classes.userNameTime}>
                  <span>{user?.sender_name || "Ngin"}</span>
                  <span>
                    {moment(user?.created_at || new Date()).format(" h:mm A")}
                  </span>
                </p>
                {user?.chat_file &&
                user?.file_type?.split("/")[0] === "image" ? (
                  <div
                    id="zoom_container"
                    onClick={() =>
                      handleDoubleClick(user?.chat_file, user?.file_type)
                    }
                    role="presentation"
                  >
                    <img
                      id="zoomed_image"
                      src={user?.chat_file}
                      alt="player-icon"
                      onError={(e) => console.error("Image Load Error:", e)}
                    />
                  </div>
                ) : user?.chat_file &&
                  user?.file_type?.split("/")[0] === "video" ? (
                  <div
                    id="zoom_container"
                    onClick={() =>
                      handleDoubleClick(user?.chat_file, user?.file_type)
                    }
                    role="presentation"
                  >
                    <video id="zoomed_media" width="640" height="360" controls>
                      <source src={user?.chat_file} type={user?.file_type} />
                    </video>
                  </div>
                ) : (
                  ""
                )}

                <p className={classes.userParagraph}>
                  {modifyMessage(user?.text)}
                </p>
              </Grid>
            </Grid>
          ))}
          {previousChats?.length === 0 && (
            <Grid className={classes.Nochats}>No chats Found 🥲</Grid>
          )}
        </Grid>
        <div ref={messagesEndRef} />
      </div>
      <Grid key="chatbox" className={classes.chatBoxFooter}>
        <Grid key="chatbox2" className={classes.footerInput}>
          <Dialog
            open={showPickerr}
            onClose={() => setShowPickerr(false)}
            className={classes.dropShadow}
          ></Dialog>
          <Grid className={classes.supprtChatInput}>
            <Input
              id="file-input"
              type="text"
              placeholder="Type your message"
              value={message}
              onChange={(e) => {
                if (e?.target?.value && e?.target?.value?.length > 100) {
                  toast.error("Oops! Seems like your message is too long ", {
                    id: "messgaeLength",
                  });
                  return;
                }
                if (e?.target?.value) {
                  setMessage(customFilter?.cleanHacked(e?.target?.value));
                } else setMessage("");
                inputRef?.current?.focus();
              }}
              onKeyDown={handleOnKeyDownInput}
              key="message"
              inputRef={inputRef}
              className="comments-textarea supportChat-input"
            />
            {/* <Input type="button" onClick={handleSendMessage}>
            Send
          </Input> */}
            <Grid className={classes.searchIcons}>
              <Box>
                {imagePreview ? (
                  <Box className={`${classes.uploadImages} uploadImages`}>
                    <img
                      src={imagePreview}
                      alt="preview"
                      style={{ width: "25px", height: "25px" }}
                    />
                  </Box>
                ) : (
                  ""
                )}
                <input
                  type="file"
                  id="fileInput"
                  name="file"
                  accept=".png, .jpg, .jpeg"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  disabled={loading}
                />
                {imagePreview ? (
                  <Box
                    className={`${classes.chatCrossIcon} chatCrossIconUpload`}
                    onClick={handleResetUpload}
                    role="presentation"
                    disabled={loading}
                  >
                    <img src="/images/chatcross.svg" alt="cross" />
                  </Box>
                ) : (
                  <Link onClick={handleLinkClick} role="presentation">
                    <img src="/images/wpf_attach.png" alt="emoji" />
                  </Link>
                )}
              </Box>

              <span
                onClick={handleSendMessage}
                disabled={loading}
                // ref={submitRef}
                role="presentation"
              >
                <Link>
                  <img src="/images/sent-icon.svg" alt="sent" />
                </Link>
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        isShowing={openChatImage?.show}
        centered
        className="walletModalDialog"
        content={
          <DialogContent className={`${classes.loginWrap}`}>
            <Box className={classes.accountRight}>
              <Grid>
                <Box
                  className={classes.closeIconWrap}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginBottom: "14px",
                  }}
                >
                  <CloseIcon
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "20px",
                      height: "20px",
                    }}
                    onClick={handleClose}
                  />
                </Box>
              </Grid>
              {openChatImage?.file &&
              openChatImage?.fileType?.split("/")[0] === "image" ? (
                <Box className={classes.zoomedImage}>
                  <img
                    id="zoomed_image"
                    src={openChatImage?.file}
                    alt="player-icon"
                  />
                </Box>
              ) : openChatImage?.file &&
                openChatImage?.fileType?.split("/")[0] === "video" ? (
                <video id="zoomed_media" width="640" height="360" controls>
                  <source
                    src={openChatImage?.file}
                    type={openChatImage?.fileType}
                  />
                </video>
              ) : (
                ""
              )}
            </Box>
          </DialogContent>
        }
        hide={handleClose}
      />
    </>
  );
};
export default SupportChat;
