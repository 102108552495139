import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Grid, Tab, Tabs, Box, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

import useStyles from "../../container/Casino/Lobby/Lobby.styles";
import {
  getTopBetsStart,
  getHighRollersBetStart,
  getLuckyWinnerBetStart,
  startGetBet,
  clearLatestBet,
} from "../../redux-saga/redux/LiveTable";
import BetTable from "./table";
import { TableMobile } from "./mobileTable";
import {
  HIGH_ROLLER_CHECK_AMONT,
  LEADERBOARD_TABLE_LIMIT,
  LUCKY_WINNER_CHECK_AMOUNT,
} from "../../utils/constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  );
}
const LiveTable = () => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { topBets, latestBet, highRollerBets, luckyWinnerBets, loading } =
    useSelector((state) => state.liveTable);
  const { darkMode } = useSelector((state) => state.themeSettings);
  const [betsList, setBetsList] = useState([]);
  const [highRollersBetList, setHighRollersBetList] = useState([]);
  const [luckyWinnerBetList, setLuckyWinnerBetList] = useState([]);

  useEffect(() => {
    setBetsList(topBets);
  }, [topBets]);

  useEffect(() => {
    setHighRollersBetList(highRollerBets);
  }, [highRollerBets]);

  useEffect(() => {
    setLuckyWinnerBetList(luckyWinnerBets);
  }, [luckyWinnerBets]);

  useEffect(() => {
    dispatch(getTopBetsStart());
    dispatch(getHighRollersBetStart());
    dispatch(getLuckyWinnerBetStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(startGetBet());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (latestBet) {
      let newObj = {};
      newObj = {
        User: {
          userName: latestBet?.userName || null,
          profileImage: latestBet?.userImage || null,
          userLevelId: latestBet?.userLevelId || null,
        },
        MasterCasinoGame: {
          name: latestBet?.gameName || null,
          gameId: latestBet?.gameId || null,
          MasterGameSubCategory: {
            iconName: latestBet?.gameSubCatImage || "/images/game-icon.svg",
          },
        },
        amount: latestBet?.winningAmount || 0,
        BetsDetail: {
          amount: latestBet?.betAmount || 0,
          currencyCode: latestBet?.currency || "",
        },
        actionType: latestBet?.type || null,
        createdAt: new Date(),
      };
      if (
        latestBet?.winningAmount >=
        Number(latestBet?.betAmount || 0) * LUCKY_WINNER_CHECK_AMOUNT
      ) {
        setLuckyWinnerBetList((s) => [newObj, ...s]);
        if (
          luckyWinnerBetList?.length !== 0 &&
          luckyWinnerBetList?.length > LEADERBOARD_TABLE_LIMIT
        )
          setLuckyWinnerBetList((s) => s.slice(0, -1));
      } else if (latestBet?.betAmount >= HIGH_ROLLER_CHECK_AMONT) {
        setHighRollersBetList((s) => [newObj, ...s]);
        if (
          highRollersBetList?.length !== 0 &&
          highRollersBetList?.length > LEADERBOARD_TABLE_LIMIT
        )
          setHighRollersBetList((s) => s.slice(0, -1));
      } else {
        setBetsList((s) => [newObj, ...s]);
        if (
          betsList?.length !== 0 &&
          betsList?.length > LEADERBOARD_TABLE_LIMIT
        )
          setBetsList((s) => s.slice(0, -1));
      }
      dispatch(clearLatestBet());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestBet]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <section>
      <Grid className={classes.latestBetSection}>
        <Grid className={classes.themeCard}>
          <Box className={classes.tabSection}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Recent Wins" />
              <Tab label="High rollers" />
              <Tab label="Lucky Winners" />
            </Tabs>
          </Box>
          {loading ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <div className="load" />
            </Box>
          ) : (
            <Grid className={classes.betTableWrap}>
              {value === 0 && <BetTable betsList={betsList} />}
              {value === 1 && <BetTable betsList={highRollersBetList} />}
              {value === 2 && <BetTable betsList={luckyWinnerBetList} />}
            </Grid>
          )}
        </Grid>
      </Grid>
      {isMobile && (
        <TableMobile
          loading={loading}
          betsList={betsList}
          highRollersBetList={highRollersBetList}
          luckyWinnerBetList={luckyWinnerBetList}
          classes={classes}
          isDarkMode={darkMode}
          handleChange={handleChange}
          value={value}
          TabPanel={TabPanel}
        />
      )}
    </section>
  );
};

export default LiveTable;
