/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import { Box, Button, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import useStyles from "../Header/Login/Login.styles";

const ExitPopup = ({
  setOpenExitGame,
  countdown,
  setCountdown,
  text,
  handleDo,
  from,
}) => {
  console.log("countdown", countdown);

  const classes = useStyles();
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    }
    console.log("hello ");
    return () => clearTimeout(timer);
  }, [countdown]);

  return (
    <div
      className={`${classes.authPopupForm} ${
        from === "transaction" ? "TransactionExtoPopup" : "GameExitPopup"
      }`}
    >
      <div className={classes.userForm}>
        <Box
          className={`${classes.closeIconWrap} close-cross`}
          onClick={() => {
            setOpenExitGame(false);
          }}
        >
          <CloseIcon
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "20px",
              height: "20px",
            }}
          />{" "}
        </Box>
      </div>

      <Grid className={" pop-up-new-styling"}>
        <Grid className="exit-para exitParaNew">
          <img
            className="pop-up-img"
            src="/images/State mobile.svg"
            alt="modal banner img"
          />
          <p className="exit-para-head">Confirmation</p>
          <p
            className={`exit-para-sub   ${
              from !== "transaction" ? "exit-para-sub-mobile-width " : ""
            }`}
          >
            {text ? text : "Are you sure you want to exit this game?"}
          </p>
        </Grid>
        <Grid>
          <Button
            onClick={handleDo}
            disabled={countdown > 0 && from !== "transaction"}
            className={`   ${
              countdown > 0 && from !== "transaction" ? "exit-btn-disable" : ""
            }
                exit-btn1 exit-text-btn`}
          >
            Yes, I do
          </Button>
        </Grid>
        <Grid className={`${classes.forgotLink} exit-margin-bt  `}>
          <Button
            style={{ textTransform: "none !important" }}
            className="exit-text-danger exit-text-btn"
            onClick={() => setOpenExitGame(false)}
          >
            No, I don&lsquo;t
          </Button>
        </Grid>
        {countdown > 0 && from !== "transaction" && (
          <Grid className={`${classes.authBtnWrap} exit-para-bottom`}>
            <p className={`${classes.formInput}`}>
              Available to exit in{" "}
              <span className="exit-para-sec"> {countdown} seconds</span>
            </p>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default ExitPopup;
