import React from "react";

import { Link } from "react-router-dom";

function PaymentProcess() {
  return (
    <div className="paymentProcessMainWrap">
      <div>
        <img src="/images/moonShadow.png" alt="Bitcoin Coin" />
      </div>
      <div className="contentWrapPaymentProcess">
        <span>00:02</span>
        <p>We are processing your payment... </p>
        <Link href="#">Contact support</Link>
      </div>
      <div className="select-payment-method-button select-btn-mobile">
        <button
        // disabled={loader}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default PaymentProcess;
