import React from "react";

export function GlobalLoader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // padding: "20px",
      }}
      className="LoaderWrap"
    >
      <div className="load" />
    </div>
  );
}
