// store.js
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import onlyGamesTransform from "./customTransform";
import reducer from "./redux";
import saga from "./saga";
import resetGamesMiddleware from "./resetStates";

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Persist configuration
const persistConfig = {
  key: "root",
  storage,
  transforms: [onlyGamesTransform], // Apply the custom transform
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, reducer);

// Configure the store with the persisted reducer and middleware
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
    sagaMiddleware,
    resetGamesMiddleware,
  ],
});

// Run the saga middleware
sagaMiddleware.run(saga);

// Create the persistor
export const persistor = persistStore(store);
