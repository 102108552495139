import React, { useMemo, useEffect, useState } from "react";

import { Box, Grid, Tab, Tabs } from "@mui/material";

export const TableMobile = ({
  betsList,
  highRollersBetList,
  luckyWinnerBetList,
  classes,
  isDarkMode,
  handleChange,
  value,
  TabPanel,
  loading,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    if (betsList?.length > 0) {
      if (value === 1) {
        const data = [...betsList];
        const sortData = data.sort(
          (a, b) => b.BetsDetail.amount - a.BetsDetail.amount
        );
        setFilteredData(sortData);
      }
      if (value === 0) {
        setFilteredData(betsList);
      }
      if (value === 2) {
        const data = [...betsList];
        const sortData = data.sort((a, b) => b.amount - a.amount);
        setFilteredData(sortData);
      }
    }
  }, [betsList, value]);

  const memoizedTable = useMemo(
    (number) => {
      return (
        <Grid className={classes.tableBg} key={number}>
          <Box
            className={classes.mobTabSection}
            value={value}
            onChange={handleChange}
          >
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Recent Wins" />
              <Tab label="High rollers" />
              <Tab label="Lucky Winners" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <BetTable
              filteredData={betsList}
              classes={classes}
              isDarkMode={isDarkMode}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <BetTable
              filteredData={highRollersBetList}
              classes={classes}
              isDarkMode={isDarkMode}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <BetTable
              filteredData={luckyWinnerBetList}
              classes={classes}
              isDarkMode={isDarkMode}
            />
          </TabPanel>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "20px",
              }}
            >
              <div className="load" />
            </div>
          ) : (
            betsList?.length === 0 && (
              <p className={classes.noBetsPara}>No Bets Found</p>
            )
          )}
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredData, classes, loading]
  );
  return memoizedTable;
};

const BetTable = ({ filteredData, classes, isDarkMode }) => {
  return (
    <>
      {filteredData?.map((bet, index) => (
        <Grid
          key={`${bet?.createdAt} ${index}`}
          className={`${classes.mobileTable} ${classes.slideIn} slideIn`}
        >
          <Grid className={classes.mobileTableDetail}>
            <Grid className={classes.userImage}>
              <span className={classes.userBorder}>
                <img
                  src={bet?.User?.profileImage || "/images/player-icon.svg"}
                  alt="player-icon"
                />
              </span>
              <span className={classes.userProfileLevel}>
                {bet?.User?.userLevelId || ""}
              </span>
            </Grid>
            <div className={classes.gameName}>
              <p>
                {bet?.MasterCasinoGame?.name.length >= 18
                  ? `${bet?.MasterCasinoGame?.name?.substring(0, 18) + "..."}`
                  : bet?.MasterCasinoGame?.name}
              </p>
              <p>{bet?.User?.userName}</p>
            </div>

            <div className={classes.amountSection}>
              <div className={classes.currencyStatus}>
                <p>Amount</p>
                <p>Payout</p>
              </div>

              <div className={classes.currencySection}>
                <p>
                  {Number(bet?.BetsDetail?.amount)?.toFixed(2)}{" "}
                  <span>
                    {bet?.BetsDetail?.currencyCode === "USD" ? (
                      <GoldCoin />
                    ) : (
                      <BonusCoin />
                    )}
                  </span>
                </p>
                <p
                  style={{
                    color:
                      bet?.actionType === "loss"
                        ? bet?.BetsDetail?.amount > 1
                          ? "#F25C5C"
                          : isDarkMode
                          ? "#fff"
                          : "black"
                        : "#6CD9A5",
                  }}
                >
                  {bet?.amount
                    ? `${Number(bet?.amount)?.toFixed(2)}`
                    : `- ${Number(bet?.BetsDetail?.amount)?.toFixed(2)}`}
                  <span>
                    <GoldCoin />
                  </span>
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const BonusCoin = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 56 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.55" filter="url(#filter0_f_4953_76)">
        <circle
          cx="28"
          cy="28.4443"
          r="20"
          fill="url(#paint0_radial_4953_76)"
        />
        <circle
          cx="28"
          cy="28.4443"
          r="19.5"
          stroke="url(#paint1_linear_4953_76)"
        />
      </g>
      <circle
        cx="28"
        cy="24"
        r="19.5"
        fill="url(#paint2_radial_4953_76)"
        stroke="url(#paint3_linear_4953_76)"
      />
      <g filter="url(#filter1_di_4953_76)">
        <ellipse
          cx="28"
          cy="24"
          rx="15"
          ry="15"
          fill="url(#paint4_linear_4953_76)"
        />
      </g>
      <g filter="url(#filter2_d_4953_76)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.0766 20.2329L33.13 16.2863C33.0282 16.1846 32.8903 16.1274 32.7463 16.1274H30.7368L32.2763 20.2329H37.0766ZM29.3305 31.234L32.3032 21.3249H37.2577L29.3305 31.234ZM18.7402 21.3253H23.6948L26.6675 31.2343L18.7402 21.3253ZM25.2621 16.1274L23.7226 20.2329H18.9224L22.8689 16.2863C22.9707 16.1846 23.1087 16.1274 23.2526 16.1274H25.2621ZM31.1625 21.3249H24.834L27.9982 31.8725L31.1625 21.3249ZM31.1097 20.2329H24.8892L26.4288 16.1274H29.5702L31.1097 20.2329Z"
          fill="#A822BF"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_4953_76"
          x="0"
          y="0.444336"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="4"
            result="effect1_foregroundBlur_4953_76"
          />
        </filter>
        <filter
          id="filter1_di_4953_76"
          x="9"
          y="9"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4953_76"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4953_76"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_4953_76"
          />
        </filter>
        <filter
          id="filter2_d_4953_76"
          x="18.7402"
          y="16.1274"
          width="18.5176"
          height="16.7451"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4953_76"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4953_76"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_4953_76"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28 48.4443) rotate(-90) scale(40)"
        >
          <stop stopColor="#800F93" />
          <stop offset="1" stopColor="#E483F5" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_4953_76"
          x1="28"
          y1="8.44434"
          x2="28"
          y2="48.4443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.75" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint2_radial_4953_76"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28 44) rotate(-90) scale(40)"
        >
          <stop stopColor="#750D87" />
          <stop offset="1" stopColor="#EFA4FC" />
        </radialGradient>
        <linearGradient
          id="paint3_linear_4953_76"
          x1="28"
          y1="4"
          x2="28"
          y2="44"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.75" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_4953_76"
          x1="28"
          y1="58.5"
          x2="28"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A557B2" />
          <stop offset="1" stopColor="#F6BFFE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const GoldCoin = () => {
  return (
    // <svg
    //   width="40"
    //   height="40"
    //   viewBox="0 0 56 57"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <g opacity="0.3" filter="url(#filter0_f_4953_77)">
    //     <circle
    //       cx="28"
    //       cy="28.4443"
    //       r="20"
    //       fill="url(#paint0_radial_4953_77)"
    //     />
    //     <circle
    //       cx="28"
    //       cy="28.4443"
    //       r="19.5"
    //       stroke="url(#paint1_linear_4953_77)"
    //     />
    //   </g>
    //   <circle
    //     cx="28"
    //     cy="24"
    //     r="19.5"
    //     fill="url(#paint2_radial_4953_77)"
    //     stroke="url(#paint3_linear_4953_77)"
    //   />
    //   <g filter="url(#filter1_di_4953_77)">
    //     <ellipse
    //       cx="28"
    //       cy="24"
    //       rx="15"
    //       ry="15"
    //       fill="url(#paint4_linear_4953_77)"
    //     />
    //   </g>
    //   <g filter="url(#filter2_d_4953_77)">
    //     <path
    //       d="M22.1352 27.8889L19.666 18.3651L25.2216 23.9207L28.308 16.7778L30.7771 23.9207L36.3327 18.3651L34.4808 27.8889H22.1352Z"
    //       fill="#CD7400"
    //     />
    //     <path
    //       d="M22.2302 29.2778H34.4706V30.6667H22.2302V29.2778Z"
    //       fill="#CD7400"
    //     />
    //   </g>
    //   <defs>
    //     <filter
    //       id="filter0_f_4953_77"
    //       x="0"
    //       y="0.444336"
    //       width="56"
    //       height="56"
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity="0" result="BackgroundImageFix" />
    //       <feBlend
    //         mode="normal"
    //         in="SourceGraphic"
    //         in2="BackgroundImageFix"
    //         result="shape"
    //       />
    //       <feGaussianBlur
    //         stdDeviation="4"
    //         result="effect1_foregroundBlur_4953_77"
    //       />
    //     </filter>
    //     <filter
    //       id="filter1_di_4953_77"
    //       x="9"
    //       y="9"
    //       width="38"
    //       height="38"
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity="0" result="BackgroundImageFix" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy="4" />
    //       <feGaussianBlur stdDeviation="2" />
    //       <feComposite in2="hardAlpha" operator="out" />
    //       <feColorMatrix
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in2="BackgroundImageFix"
    //         result="effect1_dropShadow_4953_77"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in="SourceGraphic"
    //         in2="effect1_dropShadow_4953_77"
    //         result="shape"
    //       />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy="1" />
    //       <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
    //       <feColorMatrix
    //         type="matrix"
    //         values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.24 0"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in2="shape"
    //         result="effect2_innerShadow_4953_77"
    //       />
    //     </filter>
    //     <filter
    //       id="filter2_d_4953_77"
    //       x="19.666"
    //       y="16.7778"
    //       width="16.666"
    //       height="14.8887"
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity="0" result="BackgroundImageFix" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         type="matrix"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy="1" />
    //       <feComposite in2="hardAlpha" operator="out" />
    //       <feColorMatrix
    //         type="matrix"
    //         values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in2="BackgroundImageFix"
    //         result="effect1_dropShadow_4953_77"
    //       />
    //       <feBlend
    //         mode="normal"
    //         in="SourceGraphic"
    //         in2="effect1_dropShadow_4953_77"
    //         result="shape"
    //       />
    //     </filter>
    //     <radialGradient
    //       id="paint0_radial_4953_77"
    //       cx="0"
    //       cy="0"
    //       r="1"
    //       gradientUnits="userSpaceOnUse"
    //       gradientTransform="translate(28 48.4443) rotate(-90) scale(40)"
    //     >
    //       <stop stopColor="#FF8A00" />
    //       <stop offset="1" stopColor="#FFC803" />
    //     </radialGradient>
    //     <linearGradient
    //       id="paint1_linear_4953_77"
    //       x1="28"
    //       y1="8.44434"
    //       x2="28"
    //       y2="48.4443"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="white" stopOpacity="0.75" />
    //       <stop offset="1" stopColor="white" stopOpacity="0" />
    //     </linearGradient>
    //     <radialGradient
    //       id="paint2_radial_4953_77"
    //       cx="0"
    //       cy="0"
    //       r="1"
    //       gradientUnits="userSpaceOnUse"
    //       gradientTransform="translate(28 44) rotate(-90) scale(40)"
    //     >
    //       <stop stopColor="#FF8A00" />
    //       <stop offset="1" stopColor="#FFC803" />
    //     </radialGradient>
    //     <linearGradient
    //       id="paint3_linear_4953_77"
    //       x1="28"
    //       y1="4"
    //       x2="28"
    //       y2="44"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="white" stopOpacity="0.75" />
    //       <stop offset="1" stopColor="white" stopOpacity="0" />
    //     </linearGradient>
    //     <linearGradient
    //       id="paint4_linear_4953_77"
    //       x1="28"
    //       y1="58.5"
    //       x2="28"
    //       y2="9"
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#FF8A00" />
    //       <stop offset="1" stopColor="#FFEA7B" />
    //     </linearGradient>
    //   </defs>
    // </svg>
    <img src="/images/GoldCoinIcon.png" alt="" />
  );
};
