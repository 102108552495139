import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import toast from "react-hot-toast";

import { StepsEnum } from "./constants";
import userProfileService from "../../../../services/user";
import { GlobalLoader } from "../../../../utils/globalLoader";

export default function SelectRedemptionAmount({
  setStep,
  setLoader,
  loader,
  withdrawAmount,
  setWithdrawAmount,
}) {
  const sweepCoins = useSelector((state) => state?.wallet?.nonCash?.amount);
  const [nonRedeemableCoins, setNonRedeemableCoins] = useState(0);

  useEffect(() => {
    (async () => {
      const { non_redeemable } = await userProfileService.getRedemptionTokens({
        setLoader,
      });
      setNonRedeemableCoins(non_redeemable || 0);
    })();
  }, [setLoader]);

  useEffect(() => {
    if (Number(withdrawAmount) > Math.max(sweepCoins - nonRedeemableCoins, 0)) {
      toast.error("Withdraw token can't be greater than Redeemable Tokens", {
        id: "withdraw-error-on-redeem-balance",
      });
    }
  }, [nonRedeemableCoins, sweepCoins, withdrawAmount]);

  if (loader) {
    return (
      <div className="RedeemLoaderWrap">
        <GlobalLoader />
      </div>
    );
  }

  const handleContinue = () => {
    if (Number(withdrawAmount) > Math.max(sweepCoins - nonRedeemableCoins, 0)) {
      toast.error("Withdraw token can't be greater than Redeemable Tokens", {
        id: "withdraw-error-on-redeem-balance",
      });
      return;
    }
    if (!Number(withdrawAmount)) {
      toast.error("Please enter tokens to withdraw", {
        id: "no-amount-withdraw-balance",
      });
      return;
    }
    setStep(StepsEnum.RedeemMethod);
  };

  return (
    <div className="mainWrapRedeemTab">
      <h1>How much would you like to redeem?</h1>
      <div className="inputMainWrap paymentMainContentWrap">
        <div className="secondCopyInput RedeemLable">
          <span>Available sweep coin</span>
          <div className="copyWrap copyWrapSec">
            <p>{sweepCoins.toFixed(2)}</p>
            <span className="coinsImg">
              <img
                src="/images/BonusNewIcon.png"
                className="goldCoinsImg"
                alt="Bonus Coins"
              />
            </span>
          </div>
        </div>
        <div className="marginTop-8 RedeemLable">
          <span>Redeemable sweep coin</span>
          <div className="copyWrap copyWrapSec">
            <p>{Math.max(sweepCoins - nonRedeemableCoins, 0).toFixed(2)}</p>
            <span className="coinsImg">
              <img
                src="/images/BonusNewIcon.png"
                className="goldCoinsImg"
                alt="Bonus Coins"
              />
            </span>
          </div>
        </div>
        <div className="marginTop-8 RedeemLable">
          <span>Redeem amount</span>
          {/* <input className="copyWrap copyWrapSec">

          <p className="grayTxt">Amount in Sweep coins</p>
          <span className="coinsImg">
            <img
              src="/images/Bonus-coin.png"
              className="goldCoinsImg"
              alt="Bonus Coins"
            />
          </span>
        </input> */}
          <div className="input-container">
            <input
              type="text"
              id="amount"
              className="styled-input"
              placeholder="Amount in Sweep coins"
              value={
                Number(withdrawAmount) === 0 ||
                withdrawAmount === "" ||
                !withdrawAmount
                  ? ""
                  : withdrawAmount
              }
              onChange={(e) => setWithdrawAmount(e.target.value)}
              onKeyDown={(e) => {
                if (
                  !/^[0-9]$/.test(e.key) &&
                  e.key !== "Backspace" &&
                  e.key !== "Delete" &&
                  e.key !== "ArrowLeft" &&
                  e.key !== "ArrowRight" &&
                  e.key !== "."
                ) {
                  e.preventDefault(); // Prevent other non-numeric input
                }
              }}
            />
            <div className="icon-container">
              <img
                src="/images/BonusNewIcon.png"
                className="goldCoinsImg"
                alt="Bonus Coins"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="buyNowBtnWrap cardWrapScreen">
        <button onClick={handleContinue}>
          <span
            style={{
              fontWeight: "500",
              marginRight: "0px",
              lineHeight: "17px",
            }}
          >
            Continue
          </span>
        </button>
      </div>
    </div>
  );
}
