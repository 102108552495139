import React from "react";

import { Grid } from "@mui/material";

import { StepsEnum } from "./constants";

export default function SelectRedemptionMethod({ setStep }) {
  return (
    <>
      <span className="RedeemSpanMainTxt">Select payment method</span>
      <div className="select-payment-methodWrap">
        <Grid className="paymentCardMainWrap">
          {/* <div
            className="paymentCardWrap"
            // onClick={handleBitcoinPayment}
            role="presentation"
          >
            <img src="/images/Bitcoin-Coin.png" alt="" />
            <p>Bitcoin</p>
          </div> */}
          {/* <div
            className="paymentCardWrap"
            // onClick={() => setStep(StepsEnum.CashApp)}
            role="presentation"
          >
            <img src="/images/dollar.png" alt="" className="cashappimg" />
            <p>CashApp</p>
          </div> */}
          {/* <div
            className="paymentCardWrap"
            // onClick={() => setStep(StepsEnum.PayMobil)}
            role="presentation"
          >
            <img src="/images/payImg.png" alt="" className="paymobileImg" />
            <p>PayMobil</p>
          </div> */}
          <div
            className="paymentCardWrap"
            // onClick={handleCardPayment}
            role="presentation"
          >
            <img src="/images/carNew.png" alt="" className="visaCardImg" />
            <p>Debit/credit card</p>
          </div>

          <div
            className="paymentCardWrap"
            onClick={() => setStep(StepsEnum.AchDirectNew)}
            role="presentation"
          >
            <img src="/images/achDirect.svg" alt="" className="visaCardImg" />
            <p>ACH direct deposit</p>
          </div>
        </Grid>
        <div className="select-payment-method-button select-btn-mobile">
          <button onClick={() => setStep(StepsEnum.RedeemAmount)}>Back</button>
        </div>
      </div>
    </>
  );
}
