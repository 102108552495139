import { createSlice } from "@reduxjs/toolkit";

export const liveTableInitialState = {
  loading: false,
  success: false,
  error: false,
  topBets: [],
  highRollerBets: [],
  luckyWinnerBets: [],
  latestBet: null,
};

const liveTableSlice = createSlice({
  name: "liveTable",
  initialState: liveTableInitialState,
  reducers: {
    getTopBetsStart: (state, action) => {
      state.loading = true;
    },
    getTopBetsSuccess: (state, action) => {
      state.loading = false;
      state.topBets = action.payload;
    },
    getTopBetsFailure: (state, action) => {
      state.loading = false;
    },
    getHighRollersBetStart: (state) => {
      state.loading = true;
    },
    getHighRollersBetSuccess: (state, action) => {
      state.loading = false;
      state.highRollerBets = action.payload;
    },
    getHighRollersBetFailure: (state, action) => {
      state.loading = false;
    },
    getLuckyWinnerBetStart: (state) => {
      state.loading = true;
    },
    getLuckyWinnerBetSuccess: (state, action) => {
      state.loading = false;
      state.luckyWinnerBets = action.payload;
    },
    getLuckyWinnerBetFailure: (state) => {
      state.loading = false;
    },
    updateBetData: (state, action) => {
      if (action.payload?.betsDataObj)
        state.latestBet = action.payload?.betsDataObj;
    },
    startGetBet: () => {},
    clearLatestBet: (state) => {
      state.latestBet = "";
    },
  },
});

export const {
  getLatestBet,
  getTopBetsFailure,
  getTopBetsStart,
  getTopBetsSuccess,
  startGetBet,
  updateBetData,
  clearLatestBet,
  getHighRollersBetStart,
  getHighRollersBetSuccess,
  getHighRollersBetFailure,
  getLuckyWinnerBetStart,
  getLuckyWinnerBetSuccess,
  getLuckyWinnerBetFailure,
} = liveTableSlice.actions;

export default liveTableSlice.reducer;
