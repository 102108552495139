import React, { useEffect, useState } from "react";

import { Dialog, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import clsx from "clsx";

import useStyles from "./Modal.styles";

export default function Modal(props) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    isShowing,
    hide,
    content,
    maxWidth,
    className,
    buyCoins = false,
  } = props;
  console.log("buyCoins>>>>", buyCoins);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767); // Set mobile width threshold

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 320);
  };

  useEffect(() => {
    if (buyCoins === "payment-popup") {
      // Add event listener for resize
      window.addEventListener("resize", handleResize);

      // Clean up event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);
  function getContent() {
    if (typeof content === "function") {
      return content();
    }

    return content;
  }

  if (!isShowing) {
    return null;
  }
  console.log("is mobile>>>", isMobile);
  const modalContent = getContent();

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={(event, reason) => {
        if (reason === "backdropClick") return;
        hide();
      }}
      aria-labelledby="customized-dialog-title"
      open={isShowing}
      maxWidth={maxWidth}
      fullWidth={maxWidth ? true : false}
      className={`${clsx(className, [
        classes.dialogBox,
      ])} dialogBoxLandscape smallScreen ${isMobile ? "buy-coins-dialog" : ""}`}
    >
      {modalContent}
    </Dialog>
  );
}
