/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Toolbar,
  List,
  ListItem,
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  TextField,
  Box,
} from "@material-ui/core";
import { matchPath, useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import i18next from "i18next";
import { toast } from "react-hot-toast";
import ReactSelect from "react-select";

import useStyles from "./PageLayout.styles";
import { sidebarListItems, languageOptions } from "./constants";
import { toggleSlider } from "../Header/toggle";
import { formatCategoryName } from "../../utils/helper";

export default function OpenSidebar({
  games,
  loading,
  handleCloseLanguage,
  switchTheme,
  categoryList,
  handleOpenReferral,
}) {
  const len = games?.length || 0;
  const classes = useStyles({ len: len });
  const dispatch = useDispatch();
  const { darkMode } = useSelector((state) => state.themeSettings);
  const navigate = useNavigate();
  const [language, setLanguage] = useState();
  const location = useLocation();
  const { sidebarOpen } = useSelector((state) => state.themeSettings);
  const { isLoggedIn } = useSelector((state) => state.authentication);

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (sidebarOpen && isMobile) {
      body.style.overflowY = "hidden";
      body.style.overflowY = "-webkit-hidden";
    } else {
      body.style.overflowY = "inherit";
      body.style.overflowY = "-webkit-inherit";
    }
  }, [sidebarOpen, isMobile]);

  const handleClickPromotions = () => {
    if (isMobile) toggleSlider(dispatch, sidebarOpen);
    navigate("/promotions");
  };

  const handleNavigate = (path) => {
    if (path === "/refer-friend" || path === "/") return;
    navigate(path);
  };

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (sidebarOpen && isMobile) {
      body.style.overflowY = "hidden";
      body.style.overflowY = "-webkit-hidden";
    } else {
      body.style.overflowY = "inherit";
      body.style.overflowY = "-webkit-inherit";
    }
    return () => {
      const body = document.getElementsByTagName("body")[0];
      body.style.overflowY = "inherit";
      body.style.overflowY = "-webkit-inherit";
    };
  }, [sidebarOpen, isMobile]);

  useEffect(() => {
    setLanguage((e) => {
      if (i18next.language === "en") return { value: "en", label: "English" };
      else if (i18next.language === "es")
        return { value: "es", label: "Español" };
      else {
        handleCloseLanguage("en");
        return { value: "en", label: "English" };
      }
    });
  }, [i18next?.language]);
  const sideList = () => (
    <Grid className={classes.menuSliderContainer} component="div">
      {isMobile && (
        <Grid className={classes.searchSidebarContent}>
          <Box className={classes.searchSidebar}>
            <TextField
              autoComplete="off"
              autoCorrect="off"
              id="outlined-basic"
              variant="outlined"
            />
            <img src="/images/search.png" alt="Search" />
          </Box>
          <img src="/images/chaticon-sidebar.svg" alt="chaticon" />
        </Grid>
      )}

      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          aria-expanded={true}
        >
          <Typography className={classes.acord}>All Games</Typography>
        </AccordionSummary>

        <AccordionDetails className={classes.leftListItemWrap}>
          <div className={`${classes.leftListItemWrap} ${classes.listColor}`}>
            <List>
              {sidebarListItems?.slice(0, 2).map((g, i) => (
                <>
                  <ListItem
                    onClick={() => {
                      handleOpenReferral(g.listText);
                      if (isMobile) toggleSlider(dispatch, sidebarOpen);

                      if (isLoggedIn) handleNavigate(g?.to);
                      else
                        toast.error(`Please login to see ${g.listText}`, {
                          id: "recents",
                        });
                    }}
                    key={i}
                    className={
                      matchPath(
                        `/${g.to}`.replace(/ /g, "%20"),
                        location?.pathname || "zlehods"
                      ) && classes.navIconHighlighted
                    }
                  >
                    <img
                      alt={g.alt}
                      src={
                        darkMode
                          ? matchPath(
                              `/${g.to}`.replace(/ /g, "%20"),
                              location?.pathname || "zlehods"
                            )
                            ? g?.onHighlight
                            : g.listIconDark
                          : matchPath(
                              `/${g.to}`.replace(/ /g, "%20"),
                              location?.pathname || "zlehods"
                            )
                          ? g?.onHighlight
                          : g.listIconLight
                      }
                    />
                    {g.listText}
                  </ListItem>
                </>
              ))}
            </List>
          </div>

          <Divider
            className={classes.dividerStyle}
            style={{ margin: "0 0 20px 0 " }}
          />
          {!loading && (
            <List className={classes.categoryList}>
              {categoryList.length > 0 &&
                categoryList?.map((listItem, i) => {
                  return (
                    <>
                      <ListItem
                        onClick={() => {
                          if (isMobile) toggleSlider(dispatch, sidebarOpen);
                          handleNavigate(
                            `view-all/false/${listItem.masterGameSubCategoryId}/${listItem.name}`
                          );
                        }}
                        key={i}
                        className={
                          matchPath(
                            `view-all/false/${listItem.masterGameSubCategoryId}/${listItem.name}`.replace(
                              / /g,
                              "%20"
                            ),
                            location?.pathname || "zlehods"
                          ) && classes.navIconHighlighted
                        }
                      >
                        <img alt={"games"} src={listItem?.iconName} />
                        {formatCategoryName(listItem.name)}
                      </ListItem>
                    </>
                  );
                })}
            </List>
          )}
        </AccordionDetails>
      </Accordion>
      <div className={`${classes.leftListItemWrap} ${classes.listColor}`}>
        <List>
          {sidebarListItems?.slice(11, 14).map((g, i) => (
            <>
              <ListItem
                onClick={() => {
                  handleOpenReferral(g.listText);
                  if (isMobile) toggleSlider(dispatch, sidebarOpen);
                  handleNavigate(g?.to);
                }}
                key={i}
                className={
                  matchPath(
                    `/${g.to}`.replace(/ /g, "%20"),
                    location?.pathname || "zlehods"
                  ) &&
                  g.listText !== "Leaderboard" &&
                  classes.navIconHighlighted
                }
              >
                <img
                  alt={g.alt}
                  src={
                    darkMode
                      ? matchPath(
                          `/${g.to}`.replace(/ /g, "%20"),
                          location?.pathname || "zlehods"
                        )
                        ? g?.onHighlight
                        : g.listIconDark
                      : matchPath(
                          `/${g.to}`.replace(/ /g, "%20"),
                          location?.pathname || "zlehods"
                        )
                      ? g?.onHighlight
                      : g.listIconLight
                  }
                />
                {g.listText}
                {g.listText === "Leaderboard" /* ||
                g.listText !== "Refer a Friend" */ ? (
                  <span className={classes.commingTag}>Coming Soon</span>
                ) : null}
              </ListItem>
            </>
          ))}
        </List>
      </div>

      <Grid
        className={
          location.pathname === "/promotions"
            ? `${classes.promotionButton} ${classes.promotionActive}`
            : classes.promotionButton
        }
      >
        <Button id="demo-customized-promotions" onClick={handleClickPromotions}>
          {darkMode ? (
            <img src="/images/promotion.svg" alt="game icon" />
          ) : (
            <img src="/images/Framepromotions-light.svg" alt="game icon" />
          )}
          Promotions
        </Button>
      </Grid>

      <Grid className={`${classes.languageButton} languageButton`}>
        <p>Language & Currency</p>
        <ReactSelect
          className="basic-single"
          classNamePrefix="select"
          value={language}
          onChange={(e) => {
            setLanguage(e);
            handleCloseLanguage(e.value);
          }}
          closeMenuOnSelect={true}
          isSearchable={false}
          name="color"
          options={languageOptions}
          styles={{
            menu: (provided) => ({
              ...provided,
              borderRadius: "8px",
              marginTop: "-1px",
            }),
            option: (provided, state) => ({
              ...provided,
              borderRadius: "8px", // Set the radius for the items
              background: state.isSelected ? "#24242b" : "transparent",
              color: state.isSelected ? "#fff" : "#fff",
              "&:hover": {
                backgroundColor: "#24242b",
              },
            }),
            singleValue: (provided) => ({
              ...provided,
              color: darkMode ? "#fff" : "#24242b",
              borderRadius: "8px",
            }),
            menuList: (s) => ({
              ...s,
              background: "#24242b",
              borderRadius: "12px",
              fontSize: "14px",
              border: "1.4px solid rgba(255, 255, 255, 0.04)",
              boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
            }),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: "8px",
            colors: {
              ...theme.colors,
              neutral0: darkMode ? "#2a2a2f" : "#f7df94",
              primary25: "rgba(123, 98, 218, 0.16)",
              neutral80: "#fff",
            },
          })}
        />
      </Grid>
      <Grid className={classes.toggleSection}>
        <div className={classes.modeOption}>
          <Grid className={classes.label} onClick={switchTheme}>
            {darkMode ? (
              <>
                <div
                  className={classes.ball}
                  style={
                    !darkMode
                      ? { transform: "translateX(0px)" }
                      : { transform: "translateX(-35px)" }
                  }
                />

                <img src="/images/sun-icon.svg" alt="moon" />
                <img src="/images/moon.svg" alt="sun" />
              </>
            ) : (
              <>
                <div
                  className={classes.ball}
                  style={
                    !darkMode
                      ? { transform: "translateX(0px)" }
                      : { transform: "translateX(-35px)" }
                  }
                />
                <img src="/images/monDark.svg" alt="moon" />
                <img src="/images/sun.svg" alt="sun" />
              </>
            )}
          </Grid>
          <div className={classes.switchTab}>
            <p>{darkMode ? "Dark Mode" : "Light Mode"}</p>
            <p>Currently</p>
          </div>
        </div>
      </Grid>
      <Grid className={classes.liveChatBtn}>
        <Link to="http://m.me/CryptoRollsGamingClub" target="_blank">
          <Button id="demo-customized-button" expandIcon={<ExpandMoreIcon />}>
            {darkMode ? (
              <img src="/images/live-chat.svg" alt="liev chat" />
            ) : (
              <img src="/images/Group 37489live-chat.svg" alt="liev chat" />
            )}
            Live Support
          </Button>
        </Link>

        <List className={classes.socialIcon}>
          <ListItem>
            <Link to="">
              {darkMode ? (
                <img src="/images/s-1.svg" alt="social icon" />
              ) : (
                <img src="/images/telegram-light.svg" alt="social icon" />
              )}
            </Link>
          </ListItem>

          <ListItem>
            <Link to="">
              {darkMode ? (
                <img src="/images/s-2.svg" alt="social icon" />
              ) : (
                <img src="/images/youtube-light.svg" alt="social icon" />
              )}
            </Link>
          </ListItem>

          <ListItem>
            <Link
              /* to="https://www.facebook.com/CryptoRollsGamingClub" */
              to="https://www.facebook.com/CryptoRollsCasino?mibextid=LQQJ4d"
              target="_blank"
            >
              {darkMode ? (
                <img src="/images/face-icon.svg" alt="social icon" />
              ) : (
                <img src="/images/f-icon-light.svg" alt="social icon" />
              )}
            </Link>
          </ListItem>

          <ListItem>
            <Link to="https://www.instagram.com/cryptorolls.official?igsh=MWJub24xeTNqeTY2Mg%3D%3D&utm_source=qr">
              {darkMode ? (
                <img src="/images/s-4.svg" alt="social icon" />
              ) : (
                <img src="/images/instagram-light.svg" alt="social icon" />
              )}
            </Link>
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid className={classes.sidebarWrap}>
        <Toolbar>{sideList()}</Toolbar>
      </Grid>
    </>
  );
}
