const StepsEnum = Object.freeze({
  Amount: "amount",
  Method: "method",
  BitCoin: "bitcoin",
  CashApp: "cashapp",
  PayMobil: "paymobil",
  Card: "card",
  Success: "success",
  RedeemAmount: "redeem_amount",
  RedeemMethod: "redeem_method",
  AchDirect: "ach_direct",
  Pending: "pending",
  DebitCardPayment: "debit_card_payment",
  AchDirectNew: "ach_direct_new",
});

const HeaderEnums = Object.freeze({
  [StepsEnum.Success]: "Payment Success",
  [StepsEnum.CashApp]: "Cash App Payment",
  [StepsEnum.Amount]: "Wallet",
  [StepsEnum.Method]: "Wallet",
  [StepsEnum.BitCoin]: "Bitcoin Payment",
  [StepsEnum.RedeemAmount]: "Wallet",
  [StepsEnum.RedeemMethod]: "Wallet",
  [StepsEnum.Pending]: "Cash App Payment",
  [StepsEnum.DebitCardPayment]: "Credit/Debit card payment",
  [StepsEnum.AchDirect]: "Ach Direct Redeem",
  [StepsEnum.AchDirectNew]: "Ach Direct Redeem",
});

const ShowTabsEnum = [
  StepsEnum.Amount,
  StepsEnum.Method,
  StepsEnum.RedeemAmount,
  StepsEnum.RedeemMethod,
];

export { StepsEnum, HeaderEnums, ShowTabsEnum };
