import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  formGroup: {
    position: "relative",
    marginBottom: "10px",
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& form": {
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      color: "#757786",
      marginBottom: "8px",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
    },
  },
  closeIconComment: {
    top: "0px",
    color: "#858DA0",
    right: "0px",
    cursor: "pointer",
    display: "flex",
    opacity: 1,
    zIndex: 9,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: "20px",
    },
  },

  CommentForm: {
    marginTop: "15px",
    marginBottom: "15px",
  },
  commentHeading: {
    "& h4": {
      marginBottom: "0px !important",
    },
  },

  formInput: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: theme?.palette?.text?.color,
    marginBottom: "8px",
    // marginLeft: "20px",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      // marginLeft: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "6px",
      lineHeight: "normal",
    },
  },

  btnPrimary: {
    background: theme?.palette?.background?.buttonbackground,
    border: theme?.palette?.background?.toggleBorder,
    boxShadow: theme?.palette?.background?.toggleShadow,
    borderRadius: "12px !important",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "26px",
    width: "100%",
    textTransform: "capitalize !important",
    color: "#FFFFFF !important",
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "14px !important",
      borderRadius: "8px !important",
    },
  },

  userForm: {
    position: "relative",
    "& img": {
      width: "100%",
      display: "block",
    },
  },

  authPopupForm: {
    width: "100%",
  },

  closeIconWrap: {
    // padding: "8px",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    opacity: "1",
    right: "20px",
    // width: "24px",
    // height: "24px",
    // background: theme?.palette?.background?.iframeIconBg,
    // border: theme?.palette?.background?.iframeBorder,
    // boxShadow: theme?.palette?.background?.iframeIconShadow,
    // borderRadius: "6px",
    top: "50px",
    zIndex: "9",
    display: "flex",
    cursor: "pointer",
    color: " #858DA0",

    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
    [theme.breakpoints.down("md")]: {
      top: "40px",
      right: "10px",
    },
    "&:hover": {
      // transform: "rotate(90deg)",
      transition: "transform 0.5s ease-in-out",
    },
  },

  loginSignupGrp: {
    background: theme?.palette?.background?.btntextcolor,
    "& ul": {
      display: "flex",
      background: theme?.palette?.background?.buttonWallet,
      boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "12px",
      lineHeight: "44px",
      marginBottom: "20px",
      [theme.breakpoints.down("md")]: {
        borderRadius: "8px",
        lineHeight: "30px",
      },
      "& li": {
        width: "100%",
        justifyContent: "center",
        display: "flex",
        // background:  theme?.palette?.background?.default2,
        cursor: "pointer",
        "& a": {
          fontWeight: "500",
          fontSize: "16px",
          color: theme?.palette?.text?.mobiTableText_1,
          textDecoration: "none",
          width: "100%",
          textAlign: "center",
          [theme.breakpoints.down("md")]: {
            fontSize: "14px",
          },
        },
      },
    },
  },

  active: {
    background: theme?.palette?.background?.btnbackground,
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "12px",
    "& a": {
      color: `${theme?.palette?.text?.btntextcolor} !important`,
    },
    [theme.breakpoints.down("md")]: {
      borderRadius: "8px",
    },
  },

  loginFormWrap: {
    padding: "20px",
    backgroundColor: theme?.palette?.background?.providers,
    borderRadius: "0 0 12px 12px !important",
    [theme.breakpoints.down("md")]: {
      borderRadius: "0 0 8px 8px !important",
      padding: "10px",
    },
    "& .MuiInputBase-input": {
      height: "44px",
      background: theme?.palette?.background?.default2,
      boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "12px",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "23px",
      // color: "#4D4D5B !important",
      color: `${theme?.palette?.text?.placeholder} !important`,
      padding: "0",
      paddingLeft: "20px",
      paddingRight: "50px",
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "12px",
      },
      "&::placeholder": {
        color: "#4D4D5B",
        fontWeight: "500",
        opacity: "1",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("sm")]: {
          lineHeight: "normal",
        },
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#7458DC",
        },
      },
      [theme.breakpoints.down("md")]: {
        height: "32px",
        borderRadius: "8px",
        fontSize: "14px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "12px",
      borderColor: "transparent !important",
    },
    "& .MuiFormLabel-root": {
      // top: "-5px",
      // fontSize: "14px",
      // color: "rgba(255, 255, 255, 0.3)",
    },
    "& fieldset": {
      borderColor: "transparent",
    },
  },

  commentPopup: {
    borderRadius: "0 0 12px 12px !important",
    backgroundColor: "#24242B",
    padding: "14px 10px !important",
    "& .MuiInputBase-input": {
      paddingTop: "10px !important",
    },
  },
  showLink: {
    position: "absolute",
    right: "10px",
    top: "40px",
    fontSize: "14px",
    textDecoration: "none",
    color: theme?.palette?.text?.default,
    [theme.breakpoints.down("md")]: {
      top: "35px",
    },
    [theme.breakpoints.down("sm")]: {
      top: "32px",
    },
  },
  authBtnWrap: {
    marginBottom: "10px",
    "& .MuiTypography-root": {
      textAlign: "center",
      fontWeight: "600",
    },
  },
  forgotLink: {
    margin: "20px 0 40px 0",
    textAlign: "center",
    background: theme?.palette?.background?.btnbackground,
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "12px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: theme?.palette?.background?.seeAll,
    width: "100%",
    [theme.breakpoints.down("md")]: {
      borderRadius: "8px",
    },
    "& .MuiButton-root": {
      alignItems: "center",
      margin: "0",
      padding: "0",
      textDecoration: "none",
      color: theme?.palette?.text?.btntextcolor,
      fontSize: "16px",
      fontWeight: "500",
      width: "100%",
      textTransform: "none",
      "&:hover": {
        background: "transparent",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
        lineHeight: "16px",
      },
    },
    "& .MuiButton-root:hover": {
      background: "#440073",
    },
  },
  signUpLink: {
    textAlign: "center",
    "& .MuiButton-root": {
      margin: "0",
      padding: "0",
      textDecoration: "none",
      color: "#7458DC",
      fontSize: "18px",
      textTransform: "none",
      marginLeft: "2px",
      "&:hover": {
        background: "transparent",
      },
    },
  },
  authHeading: {
    marginBottom: "30px",
    marginTop: "20px",
    "& .MuiTypography-h5": {
      textAlign: "center",
      fontSize: "32px",
      color: "rgba(151, 135, 210, 1)",
      fontWeight: "600",
    },
  },
  authLinkText: {
    maxWidth: "300px",
    margin: "15px auto 0",
    borderTop: "2px solid #26282D",
    "& .MuiTypography-body1": {
      fontSize: "16px",
      textAlign: "center",
      marginTop: "10px",
      color: " #99A4B0",
      "& a": {
        textDecoration: "none",
      },
    },
  },
  paymentComplete: {
    background: theme?.palette?.background?.default2,
    color: theme?.palette?.text?.default,
    boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    borderRadius: "12px !important",
    textAlign: "center",
    padding: "0 0 0 10px",
    marginTop: "0",
    [theme.breakpoints.down("sm")]: {
      borderRadius: "8px !important",
      height: "38px",
    },
    "& small": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "44px",
      position: "relative",
      "& button": {
        padding: "0 !important",
        background: theme?.palette?.background?.buttonbackground,
        border: "1.4px solid rgba(255, 255, 255, 0.04)",
        boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.09)",
        height: "36px",
        width: "36px",
        borderRadius: "12px !important",
        position: "relative",
        right: "6px",
        [theme.breakpoints.down("sm")]: {
          height: "36px",
          width: "36px",
          borderRadius: "8px !important",
          margin: "0",
        },
        "& svg": {
          color: "#fff",
          width: "12px",
        },
      },
      "& span": {
        marginBottom: "0",
        color: theme?.palette?.text?.default,
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: 1,
        textAlign: "start",
      },
    },
  },
  copySection: {
    position: "absolute",
    top: "-18px",
    right: "0",
  },

  referralbutton: {
    width: "100%",
    display: "flex",
    gap: "20px",
    "& button": {
      borderRadius: "8px",
      border: "1.4px solid rgba(255, 255, 255, 0.02) !important",
      backgroundColor: " #2E2E37 !important",
      boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
      color: "#757786 !important",
      fontSize: "16px !important",
      fontWeight: "500 !important",
      lineHeight: "normal !important",
      textTransform: "capitalize",
      display: "flex",
      alignItems: "center",
      gap: "12px",
      height: "44px",
      padding: "10px 15px !important",
      width: "100%",
    },
  },
  dismisButton: {
    color: "#757786",
    width: "100%",
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    fontSize: "16px",
    background: "#2B2B38",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    fontStyle: "normal",
    marginTop: "18px",
    maxHeight: "40px",
    textAlign: "center",
    fontWeight: "500",
    lineHeight: "20px",
    paddingTop: "12px !important",
    borderRadius: "12px !important",
    justifyContent: "center",
    textTransform: "capitalize",
  },
  commentSupport: {
    position: "relative",
  },
  commentHeadingTop: {
    marginBottom: "15px !important",
    color: " white !important",
    fontSize: "18px !important",
    fontWeight: "600 !important",
  },
}));
